import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { useWindowSize } from '../../hooks/useWindowSize';

export interface IModal {
  open: boolean;
  onClose: () => void;
  className?: string;
  style?: CSSProperties;
  forceScroll?: boolean;
}

export const Modal: React.FC<IModal> = ({ onClose, open, children, className, style, forceScroll }) => {
  //handles if modal is higher than window.innerHeight, makes modal scrollable
  const [modalIsHigherThanScreen, setModalIsHigherThanScreen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [, windowHeight] = useWindowSize();
  useEffect(() => {
    if (forceScroll) setModalIsHigherThanScreen(true);
    else {
      if (modalRef && modalRef.current) {
        if (modalRef.current?.offsetHeight) {
          setModalIsHigherThanScreen(modalRef.current?.offsetHeight > windowHeight);
        }
      }
    }
  }, [modalRef.current, windowHeight]);

  //prevent scrolling of page behing modal
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = '';
      document.body.style.height = 'auto';
    }
  }, [onClose]);

  if (open)
    return (
      <div className={cls('modal-container', { modalIsHigherThanScreen })}>
        <div onClick={onClose} className="modalCover" />
        <div ref={modalRef} className={cls('modal', className)} style={style}>
          {children}
        </div>
      </div>
    );
  else return null;
};
