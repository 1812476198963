import { createAction } from '@reduxjs/toolkit';
import { CountryData } from '../../pages/Profile/KYCModal';
import { KYCSlice } from './slice';

export const { setKycStatus } = KYCSlice.actions;

export const invokeKYC = createAction<{ onError: (error: string) => void }>('kyc/invoke');

export const submitKYCData = createAction<{ phone: string; date: string; country: string; countryData: CountryData }>(
  'kyc/submit-data',
);
