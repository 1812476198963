import { all, call, CallEffect, put, PutEffect, takeLatest, RaceEffect, TakeEffect } from 'redux-saga/effects';
import { getDepositAddress, getDeposits } from '../../services/deposit';
import { storeAuthorization } from '../auth/actions';
import { AxiosResponse } from 'axios';
import { DepositAddressDto, DepositsDto } from '../../types/dto/Deposit.dto';
import { setDepositAddress, storeRawDepositData } from './actions';
import { setDepositsLoader } from '../loaders/actions';

function* getDepositData(): Generator<
  CallEffect | PutEffect | RaceEffect<CallEffect | TakeEffect>,
  void,
  AxiosResponse<DepositAddressDto> & AxiosResponse<DepositsDto>
> {
  try {
    try {
      const address = yield call(getDepositAddress);
      yield put(setDepositAddress(address.data));
    } catch {
      yield put(
        setDepositAddress({
          address: 'There is no active sale',
          round: 'inactive',
        }),
      );
    }

    const deposits = yield call(getDeposits);
    yield put(storeRawDepositData(deposits.data));

    yield put(setDepositsLoader(false));
  } catch (e) {
    console.error(e);
  }
}

export function* depositSagaWatcher(): Generator {
  yield all([takeLatest(storeAuthorization, getDepositData)]);
}
