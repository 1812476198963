import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  retry,
  select,
  SelectEffect,
  TakeEffect,
  takeLatest,
} from 'redux-saga/effects';
import { invokeKYC, setKycStatus } from './actions';
import { createKYC, getKYCUser } from '../../services/kyc';
import { AxiosResponse } from 'axios';
import { CreateKYCDto, ShowKYCDto } from '../../types/dto/KYC.dto';
import { AuthData, getAuthData } from '../auth/selectors';
import { KYCStatus } from './slice';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import snsWebSdk from '@sumsub/websdk';

const sumsubSandbox = Boolean(process.env.REACT_APP_SUMSUB_SANDBOX);
const sumsubApiUrl = sumsubSandbox ? 'https://test-api.sumsub.com' : 'https://api.sumsub.com';

function* processKYC(
  action: ReturnType<typeof invokeKYC>,
): Generator<
  CallEffect | TakeEffect | SelectEffect | PutEffect,
  void,
  AxiosResponse<CreateKYCDto> & ReturnType<typeof createKYC> & AuthData
> {
  try {
    // TODO: Do we need this still ?!
    // if user finished KYC skip this step by calling action payload
    // let kycData;
    // if (data.externalUserId) action.payload.nextStep();

    const response = yield call(createKYC);

    const snsWebSdkInstance = snsWebSdk
      .Builder(sumsubApiUrl, 'basic-kyc')
      .withAccessToken(response.data.token, () => {
        /* */
      })
      .withConf({
        lang: 'en',
        // eslint-disable-next-line
        onMessage: (type: string, payload: any) => {
          console.log('WebSDK onMessage', type, payload);
          if (type === 'idCheck.applicantStatus') {
            // Result of standalone module has been presented to the user.
            if (payload.reviewResult && payload.reviewResult.reviewAnswer === 'GREEN') {
              // Only reload on success
              // Don't know if otherwise some message in iframe is displayed
              location.reload();
            }
          }
        },
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  } catch (error) {
    action.payload.onError(
      error.response?.data?.error.validations || 'An unknown error occurred. Please try again later.',
    );
    console.error(error);
  }
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function* getKYCStatus(): Generator<CallEffect | SelectEffect | PutEffect, void, AuthData & AxiosResponse<ShowKYCDto>> {
  try {
    const { applicantId } = yield select(getAuthData);
    if (!applicantId) {
      yield put(setKycStatus(KYCStatus.Awaiting));
    } else {
      const result = yield retry(3, 5 * 1000, getKYCUser, applicantId);

      if (result.data.reviewStatus === 'init') yield put(setKycStatus(KYCStatus.Awaiting));
      if (result.data.reviewStatus === 'pending' || result.data.reviewStatus === 'queued')
        yield put(setKycStatus(KYCStatus.Pending));
      else if (result.data.reviewStatus === 'completed') {
        if (result.data.reviewResult?.reviewAnswer === 'GREEN') {
          yield put(setKycStatus(KYCStatus.Finish));
        } else if (
          result.data.reviewResult?.reviewAnswer === 'RED' &&
          result.data.reviewResult?.reviewRejectType === 'RETRY'
        ) {
          yield put(setKycStatus(KYCStatus.Retry));
        } else {
          yield put(setKycStatus(KYCStatus.Failed));
        }
      }
    }
  } catch (e) {
    yield put(setKycStatus(KYCStatus.Unknown));
  }
}

export function* kycSagaWatcher(): Generator {
  yield all([takeLatest(invokeKYC, processKYC)]);
}
