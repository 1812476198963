import React from 'react';
import CookieLib from 'react-cookie-consent';

export const CookieConsent: React.FC = () => {
  return (
    <CookieLib
      location="bottom"
      buttonText="Accept"
      cookieName="revutoTScookiepolicy"
      style={{
        background: '#FFFFFF',
        color: '#2F353B',
        fontSize: '18px',
        textAlign: 'center',
        boxShadow: '0 7px 64px 0 rgb(0 0 0 / 7%)',
        borderRadius: '22px',
        border: '1px solid #e6e6e9',
        padding: '20px 0',
        alignItems: 'center',
      }}
      buttonStyle={{
        background: 'linear-gradient(180deg, #5E9EFF 0%, rgba(27, 113, 241, 0.75) 100%)',
        boxShadow:
          '4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5)',
        borderRadius: '4px',
        color: '#FFFFFF',
        padding: '8px 15px',
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
      }}
      buttonWrapperClasses="flex center-xs middle-xs col-md-2"
      expires={150}
    >
      Revuto uses cookies to provide you with an optimal experience, as well as to improve your user experience. To
      learn more, please visit our <a href="/cookie-policy">Cookie Policy</a>.
    </CookieLib>
  );
};
