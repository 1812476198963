import React, { FC, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { HomePage } from './Home';
import { store } from '../ducks/store';
import { TermsOfUsePage } from './Plain/TermsOfUse';
import { TermsOfWallet } from './Plain/TermsOfWallet';
import { PrivacyPolicyPage } from './Plain/PrivacyPolicy';
// import { TokenSalePage } from './TokenSale';
import { CookiePolicyPage } from './Plain/CookiePolicy';
import { CookieConsent } from '../components/CookieConsent/CookieConsent';
import { BTC } from './BTC';
import { NFT } from './NFT';
import { RfundPage } from './Rfund';
import { WalletPage } from './Wallet';

export const App: FC = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Provider store={store}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/terms-of-use">
              <TermsOfUsePage />
            </Route>
            <Route exact path="/wallet-terms">
              <TermsOfWallet />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route path="/btc">
              <BTC />
            </Route>
            <Route path="/nft">
              <NFT />
            </Route>
            <Route exact path="/rfund">
              <RfundPage />
            </Route>
            <Route exact path="/wallet">
              <WalletPage />
            </Route>
            <Route exact path="/cookie-policy">
              <CookiePolicyPage />
            </Route>
          </Switch>
          <CookieConsent />
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
};
