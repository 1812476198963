import React from 'react';

import { ExternalLink } from '../ExternalLink';

import { ReactComponent as IconArrow } from '../../assets/icons/arrow.svg';
import { IconButton } from '../IconButton';

export const Footer: React.FC = () => {
  const [topButtonClassName, setTopButtonClassName] = React.useState('hidden');

  const onScrollToTopClick = (): void => {
    const distanceFromTheTop = document.documentElement.scrollTop || document.body.scrollTop;
    // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
    if (distanceFromTheTop > 0) {
      // Animate
      window.requestAnimationFrame(onScrollToTopClick);
      // Increase the '10' value to get a smoother/slower scroll
      window.scrollTo(0, distanceFromTheTop - distanceFromTheTop / 10);
    }
  };

  const scrollFunc = (): void => {
    const y = window.scrollY;

    if (y > 0) {
      setTopButtonClassName('show');
    } else {
      setTopButtonClassName('hidden');
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollFunc);
    return (): void => {
      // listener cleanup
      window.removeEventListener('scroll', scrollFunc);
    };
  }, []);

  return (
    <div className="section-spacing">
      <hr />
      <div className="wrapper large-wrapper">
        <footer className="between-xs">
          <div className="flex">
            <div className="logo" />
          </div>

          <div className="links">
            <ExternalLink href="https://revuto.com/terms-of-service/" className="light small">
              <span>Terms of use</span>
            </ExternalLink>
            <ExternalLink href="https://revuto.com/terms-for-revuto-crypto-wallet/" className="light small">
              <span>Terms for Revuto Crypto Wallet</span>
            </ExternalLink>
            <ExternalLink href="https://revuto.com/privacy-policy/" className="light small">
              <span>Privacy policy</span>
            </ExternalLink>
          </div>

          <div className="flex" />

          <IconButton onClick={onScrollToTopClick} className={topButtonClassName}>
            <IconArrow />
          </IconButton>
        </footer>
        <p className="copyright light center-xs">&copy; {new Date().getFullYear()} Revuto</p>
      </div>
    </div>
  );
};
