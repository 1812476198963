import React, { FC } from 'react';
import { Header } from '../../components/Header';
import HeroAvif from '../../assets/images/wallet/wallet.avif';
import HeroWebP from '../../assets/images/wallet/wallet.webp';
import HeroPng from '../../assets/images/wallet/wallet.png';
import offerIcon1 from '../../assets/images/wallet/1.png';
import offerIcon2 from '../../assets/images/wallet/2.png';
import offerIcon3 from '../../assets/images/wallet/3.png';
import offerIcon4 from '../../assets/images/wallet/4.png';
import subs from '../../assets/images/wallet/subs.png';
import { Footer } from '../../components/Footer';
import GooglePlay from '../../assets/images/GooglePlay.svg';
import AppStore from '../../assets/images/AppStore.svg';
import AppGallery from '../../assets/images/appgallery.png';
import { ExternalLink } from '../../components/ExternalLink';

export const WalletPage: FC = () => {
  return (
    <>
      <div className="wallet-page">
        <Header />
        <div className="wallet-hero wrapper">
          <div>
            <h1>First native non-custodial wallet on Cardano</h1>
            <div className="mobile-links">
              <ExternalLink href="https://play.google.com/store/apps/details?id=com.revuto.dev">
                <img src={GooglePlay} width="188" height="56" alt="Google play" />
              </ExternalLink>
              <ExternalLink href="https://apps.apple.com/us/app/revuto/id1550940719">
                <img src={AppStore} width="188" height="56" alt="App store" />
              </ExternalLink>
              <ExternalLink href="https://appgallery.huawei.com/app/C105005025">
                <img src={AppGallery} width="188" height="56" alt="Huawei App Gallery" />
              </ExternalLink>
            </div>
          </div>
          <picture>
            <source srcSet={HeroAvif} type="image/avif" />
            <source srcSet={HeroWebP} type="image/webp" />
            <img src={HeroPng} loading="lazy" width="472" height="558" />
          </picture>
        </div>
      </div>
      <main className="wallet-main">
        <div id="about" className="wrapper wallet-shorts">
          <div className="box-transparent-white">
            <p>Send, and Receive Cardano assets</p>
            <span className="box-transparent-white-shadow"></span>
          </div>
          <div className="box-transparent-white">
            <p>Pay subscriptions with revu</p>
            <span className="box-transparent-white-shadow"></span>
          </div>
          <div className="box-transparent-white">
            <p>Stake revu to earn rewards</p>
            <span className="box-transparent-white-shadow"></span>
          </div>
        </div>
        <div className="wrapper wallet-title">
          <h2>Revuto Wallet</h2>
          <h3>Offers unbelievable staking opportunities to maximize your financial potential</h3>
        </div>
        <div className="wrapper wallet-offers-grid">
          <div className="wallet-offers">
            <div className="wallet-offers-icon">
              <img src={offerIcon1} alt="Stack." width="60" height="60" />
            </div>
            <h3>Staking</h3>
            <p>
              Stake REVU (<span className="blue">10%</span> APR)
            </p>
            <p>Stake REVU to get EURR</p>
            <p>Stake (delegate) ADA in R Pool or any other Cardano staking pool</p>
          </div>
          <div className="wallet-offers">
            <div className="wallet-offers-icon">
              <img src={offerIcon2} alt="Stack." width="60" height="60" />
            </div>
            <h3>Combo staking</h3>
            <p>
              Stake ADA & Stake REVU (<span className="blue">12%</span> APR) for maximum profits
            </p>
          </div>
          <div className="wallet-offers">
            <div className="wallet-offers-icon">
              <img src={offerIcon3} alt="Stack." width="60" height="60" />
            </div>
            <h3>R Fund investment pool</h3>
            <p>Stake REVU to get EURR</p>
            <p>
              Invest EURR in R Fund investment pool (<span className="blue">10-30%</span> APR, depending on the R Fund
              performance)
            </p>
            <p>12M lockup period</p>
          </div>
          <div className="wallet-offers">
            <div className="wallet-offers-icon">
              <img src={offerIcon4} alt="Stack." width="60" height="60" />
            </div>
            <h3>MICRO-LENDING POOL</h3>
            <p>Stake REVU to get EURR</p>
            <p>
              Stake EURR (<span className="blue">48%</span> APR) to provide liquidity for micro-lending
            </p>
            <p>Variable pool size</p>
          </div>
        </div>
        <div className="wider">
          <h3 className="stake-header">Your subscriptions, your money, your decision</h3>
          <div className="wallet-stake">
            <div className="stake">
              <h3>STAKE REVU AND FORGET ABOUT YOUR SUBSCRIPTIONS!</h3>
              <p>
                According to the subscriptions you&apos;re paying with Revuto, we&apos;ll calculate the amount of REVU
                you need to stake in order to forget about your subscription expenses.
              </p>
            </div>
            <div className="stake-action">
              <div className="stake-subs">
                <img src={subs} alt="Stack." width="287" height="152" />
              </div>
              <button>Join to stake revu</button>
            </div>
          </div>
          <p className="stake-note">***Available to EEA & UK citizens only</p>
        </div>
      </main>
      <div className="pull-up">
        <Footer />
      </div>
    </>
  );
};
