import React from 'react';
import classNames from 'classnames';

interface IRoadmapSectionProps {
  period: string;
  year: string;
  events: string[];
}

// const emphasizedEvents = ['Open governance', 'Integrate fiat on-ramp in the Revuto App'];
const doneEvents = [
  'App and services support for Cardano native tokens and stablecoins',
  'Revuto Staking Center supporting R Fund, farming, staking, investing, presales',
  'Adding liquidity on decentralized exchanges on Cardano',
  'EMI (Electronic Money Institution) license from Bank of Lithuania',
  'FIAT to Crypto on-ramp with Banxa',
  'R token and Revudex - announcement',
  'Revulution NFTs, Subscription NFTs, Rstronaut NFTs',
  'Revuto PDCs (Physical Debit Cards) - presale',
  'Subscription Management and Password Sharing services',
];

export const RoadmapSection: React.FC<IRoadmapSectionProps> = ({ period, year, events }) => {
  return (
    <div className="roadmap-section column">
      <div className="row between-xs header">
        <p className="bold period">{period}</p>
        <p className="light">{year}</p>
      </div>
      <div className="column">
        {events.map((event) => (
          <div
            className={classNames('event middle-xs', {
              // emphasize: emphasizedEvents.includes(event),
              done: doneEvents.includes(event),
            })}
            key={event}
          >
            <span className="bullet" />
            <p className="extra-small bold">{event}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
