import axios, { AxiosResponse } from 'axios';
import { IPostSubscribeResponseData, ISubscriptionFormData } from '../types/dto/PublicData.dto';

export const TIMEOUT = 20 * 1000;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_REVUTO_COM_URL,
  timeout: TIMEOUT,
});

export const postSubscription = (
  data: ISubscriptionFormData,
  captchaKey: string,
): Promise<AxiosResponse<IPostSubscribeResponseData>> => instance.post('api/v1/auth/register', { ...data, captchaKey });
