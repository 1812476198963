import { AxiosResponse } from 'axios';
import { all, call, CallEffect, takeEvery } from 'redux-saga/effects';
import { postSubscription } from '../../services/public';
import { IPostSubscribeResponseData } from '../../types/dto/PublicData.dto';
import { submitSubscription } from './actions';

function* subscription({
  payload: { subFormData, recaptcha },
  meta,
}: ReturnType<typeof submitSubscription>): Generator<CallEffect, void, AxiosResponse<IPostSubscribeResponseData>> {
  try {
    yield call(postSubscription, subFormData, recaptcha);
    meta('You have successfully subscribed');
  } catch (error) {
    meta(
      error.response.data.error?.message ?? 'Failed to subscribe',
      error.response.data.error?.validations?.email ?? '',
    );
    if (error.response && error.response.data) {
      meta(error.response.data.error.message, error.response.data.error.validations.email);
    } else {
      console.error(error);
      meta('An error has occurred, please try again later.');
    }
  }
}
export function* subscribeSagaWatcher(): Generator {
  yield all([takeEvery(submitSubscription, subscription)]);
}
