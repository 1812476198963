import React, { useRef, FC, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from '../../components/Button';
import { InputField } from '../../components/InputField';
import { Modal } from '../../components/Modal';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { setSubModalVisible, submitSubscription } from '../../ducks/subscribe/actions';
import { getSubscribeModalState } from '../../ducks/subscribe/selectors';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

export const SubscriptionForm: FC = () => {
  const dispatch = useDispatch();
  const subFormOpen = useSelector(getSubscribeModalState);
  const [responseMessage, setResponseMessage] = useState<[string, string | undefined]>(['', '']);
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    recaptcha: '',
  };

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    setErrors,
    setFieldError,
    resetForm,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      email: yup.string().email('Invalid email address').required('Email is required'),
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      recaptcha: yup.string().required('Passing captcha is required'),
    }),
    initialValues,
    onSubmit: ({ email, firstName, lastName, recaptcha }) => {
      setSubmitting(true);
      dispatch(
        submitSubscription(
          { email, firstName, lastName },
          (message, details): void => {
            setResponseMessage([message, details]);
            resetForm();
            setSubmitting(false);
          },
          recaptcha,
        ),
      );
    },
  });
  const formRef = useRef(null);
  const wrapperRef = useRef(null);
  const h2Ref = useRef(null);
  const pRef = useRef(null);

  const handleOnWhiteSpaceClick = (e: React.MouseEvent<HTMLDivElement | HTMLFormElement, MouseEvent>): void => {
    if (
      e.target === wrapperRef.current ||
      e.target === formRef.current ||
      e.target === h2Ref.current ||
      e.target === pRef.current
    )
      setErrors(initialValues);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, valueField: string): void => {
    handleChange(e);
    setErrors({ ...errors, [valueField]: '' });
  };

  const handleModalClose = (): void => {
    dispatch(setSubModalVisible(false));
    setResponseMessage(['', undefined]);
  };

  return (
    <Modal open={subFormOpen} onClose={handleModalClose} className="cta-form">
      <div className="cta-wrapper" ref={wrapperRef} onClick={handleOnWhiteSpaceClick}>
        {!!responseMessage[0] ? (
          <>
            <h2 className="responseMessage">{responseMessage[0]}</h2>
            {responseMessage[1] && <p className="description response">{responseMessage[1]}</p>}
            <Button primary label="close" onClick={handleModalClose} />
          </>
        ) : (
          <>
            <h2 ref={h2Ref}>Subscribe to get early access to Revuto</h2>
            <p ref={pRef} className="description">
              To your email we will send you information about how to earn your referral benefits and rewards in
              Revutokens.
            </p>
            <form ref={formRef} onSubmit={handleSubmit} onClick={handleOnWhiteSpaceClick}>
              <InputField
                className="firstName"
                name="firstName"
                placeholder="First name"
                errorMessage={errors.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleInputChange(e, 'firstName')}
                onBlur={(): void => setErrors({ ...errors, firstName: '' })}
                value={values.firstName}
              />
              <InputField
                className="lastName"
                name="lastName"
                placeholder="Last name"
                errorMessage={errors.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleInputChange(e, 'lastName')}
                onBlur={(): void => setErrors({ ...errors, lastName: '' })}
                value={values.lastName}
              />
              <InputField
                className="email"
                name="email"
                placeholder="Email"
                errorMessage={errors.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleInputChange(e, 'email')}
                onBlur={(): void => setErrors({ ...errors, email: '' })}
                value={values.email}
              />
              <div className={classNames('input-field')}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  onChange={(token): void => {
                    setFieldValue('recaptcha', token || '');
                    setFieldError('recaptcha', token ? undefined : 'Captcha expires, try again');
                  }}
                />
                <div className={classNames('error-message', { active: errors.recaptcha })}>
                  <p>{errors.recaptcha}</p>
                </div>
              </div>
              <Button primary label={submitting ? 'Submitting...' : 'Subscribe'} disabled={submitting || !dirty} />
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};
