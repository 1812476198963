import { FC } from 'react';
// import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import ReactPaginate from 'react-paginate';

// import { Header } from '../../components/Header';
// import { NFT as INFT } from '../../types/dto/NFT';
// import { NFTBox } from '../../components/NFTBox';
// import { fetchNFTs } from '../../ducks/nft/actions';
// import { selectAllNFTs } from '../../ducks/nft/selectors';

// const NFTS_PER_PAGE = 100;

export const NFT: FC = () => {
  // 👇️ redirect to external URL
  window.location.replace('https://revuto.com/nft/');

  return null;
};

// export const NFT: FC = () => {
//   const dispatch = useDispatch();
//   const nfts = useSelector(selectAllNFTs);
//   const [displayedNFTs, setDisplayedNFTs] = useState<INFT[]>([]);

//   useEffect(() => {
//     dispatch(fetchNFTs());
//   }, []);

//   // First page nfts initialization
//   useEffect(() => {
//     if (displayedNFTs.length === 0 && nfts.length > 0) {
//       setDisplayedNFTs(nfts.slice(0, NFTS_PER_PAGE));
//     }
//   }, [nfts, displayedNFTs]);

//   const handlePageClick = useCallback(
//     ({ selected }) => {
//       const offset = Math.ceil(selected * NFTS_PER_PAGE);
//       setDisplayedNFTs(nfts.slice(offset, offset + NFTS_PER_PAGE));
//     },
//     [nfts, displayedNFTs, setDisplayedNFTs],
//   );

//   const pageCount = useMemo(() => 1826 / NFTS_PER_PAGE, []);

//   return (
//     <div className="nft-page page-wrapper">
//       <Header whiteHeader />
//       <div className="claim-message">
//         <p>
//           Claimed NFT? See your NFT <a href="/claim-nft">here</a>
//         </p>
//       </div>
//       <div className="nft-list wrapper">
//         {displayedNFTs.map((nft) => (
//           <NFTBox image={`${process.env.REACT_APP_IPFS_GATEWAY}${nft.cid}`} id={nft.id} key={nft.id} />
//         ))}
//       </div>
//       <ReactPaginate
//         previousLabel={'<'}
//         nextLabel={'>'}
//         breakLabel={'...'}
//         breakClassName={'break-me'}
//         pageCount={pageCount}
//         marginPagesDisplayed={1}
//         pageRangeDisplayed={3}
//         onPageChange={handlePageClick}
//         containerClassName={'pagination'}
//         activeClassName={'active'}
//       />
//     </div>
//   );
// };
