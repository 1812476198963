import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuyerNFTClaim, NFT } from '../../types/dto/NFT';

interface NFTState {
  allNFTs: NFT[];
  unclaimedNFTs: NFT[];
  randomSpinnerNFTs: {
    spinner: NFT[];
    winner: NFT;
  } | null;
  buyerNFTClaim: BuyerNFTClaim | null;
}

const initialState: NFTState = {
  allNFTs: [],
  unclaimedNFTs: [],
  randomSpinnerNFTs: null,
  buyerNFTClaim: null,
};

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    storeAllNFTs: (state, action: PayloadAction<NFT[]>): void => {
      state.allNFTs = action.payload;
    },
    storeUnclaimedNFTs: (state, action: PayloadAction<NFT[]>): void => {
      state.unclaimedNFTs = action.payload;
    },
    storeRandomSpinnerNFTs: (state, action: PayloadAction<NFT[]>): void => {
      state.randomSpinnerNFTs = {
        spinner: action.payload,
        winner: action.payload[0],
      };
    },
    storeBuyerNFTClaim: (state, action: PayloadAction<BuyerNFTClaim>): void => {
      state.buyerNFTClaim = action.payload;
    },
  },
});
