import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ExternalLink } from '../../components/ExternalLink';

export const TermsOfUsePage: React.FC = () => {
  return (
    <>
      <div className="page-wrapper wrapper plain-pages">
        <h1>Terms</h1>
        <p>Effective as of 1st December, 2021.</p>
        <p>Welcome to Revuto!</p>
        <p>
          Revuto (as defined in Section 1) is owned and operated by REVUTO OÜ, Keemia tn 4, 10616 Tallinn, Estonia, TIN:
          16108647, VAT number: EE102322835.
        </p>
        <p>
          By accessing, downloading, using or attempting to use Revuto, you enter into a legally binding contract with
          REVUTO O&Uuml; (and some of our service providers) and you agree to these Revuto Terms of Use,{' '}
          <a href="/privacy-policy">Privacy policy</a>, <a href="/cookie-policy">Cookie policy</a>,{' '}
          <ExternalLink href="https://www.manigo.com/wp-content/uploads/2020/10/Manigo-Terms-of-Service-Cardholder-Terms-and-Conditions.pdf">
            Manigo Terms of Service and Cardholder Terms and Conditions
          </ExternalLink>{' '}
          including any supplemental terms that accompany the Platform (as defined in Section 1), any documents and any
          linked terms in the Terms of Use.
        </p>
        <p>
          In addition, when using some features of the Revuto Services, you may be subject to specific additional terms
          applicable to those features (for example, to use Revuto Crypto Wallet you need to comply with the{' '}
          <a href="/wallet-terms">Terms for Revuto Crypto Wallet</a>) and enter into agreement with the Affiliate (as
          defined in Section 1).
        </p>
        <p>
          As Revuto is a mobile app available on app stores, the app store provider’s terms and conditions/EULA will
          also apply to you.
        </p>
        <p>This document contains the following terms:</p>
        <ol>
          <li>
            REVUTO TERMS OF USE which govern your contractual relationship with REVUTO OÜ while using the Platform{' '}
          </li>
          <li>
            MANIGO TERMS OF SERVICE which govern your contractual relationship with a Technical Service Provider (as
            defined in Section 1) which supports the provision of the payment services by the Card Issuer (including
            Schedule 2 that contains the Schedule of the applicable Fees)
          </li>
          <li>
            CARDHOLDER TERMS AND CONDITIONS which govern your contractual relationship with Card Issuer (as defined in
            Section 1) when using Revuto Virtual Debit Card.
          </li>
        </ol>
        <p>
          All the terms are important and together create this Agreement that applies to you. If you find anything in
          this text that you do not agree with, please:
        </p>
        <ul>
          <li>Do not use Revuto or part thereof</li>
          <li>Do not download Revuto</li>
          <li>Do not access Revuto Platform (as defined below)</li>
          <li>
            If you have already created a User account, stop using Platform immediately and/or delete your User account.
          </li>
        </ul>
        <p>
          Your consent to these Terms of Use further represents, warrants and certifies that the information provided by
          you during the application process for opening a User account is true and complete to the best of your
          knowledge.
        </p>
        <p>
          Prior to downloading Revuto Crypto Wallet, you will need to consent to the{' '}
          <a href="/wallet-terms">Terms for Revuto Crypto Wallet</a>.
        </p>
        <h1>Revuto Terms of Use</h1>
        <p>
          <AnchorLink href="#definitions">
            <h5>1. DEFINITIONS</h5>
          </AnchorLink>
          <AnchorLink href="#who-can-use">
            <h5>2. WHO CAN USE</h5>
          </AnchorLink>
          <AnchorLink href="#consent">
            <h5>3. CONSENT AND THE SERVICE</h5>
          </AnchorLink>
          <AnchorLink href="#amendments">
            <h5>4. AGREEMENT AMENDMENTS</h5>
          </AnchorLink>
          <AnchorLink href="#communications">
            <h5>5. ELECTRONIC COMMUNICATIONS</h5>
          </AnchorLink>
          <AnchorLink href="#ip">
            <h5>6. INTELLECTUAL PROPERTY</h5>
          </AnchorLink>
          <AnchorLink href="#use">
            <h5>7. ACCEPTABLE USE</h5>
          </AnchorLink>
          <AnchorLink href="#content">
            <h5>8. USER CONTENT AND USER DATA</h5>
          </AnchorLink>
          <AnchorLink href="#subscription">
            <h5>9. SUBSCRIPTION TO REVUTO APP</h5>
          </AnchorLink>
          <AnchorLink href="#vdc">
            <h5>10. REVUTO VIRTUAL DEBIT CARD (REVUTO VDC)</h5>
          </AnchorLink>
          <AnchorLink href="#purchases">
            <h5>11. MOBILE IN-APP PURCHASES</h5>
          </AnchorLink>
          <AnchorLink href="#referral">
            <h5>12. REVUTO REFERRAL PROGRAMS AND REVUTOKENS</h5>
          </AnchorLink>
          <AnchorLink href="#warranties">
            <h5>13. DISCLAIMER OF WARRANTIES</h5>
          </AnchorLink>
          <AnchorLink href="#liability">
            <h5>14. LIMITATION AND EXCLUSION OF LIABILITY</h5>
          </AnchorLink>
          <AnchorLink href="#idemnification">
            <h5>15. INDEMNIFICATION</h5>
          </AnchorLink>
          <AnchorLink href="#termination">
            <h5>16. TERMINATION</h5>
          </AnchorLink>
          <AnchorLink href="#severability">
            <h5>17. SEVERABILITY</h5>
          </AnchorLink>{' '}
          <AnchorLink href="#notices">
            <h5>18. NOTICES</h5>
          </AnchorLink>{' '}
          <AnchorLink href="#waiver">
            <h5>19. NO WAIVER</h5>
          </AnchorLink>
          <AnchorLink href="#third-party">
            <h5>20. LINKS TO THIRD-PARTY WEBSITES</h5>
          </AnchorLink>
          <AnchorLink href="#jurisdiction">
            <h5>21. JURISDICTION AND CHOICE OF LAW; DISPUTE RESOLUTION</h5>
          </AnchorLink>
          <AnchorLink href="#action">
            <h5>22. NO CLASS ACTION</h5>
          </AnchorLink>
          <AnchorLink href="#agreement">
            <h5>23. ENTIRE AGREEMENT</h5>
          </AnchorLink>
        </p>

        <h4 id="definitions">1. DEFINITIONS</h4>
        <p>
          When we say <strong>“Affiliate”</strong> we mean Revuto Savjetovanje d.o.o, a company registered in Croatia,
          Vladimira Nazora 21, 10000 Zagreb, CIN 05361567.
        </p>
        <p>
          When we say <strong>“Agreement”</strong> we mean the contract comprising these Revuto Terms of Use (as amended
          from time to time under Section 4), <a href="/privacy-policy">Privacy policy</a>,{' '}
          <a href="/cookie-policy">Cookie policy</a>, Subscription Plans,{' '}
          <ExternalLink href="https://www.manigo.com/wp-content/uploads/2020/10/Manigo-Terms-of-Service-Cardholder-Terms-and-Conditions.pdf">
            Manigo Terms of Service and Cardholder Terms and Conditions
          </ExternalLink>{' '}
          and any terms linked in this document, as well as any other information on Platform mentioned in this
          document. In addition, the term Agreement may encompass specific terms applicable to certain features (for
          example, <a href="/wallet-terms">Terms for Revuto Crypto Wallet</a>).
        </p>
        <p>
          When we say <strong>“AML/CTF”</strong> we mean the applicable Anti-Money Laundering / Combating the Financing
          of Terrorism regulations and requirements.
        </p>
        <p>
          When we say <strong>“Card Issuer”</strong> we mean CashFlows Europe Limited (registered number 5428358), CPC1,
          Capital Park, Cambridge, CB21 5XE, United Kingdom, CashFlows Europe Limited is an electronic money issuer
          regulated by the Financial Conduct Authority under registration number FRN: 900006.
        </p>
        <p>
          When we say <strong>“Content”</strong> we mean all Revuto&apos;s features and technical resources available to
          Users, including but not limited to information, data, text, photographs, videos, audio clips, software,
          scripts, graphics and interactive features generated, provided, or otherwise made accessible on or through
          Platform.
        </p>
        <p>
          When we say <strong>“Intellectual Property Rights”</strong> we mean any and all registered and unregistered
          rights granted, applied for or otherwise now or hereafter in existence under or related to any patent,
          copyright, trademark, trade secret, database protection or another intellectual property right, and all
          similar or equivalent rights or forms of protection, in any part of the world.
        </p>
        <p>
          When we say <strong>“KYC”</strong> we mean due diligence on the User (known as Know Your Customer) check
          carried out in accordance with AML/CTF and the Agreement. REVUTO OÜ has sole discretion to accept or reject
          the User after the KYC.
        </p>
        <p>
          When we say <strong>“Party”</strong> or <strong>“Parties”</strong> we refer to REVUTO O&Uuml; and/or User.
        </p>
        <p>
          When we say <strong>“Privacy Policy”</strong> we refer to REVUTO O&Uuml; personal data protection policy
          available <a href="/privacy-policy">here</a>.
        </p>
        <p>
          When we say <strong>“REVUTO O&Uuml;”</strong>, <strong>“we”</strong> or <strong>“us”</strong> we are referring
          to Revuto O&Uuml;<em>, a </em>company registered in Estonia<em>, </em>Keemia tn 4, 10616 Tallinn, TIN:
          16108647, VAT number: EE102322835.
        </p>
        <p>
          When we say <strong>“Revuto”</strong> or <strong>“Platform”</strong> we refer to the Website, Software, and
          Revuto Services collectively.
        </p>
        <p>
          When we say <strong>“Revuto Services”</strong> we mean all services within the following Revuto ecosystem
          components:
          <ul>
            <li>
              The subscription management software (including but not limited to Revuto Referral program, Revuto
              Cashback, Revuto Redeem Codes)
            </li>
            <li>The governance/utility Revu token (including but not limited to Revuto Crypto Wallet)</li>
            <li>The decentralized Revuto micro-lending and borrowing platform.</li>
          </ul>
        </p>
        <p>
          When we say <strong>“Revuto App”</strong> we mean making the Software available by REVUTO OÜ in any version in
          full or in part, including any updates, upgrades, enhancements, modifications, new features, programs and
          tools enabling Users to control their online subscription to services offered by third-party providers by
          allowing, snoozing or blocking payments as they occur.
        </p>
        <p>
          When we say <strong>“Revuto Virtual Debit Card”</strong> or <strong>“Revuto VDC”</strong> we mean virtual
          debit cards issued under the Agreement for each subscription to third-party services to be managed via Revuto
          App under Manigo Terms of Service and Cardholder Terms and Conditions.
        </p>
        <p>
          When we say <strong>“Software”</strong>, we refer to the mobile app and its updates, upgrades, enhancements,
          modifications, extensions, new features and possible replacements provided by REVUTO OÜ, now existing or later
          developed, and other programs and tools, developed in conjunction therewith including the integrated
          applications (for example, Revuto Crypto Wallet).
        </p>
        <p>
          When we say <strong>“Subscription Plans”</strong> we mean freemium and premium plans in Revuto app.
        </p>
        <p>
          When we say <strong>“Technical Service Provider”</strong> or <strong>“Manigo”</strong> we mean Manigo Services
          Limited, a company incorporated in England and Wales, with company number 9894618 and whose registered office
          is at LEVEL39 TECH HUB, One Canada Square, E14 5AB, LONDON UK. Manigo is a technical service provider under
          the terms of the Payment Services Regulations 2009 and provides support to the provision of payment services
          to you by Card Issuer. Manigo does not receive, hold, transfer or come into possession of User’s funds. Manigo
          provides the following technical services: administering your enrollment into that service; receiving, and
          relaying your transfer instructions for execution by the Card Issuer and User; reporting services regarding
          the funds held or transferred for you by the Card Issuer and User; and, associated customer support.
        </p>
        <p>
          When we say <strong>“Terms of Use”</strong> or <strong>“TOU”</strong> we mean the rules set out in this
          document.
        </p>
        <p>
          When we say <strong>“User”</strong>, <strong>“You”</strong> or <strong>“you”</strong> we refer to any person
          other than REVUTO OÜ, that uses, downloads, accesses or attempts to access Platform or any Revuto Service.
        </p>
        <p>
          When we say <strong>“User Content”</strong> we mean any content provided by the User to the Platform or Revuto
          community or social media including any entered, recorded, stored, used, controlled, modified, disclosed,
          transmitted or erased information and data.
        </p>
        <p>
          When we say <strong>“User Data”</strong> we mean data in electronic form input or collected through the
          Software or Platform by or from any User (in the broadest possible interpretation of the term), including
          without limitation personal data (as defined in <a href="/privacy-policy">Privacy policy</a>).
        </p>
        <p>
          When we say <strong>“Website”</strong> we mean the website located at https://revuto.com/, the website located
          at https://crypto.revuto.com/, as well as the related mobile app collectively or each of them individually.
        </p>
        <p>Defined words can be recognized throughout this document by the capitalized first letter.</p>
        <p>
          Titles and headings provided in these TOU are for convenience and ease of access only and they will not affect
          the interpretation of the TOU.
        </p>
        <p>
          In the event of discrepancy between Revuto Terms of Use and Manigo Terms of Service and Cardholder Terms and
          Conditions in relation to matters connected to Revuto VDC, Manigo Terms of Service and Cardholder Terms and
          Conditions shall prevail.
        </p>
        <h4 id="who-can-use">2. WHO CAN USE REVUTO?</h4>
        <p>
          <strong>User Restrictions</strong>
        </p>
        <p>Revuto is intended for natural persons who have full legal capacity.</p>
        <p>
          You need to be at the age of majority (legal age) to be able to use Revuto. Legal age depends on the national
          legislation applicable to the User (probably you need to be 18 years old). By using Revuto, you represent that
          you are of legal age. If you are not at the required age, please stop using the Revuto immediately.
        </p>

        <p>By entering this Agreement, the User represents and warrants to the Revuto that:</p>

        <p>(a) all information provided by User to the Revuto is truthful, accurate and complete;</p>

        <p>
          (b) User will comply with all laws and regulations of any applicable jurisdiction with regard to User&apos;s
          access, use or installation of the Software;
        </p>

        <p>
          (c) User has provided and will provide accurate and complete information as required for access, KYC, use of
          Revuto Services and installation of the Software.
        </p>
        <p>
          <strong>Territorial Restrictions</strong>
        </p>
        <p>Some Revuto Services are available only to the residents of a certain territory (&quot;Territory&quot;).</p>

        <p>
          For example, subscription management software and the connected services are only available to the residents
          of the countries within EEA and Turkey.
        </p>

        <p>
          A User may obtain benefits of the Referral program provided the User is a resident of any country other than
          the countries under embargo, such as
        </p>
        <ul>
          <li>Cuba,</li>
          <li>Islamic Republic of Iran,</li>
          <li>North Korea,</li>
          <li>Syria,</li>
          <li>Sudan,</li>
          <li>Iraq,</li>
          <li>Libya,</li>
          <li>South Sudan,</li>
          <li>and Crimea.</li>
        </ul>
        <p>
          The Platform or some of its parts may be accessed globally (for example, sign up) and we may not control who
          uses the Platform. However, even if you sign up outside the Territory, you will not be able to use Revuto App
          to manage subscriptions as you will not be able to receive the Revuto VDC or complete the KYC successfully. By
          opening a User account you represent and warrant to us that the opening of the User account does not violate
          any laws or regulations applicable to you.
        </p>
        <p>
          <strong>Identity Authentication</strong>
        </p>
        <p>
          You authorize REVUTO O&Uuml;, directly or through third parties, to make any inquiries we consider necessary
          to verify your identity. This may include:
        </p>
        <ul>
          <li>
            asking you for further information, including but not limited to your date of birth, your taxpayer or
            national identification number, proof of your physical address and other information that will allow us to
            reasonably identify you;
          </li>
          <li>requiring you to take steps to confirm ownership of your email address or financial instruments;</li>
          <li>verifying your information against third party databases or through other sources; or</li>
          <li>
            requiring you to provide further documentation, such as your driver&apos;s license or other identifying
            documents at any time.
          </li>
        </ul>
        <p>
          AML/CTF may require that REVUTO OÜ verify certain identifying information, as described in more detail in our{' '}
          <a href="/privacy-policy">Privacy policy</a>. REVUTO OÜ reserves the right to close, suspend, or limit access
          to your User account and/or the Revuto Services in the event that, after reasonable enquiries, we are unable
          to obtain information about you required to verify your identity.
        </p>
        <p>
          For clarity, only Users who go through the KYC successfully may use all Revuto Services in accordance with the
          Agreement. Users who do not go through the KYC may only obtain access to the User account, may send referral
          links to third parties and may use Revuto Crypto Wallet (in accordance with the{' '}
          <a href="/wallet-terms">Terms for Revuto Crypto Wallet</a>), but without the right to earn Revu Token through
          Referral Program or use any other Revuto Service not explicitly mentioned herein.
        </p>
        <p>
          REVUTO OÜ may suspend or terminate access to Revuto Services to any User even after successful KYC if REVUTO
          OÜ obtains information that the User failed to provide accurate information during the KYC.
        </p>
        <p>
          <strong>Compliance with the Law</strong>
        </p>
        <p>
          It is Users’ responsibility to abide by local laws in relation to the legal usage of Revuto Services in their
          local jurisdiction as well as other laws and regulations applicable to Users (for example, legislation
          applicable to digital assets transactions). Each User also confirms to comply with all applicable tax
          regulations to such User.
        </p>
        <p>
          <strong>
            THE USER DECLARES THAT THEIR FUNDS DO NOT ORIGINATE FROM ILLEGAL ACTIVITIES AND HEREBY AGREE THAT REVUTO MAY
            REQUIRE THEM TO PROVIDE OR OTHERWISE COLLECT THE NECESSARY INFORMATION TO VERIFY THE LEGALITY OF THE SOURCES
            AND USE OF THEIR FUNDS UNDER THE APPLICABLE LAWS AND REGULATIONS.
          </strong>
        </p>
        <h4 id="consent">3. CONSENT AND THE SERVICE</h4>
        <p>User shall be bound by this Agreement in any of the following situations, whichever occurs first:</p>
        <p>(1) Upon creating a User account.</p>
        <p>Downloading the Software as mobile app </p>
        <p>
          (3) If User agrees to or is deemed to have agreed to the Agreement. Any use, access or attempt to use or
          attempt to access the Platform shall be considered deemed to agree
        </p>
        <p>(4) If the User makes the payment for the Revuto App (subscribe to a paid Subscription plan)</p>
        <p>(5) If the User takes part in any Revuto Service (for example, Revuto Referral Program).</p>
        <p>
          You acknowledge that REVUTO OÜ may modify the features and functionality of the Platform or part thereof at
          any time during the Term of the Agreement.
        </p>
        <p>
          The full list and description of Revuto Services are available on the Website and the User must check the
          Content for up-to-date information.
        </p>
        <p>
          We will make the Revuto Services available to you under this Agreement. We will use commercially reasonable
          efforts to make the Platform available 24 hours a day, 7 days a week, except (a) during planned downtime (of
          which we will give advance notice); and (b) for any unavailability caused by circumstances beyond our
          reasonable control, including, for example, a force majeure event (c) internet problems outside our control,
          (v) bugs in code, hardware or Services without a commercially known fix. Specific Users may experience an
          inability to use the Platform because of the suspension or termination of their User account. Nevertheless, we
          cannot guarantee that the Services will meet your requirements or be available on an uninterrupted, secure, or
          error-free basis.
        </p>
        <p>
          User shall be solely responsible for providing, maintaining and ensuring compatibility with the Software, all
          hardware, software, electrical and other physical requirements for User&apos;s use of the Software, including,
          without limitation, telecommunications and internet access connections and links, web browsers or other
          equipment, programs and services required to access and use the Software.
        </p>
        <h4 id="amendments">4. AGREEMENT AMENDMENTS</h4>
        <p>REVUTO O&Uuml; may revise and update these Terms of Use or any part of the Agreement at any time.</p>
        <p>
          If you use Revuto, you are cautioned to review the Terms of Use periodically. Any changes shall enter into
          force upon being published on the Website and/or after at least 10 days after you have received a notification
          from us via email or otherwise on your User account, at our sole discretion. Your continued access or use
          after any such change will constitute your acceptance of these changes.
        </p>
        <p>
          If you do not agree to the new terms of the Agreement, and you have not subscribed to a paid Subscription
          plan, you must stop using Revuto. For Users subscribed to a paid Subscription plan, the existing Agreement
          will continue to be valid until the expiration of the then-current billing term (for example, until the expiry
          of the month for which the User has already made payment).
        </p>
        <p>
          REVUTO OÜ, Card Issuer, and Technical Service Provider reserve the right to change any of its third-party
          service providers, with or without notice, that we, in our sole discretion, deem to be reasonable in the
          circumstances. You agree to cooperate with REVUTO OÜ, Card Issuer, Technical Service Provider and any other
          service provider as reasonably required to perform services for which it is responsible.
        </p>
        <h4 id="communications">5. ELECTRONIC COMMUNICATIONS</h4>
        <p>
          By accepting these TOU, you agree to this electronic contract. You consent to receive electronic
          communications and you agree that all agreements, notices, disclosures and other communications that we
          provide to you electronically, via email, satisfy any legal requirement that such communications be in
          writing.
        </p>
        <p>
          Please note that notifications about any amendment of TOU by Section 3 (Agreement Amendments) or any material
          change to the Revuto Services or the Agreement we will send as stipulated in Section 17 (Notices).
        </p>
        <p>
          We have a legal obligation to inform you about the change of the terms so you can decide whether to continue
          using Revuto Services. Such correspondence does not constitute marketing or promotional emails, and you cannot
          unsubscribe from receiving such notifications. If you do not wish to receive such notifications, you need to
          terminate the Agreement by deleting your User account.
        </p>
        <h4 id="ip">6. INTELLECTUAL PROPERTY</h4>
        <p>
          Unless otherwise indicated in the Agreement, the Platform and the entire Content (including but not limited to
          the source code, Website and Revuto App “look and feel”, Website and mobile application copy, images, graphic
          elements, user interface, audio and video clips, design, databases, logo or other signs, domain, trade name
          and business name, trademarks or service marks, any customized work and other related materials) are protected
          by Intellectual Property Rights of REVUTO OÜ or its Affiliate, agents or licensors, as applicable.
        </p>
        <p>
          Users have only the rights specified under this Section of the Agreement. Users may not acquire any other
          Intellectual Property Rights under this Agreement. No ownership right may be conveyed to any User,
          irrespective of the use of terms such as &quot;purchase&quot; or &quot;sale&quot; in TOU or anywhere on the
          Platform.
        </p>
        <p>
          Any unauthorized use of the Platform and Content and/or any part of it, without our permission, shall be
          deemed an infringement of Intellectual Property Rights. REVUTO O&Uuml; will take all legal remedies to protect
          its Intellectual Property Rights immediately upon the knowledge of such unauthorized use.
        </p>
        <p>REVUTO O&Uuml; also reserves all Intellectual Property Rights not expressly granted in this Agreement.</p>
        <p>
          If you believe that any material on this Website or Revuto Service, including any material posted by members,
          violates your copyright, please notify us at <a href="mailto:support@revuto.com">support@revuto.com</a>.Revuto
          team will respond to all copyright infringement notices that it receives without undue delay.
        </p>
        <p>
          In consideration of your acceptance of this Agreement and your payment of all applicable fees (if any), REVUTO
          OÜ grants you a personal, limited, non-exclusive, non-transferable, non-sublicensable, revocable authorization
          to access and use the Revuto Services for your personal purposes under the Agreement and any other
          instructions on the Platform.
        </p>
        <p>
          If you are using Revuto Software such as API or other software application, that you have downloaded through
          an app platform/store, REVUTO OÜ grants you a limited, non-exclusive, non-transferable, non-sublicensable,
          revocable license to use Revuto Software in accordance with the documentation accompanying such Software and
          all its updates, upgrades and new versions.
        </p>
        <p>
          Any other use of the Platform, Software or the Revuto Services, not specifically mentioned in this Agreement,
          by any User, is forbidden. For example, you may not:
        </p>
        <ul>
          <li>
            publish, copy, rent, lease, lend, sell, create derivative works or transfer in any way the Software,
            Website, Revuto Services or any portion(s) of the foregoing;
          </li>
          <li>
            distribute, transmit, publish or otherwise disseminate the Software, Website, Revuto Services or any
            portion(s) of the foregoing;
          </li>
          <li>
            attempt to access or derive the source code or architecture of the Software or work around any technical
            restrictions or limitations in the Software;
          </li>
          <li>reverse engineer, decompile, or disassemble the Software, or attempt to do so;</li>
          <li>
            when using internet-based features, you may not use those features in any way that could interfere with
            anyone else&apos;s use of them, or to try to gain access to or use any service, data, account, or network,
            in an unauthorized manner;
          </li>
          <li>
            attempt to probe, scan or test the vulnerability of the Website, Services and/or Software, or any associated
            system or network, or to breach any security or authentication feature or measures, and, if you are blocked
            by REVUTO O&Uuml; from accessing the Platform or Revuto Services (including by blocking your IP address),
            you will not implement any measures to circumvent such blocking (e.g., by masking your IP address or using a
            proxy IP address).
          </li>
        </ul>
        <p>
          For the purpose of clarity, Users may not obtain access to source code under the Agreement and should not
          attempt to do so.
        </p>
        <h4 id="use">6. ACCEPTABLE USE</h4>
        <p>
          To use Revuto (including participation in Revuto community and social media), each User must comply with these
          rules of acceptable use:
        </p>
        <ul>
          <li>The User must provide complete information for registration purposes.</li>
          <li>
            The User must provide accurate and up-to-date information. The User has to use accurate contact information.
            Using false identity is strongly prohibited.
          </li>
          <li>
            The User will prevent any other person from using that User&apos;s account. Use of the User account by more
            persons is prohibited.
          </li>
          <li>The User may have only one User account.</li>
          <li>The User may not engage in selling or renting or transacting the User’s account in any way.</li>
          <li>
            The User must maintain the security of the account and password. User is responsible and liable for any use
            of Revuto through User&apos;s account, whether authorized or unauthorized. REVUTO OÜ cannot be held liable
            for any loss, damages or expenses incurred due to the User&apos;s failure to comply with this obligation.
            The User will be liable for all losses, damages, liability and expenses incurred by REVUTO OÜ or a third
            party as a consequence of authorized use of the account. If you become aware of any unauthorized use of your
            account on Revuto, you need to immediately notify us by sending an email to support@revuto.com
          </li>
          <li>
            The User will not engage in activity that violates the privacy of others, or any misuse or unlawful
            processing of personal data, nor will publicly display or use Revuto to share inappropriate content or
            material. The User may not violate any applicable law or regulations in connection with your use of Revuto.
          </li>
          <li>
            The User will not access the Revuto Services or the Software to build a competitive product or service, to
            build a product using similar ideas, features, functions or graphics, or to copy any ideas, features,
            functions or graphics.
          </li>
          <li>
            The User will not engage in web scraping or data scraping on or related to the Software or the Platform,
            including without limitation collection of information through any software that simulates human activity or
            any bot or web crawler.
          </li>
          <li>
            The User will not automate access to the Website or the Services, including, without limitation, through the
            use of APIs, bots, scrapers or other similar devices.
          </li>
          <li>
            The User will not commence or engage in any behaviour that results in personal attacks or ad hominem
            arguments, swearing, discrimination of any kind, harrasment, provocation or abuse of any kind.
          </li>
          <li>
            The User will not commence or engage in any correspondence irrelevant or disconnected from the use of Revuto
            Services (for example, discussion on political or religious issues).
          </li>
          <li>
            The User will not use Revuto for spamming or shilling or for the promotion of any product or service,
            project, cryptocurrency, etc.
          </li>
          <li>
            The User will not engage in disseminating negative and dubious or false information and a manifestation of
            the appeal to fear (FUD).
          </li>
        </ul>
        <p>
          We will strive to prevent any activity that may infringe these rules, but we do not undertake to monitor each
          and every User’s activity and REVUTO OÜ may not be held liable for such breach. Users are fully responsible
          for all the activities that occur under their User accounts or social media accounts.
        </p>
        <p>
          Breach of any rule under this Section results in immediate suspension of the use of Revuto Services (partially
          or in full at our discretion) and may result in termination of the Agreement with the User without any notice.
        </p>
        <h4 id="content">8. USER CONTENT AND USER DATA</h4>

        <p>Users are solely responsible for all User Data and User Content.</p>

        <p>
          REVUTO O&Uuml; will have no responsibility or liability for the accuracy of data uploaded to the Software by
          User, including without limitation User Data and any other data uploaded by Users.
        </p>

        <p>
          By accepting this Agreement, each User warrants represents and covenants that the User owns or has a valid and
          enforceable license to use all User Content. User Content will not infringe, misappropriate or violate the
          rights of any person or entity or any applicable law, rule or regulation of any government authority of
          competent jurisdiction. The User remains the owner of User Content and User Data under the Agreement.
        </p>

        <p>
          Nevertheless, by posting User Content on Platform or Revuto Community, you grant to REVUTO O&Uuml; a
          non-exclusive, irrevocable, transferable, sub-licensable, royalty-free, worldwide right and license to use
          User Content and associated Intellectual Property Rights in any way in any media know now or in the future.
          You agree to waive moral rights and promise not to assert such rights against REVUTO O&Uuml;.
        </p>

        <p>
          You are also prohibited from providing false information on Revuto Community. If you find that there is
          information on your profile that is no longer accurate, it is your responsibility to edit it or alert REVUTO
          O&Uuml;.
        </p>

        <p>
          REVUTO O&Uuml; reserves the right to refuse, limit or cancel the Revuto Service to a User, terminate User
          accounts, or remove or edit User Content at its sole discretion. Therefore, when investigating alleged
          violations of this Agreement, REVUTO O&Uuml; reserves the right to review your User Content to resolve the
          issue (such as to prevent harmful or illegal activity). REVUTO O&Uuml; may also access the User Content when
          providing technical support or when performing other legal obligations under this Agreement.
        </p>

        <p>
          Nevertheless, REVUTO O&Uuml; has no obligation to monitor all User Content (and will make no attempt to do so)
          and has no obligation to remove any User Content.
        </p>

        <p>
          REVUTO O&Uuml; cannot be held responsible for any loss, damage, expense or other harmful consequences to any
          User resulting from User Content.
        </p>

        <p>
          In the event becomes aware of the illegal User Content, activities that infringe anyone&apos;s Intellectual
          Property Rights or personal data or any other right, or activities that infringe the Agreement, REVUTO O&Uuml;
          may, in its sole discretion, disable, close, temporarily or permanently limit access to any User account
          without any notice. REVUTO O&Uuml; may not be liable for any loss, damages or undesirable consequences
          resulting from such action.
        </p>

        <p>
          REVUTO O&Uuml; may permanently erase User Data if the User account is delinquent, suspended, or terminated for
          30 (thirty) days or more with or without notice.
        </p>

        <h4 id="subscription">9. SUBSCRIPTION TO REVUTO APP</h4>
        <p>
          Information on Revuto App features and how it functions, please see{' '}
          <ExternalLink href="https://revuto.com/">here</ExternalLink>.
        </p>

        <p>
          By creating a User account at Revuto you automatically start your free Subscription Plan. Free Subscription
          Plan entails payment of our protection fee per transaction which amounts to 4% of the value of the
          transaction, as described here.
        </p>

        <p>
          You may choose to switch from the free Subscription Plan to any paid Subscription Plan at any time. Once you
          choose to do so, we start to apply the respective monthly subscription fees under the applicable paid
          Subscription Plan and we do not charge protection fees. You may receive Cashback in the amount of 4% in Revu
          Tokens.
        </p>

        <p>
          Anyone who subscribes to any paid Subscription Plan or anyone who permits or causes another person to make an
          order on their behalf will be liable for the payment arising from such a subscription. All payments for the
          paid Subscription Plan are due in advance by the first day of the billing period.
        </p>

        <p>
          Fees of the Subscription Plans are subject to change at any time. Any fee may change at any time and will
          become binding on the User upon the following conditions:
        </p>

        <p>(1) REVUTO O&Uuml; has sent a 7-days-period-notice;</p>

        <p>
          (2) The User did not unsubscribe from the paid Subscription Plan within such a period or by the end of the
          then-current subscription term, whichever date is later.
        </p>

        <p>
          Such notice may be sent to a User by email to your most recently provided email address or posted on the
          Website or by any other manner chosen by REVUTO O&Uuml; in its commercially reasonable discretion. You will be
          deemed to have received any such notice that is posted on the Website on the day following the date it was
          posted.
        </p>

        <h4 id="vdc">10. REVUTO VIRTUAL DEBIT CARD (REVUTO VDC)</h4>

        <p>
          Upon entering details on a subscription to a service offered by a third party, and conditioned on the
          successful KYC, each User receives Revuto VDC for each subscription (including free-trial subscriptions). The
          User should use Revuto VDCs for all subscriptions to services offered by third-party providers which are
          supposed to be managed via Revuto App. Using other debit or credit cards prevents the User from benefits
          offered via Revuto App.
        </p>

        <p>
          Revuto VDC is in the ownership of the Card Issuer. Once you file a request for issuance of Revuto VDC, you
          agree to{' '}
          <ExternalLink href="https://www.manigo.com/wp-content/uploads/2020/10/Manigo-Terms-of-Service-Cardholder-Terms-and-Conditions.pdf">
            Manigo Terms of Service and Cardholder Terms and Conditions
          </ExternalLink>{' '}
          (excluding terms that govern the issuance of physical debit cards) and you contract with Manigo and the Card
          Issuer. Revuto is not a party to such contracts and acts only as an agent to Manigo and Card Issuer.
        </p>

        <p>
          Nevertheless, REVUTO O&Uuml; shall be your first point of contact in relation to{' '}
          <ExternalLink href="https://www.manigo.com/wp-content/uploads/2020/10/Manigo-Terms-of-Service-Cardholder-Terms-and-Conditions.pdf">
            Manigo Terms of Service and Cardholder Terms and Conditions
          </ExternalLink>
          . You should contact us if you, for example:
        </p>
        <ul>
          <li>wish to report an unauthorized transaction via your Revuto VDC</li>
          <li>you wish to cancel your VDC you wish to</li>
          <li>complain about Manigo service or Card Issuer service.</li>
        </ul>
        <p>
          The Revuto VDC is a prepaid card, not a credit card, issued for each subscription to a third-party service
          managed via Revuto App. The Revuto VDC is intended for use as a means of payment, and funds loaded onto the
          Revuto VDC do not constitute a deposit. The Users are not entitled to earn interest on the balance of the
          Revuto VDC.
        </p>

        <p>
          The User understands that REVUTO O&Uuml;, Manigo, Card Holder and the User must at all times comply with the
          AML/CTF. The User understands that Revuto VDC may not be issued before the User goes through KYC. The User
          acknowledges and agrees that REVUTO O&Uuml; may require additional data or identity checks at the point of
          sale or application for a Revuto VDC by the User.
        </p>

        <p>
          In the event that such additional requirements are imposed or recommended by any competent regulatory body or
          association, the User understands and accepts that REVUTO O&Uuml; would then undertake to make appropriate
          changes to the Revuto Services and/or its operating procedures as soon as reasonably practicable and in any
          case within 30 days of its receipt of notification by such regulatory body, or association, whichever is the
          sooner.
        </p>

        <h4 id="purchases">11. MOBILE IN-APP PURCHASES</h4>

        <p>
          Revuto is free to download and use the app on app stores. Nevertheless, we offer additional features within
          our paid Subscription Plans. You may subscribe to a chosen paid Subscription Plan via the in-app purchase on
          App Store or Google Play Store. Payments will be processed through the Google Play or App Store from which you
          originally downloaded the application. You may access the applicable &ldquo;in-app&rdquo; purchase rules and
          policies directly from the app stores. If you make any &ldquo;in-app&rdquo; purchase, then the app store
          provider&rsquo;s terms and conditions/EULA will also apply.
        </p>

        <p>All billing inquiries must be directed to the app store from which you downloaded the application.</p>

        <p>
          If you subscribed to paid Subscription Plan via the app store provider, you can cancel the renewal of the
          subscription with the app store provider. You will not receive a refund for the fees you already paid for the
          current subscription period and you will continue to receive the Revuto Services until the expiry of the
          billing period.
        </p>

        <p>
          All currency conversion charges, third party fees, or other tax, duty or levy of any kind, including interest
          and penalties thereon, whether imposed during the term of the Agreement or after the term of the Agreement
          incurred by User in relation to Revuto Services shall be the sole responsibility of the User.
        </p>

        <h4 id="referral">12. REVUTO REFERRAL PROGRAM AND REVU TOKENS</h4>

        <h5>12.1. REVUTO REFERRAL PROGRAM</h5>

        <p>
          By participating in the Revuto Referral Program the User accepts the Agreement, as well as further conditions
          explained on the Platform.
        </p>

        <p>
          To receive credits in Revu Tokens under the Revuto Referral Program, in addition to such conditions, eligible
          User must:
        </p>

        <ul>
          <li>Go through the KYC process successfully</li>
          <li>
            Each prospect User who signs up via their unique referral link must go through the KYC process successfully
          </li>
          <li>Accept the Terms of Revuto Crypto Wallet</li>
          <li>Download the Revuto Crypto Wallet within the Revuto App</li>
          <li>Comply with the User and Territorial Restrictions (as explained in Section 2).</li>
        </ul>

        <h5>12.2. REVU TOKENS</h5>

        <p>
          Revu Token is a native Cardano token minted on the Cardano network (blockchain). With Revu Tokens, Users may
          earn benefits correlated to cryptocurrencies such as to easily transfer and/or sell digital assets to others,
          and pay less for online goods and services (especially to pay less for online subscriptions).
        </p>

        <p>
          However, the User is fully and solely responsible for evaluating their decisions regarding crypto currencies
          and Revuto Services. Each User understands that the{' '}
          <strong>
            VALUES OF CRYPTOCURRENCIES (as defined below) MAY FLUCTUATE SIGNIFICANTLY DEPENDING ON MARKET CONDITIONS AND
            OTHER RISKS AND THE USER UNDERSTANDS AND ACCEPTS A SUBSTANTIAL RISK OF ECONOMIC LOSS ASSOCIATED WITH
            HOLDING, PURCHASING, SELLING OR INVESTING IN CRYPTOCURRENCIES. BY ACCEPTING THESE TERMS THE USER
            ACKNOWLEDGES AND AGREES THAT:
          </strong>
        </p>

        <p>
          <strong>
            (1) THESE RISKS ARE ASSOCIATED TO THE USE OF SERVICES OFFERED BY REVUTO O&Uuml; AND ITS AFFILIATES AND THE
            USER ACCEPTS SUCH RISK;
          </strong>
        </p>

        <p>
          <strong>
            (2) REVUTO O&Uuml; OR ITS AFFILIATES SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.
          </strong>
        </p>

        <p>
          The Users may receive Revu Tokens only via Revuto Crypto Wallet. Revuto Crypto Wallet is a cryptocurrency
          wallet supporting all Cardano-based tokens, while by default including REVU and the EURr stablecoin. The User
          may download the Revuto Crypto Wallet within the Revuto App under the condition that the User accepts{' '}
          <a href="/wallet-terms">Terms for Revuto Crypto Wallet</a> and completes the KYC successfully.
        </p>

        <h4 id="warranties">13. DISCLAIMER OF WARRANTIES</h4>

        <p>
          Your use of Revuto is at your sole risk. The service is provided on an &quot;as is&quot; and &quot;as
          available&quot; basis.
        </p>

        <p>
          Any warranty of REVUTO O&Uuml; regarding the Platform (or part thereof) not expressly stated herein shall be
          deemed withheld. REVUTO O&Uuml; disclaims, to the fullest extent permitted under the applicable law, all
          statutory warranties and course of performance, course of dealing and usage related to Users&apos;
          expectations.
        </p>

        <p>
          User is solely responsible for any damage User may suffer resulting from the use of the Platform and any
          Revuto Service. No oral or written information or advice is given by REVUTO O&Uuml; or its authorized
          representatives shall create a warranty or in any way increase the scope of REVUTO O&Uuml;&apos;s obligations.
        </p>

        <p>Without prejudice to the generality of the previous provisions, REVUTO O&Uuml; does not warrant that:</p>

        <ul>
          <li>
            any Revuto Service will meet the User&apos;s specific requirements nor that it will be &quot;fit for
            purpose&quot;,
          </li>
          <li>
            the Platform and Revuto Service will be uninterrupted, timely, secure, error-free or of satisfactory
            quality,
          </li>
          <li>the results that may be obtained from the use of the Service will be accurate or reliable,</li>
          <li>any errors in the Revuto Service will be corrected.</li>
        </ul>

        <p>
          REVUTO O&Uuml; and/or its suppliers make no representations about the suitability, reliability, availability,
          continuity, timeliness, and accuracy of the Revuto Service and Platform.
        </p>

        <h4 id="liability">14. LIMITATION AND EXCLUSION OF LIABILITY</h4>

        <p>
          To the maximum extent permitted by the applicable law, REVUTO O&Uuml; and/or its suppliers, employees and
          representatives shall be liable in no event for:
        </p>
        <ul>
          <li>
            (1) any loss, damage, expense or other harmful consequences resulting from anyone&apos;s use or inability to
            use Revuto;
          </li>

          <li>
            (2) any installation, implementation, customization or modification of the Software not carried out by
            REVUTO O&Uuml;;
          </li>

          <li>
            (3) any failure to apply available update, service pack, fix or upgrade that would have avoided the harmful
            event;
          </li>

          <li>(4) any unauthorized access to the User Content;</li>

          <li>(5) any unauthorized use of any User&apos;s credentials.</li>
        </ul>
        <p>
          To the maximum extent permitted by applicable law, in no event shall REVUTO O&Uuml; and/or its suppliers,
          employees and representatives be liable for: any indirect, punitive, incidental, special, consequential
          damages or any damages whatsoever (including, without limitation, damages for loss of use, data or profits, or
          business interruption) arising out of or in any way connected:
        </p>

        <ul>
          <li>with the use or performance of Revuto,</li>
          <li>
            with the delay or inability to use Revuto and any Revuto Service, including the provision of or failure to
            provide Revuto Services
          </li>
          <li>User&rsquo;s or third-party fraud</li>
          <li>any transaction which results in a negative balance on a User&rsquo;s account.</li>
        </ul>

        <p>
          If any of the foregoing limitations are deemed unenforceable or in the event any liability of REVUTO O&Uuml;
          is established, to the greatest extent permitted by law, You agree that the entire aggregate liability of
          REVUTO O&Uuml; and sole remedy available to any User in any case in any way arising out of or relating to the
          Agreement, Platform or the Revuto Service shall be limited to monetary damages that in the aggregate may not
          exceed the sum of any amount paid (if any) by that User during the twelve months prior to notice to REVUTO
          O&Uuml; of the dispute for which the remedy is sought. If the User had no obligation to make such payment
          during such a period, monetary damages that in the aggregate may not exceed the sum of 100 EUR (hundred euro).
        </p>

        <p>
          You agree that this limitation of liability represents a reasonable allocation of risk and is a fundamental
          element of the basis of the bargain between you and REVUTO O&Uuml;. You understand that the Platform and
          Revuto Services would not be provided without such limitations.
        </p>

        <p>
          Some countries do not allow the limitation of certain damages, so some or all of this limitation of liability
          may not apply to you and you may have additional rights. Nevertheless, if any portion of these Sections is
          held to be invalid under the applicable law, the invalidity of such portions shall not affect the validity of
          the remaining portions of the applicable Sections.
        </p>

        <p>Section 13 does not exclude mandatory liability for:</p>

        <ul>
          <li>(a) Wilful breach by REVUTO O&Uuml; of any of its obligations;</li>
          <li>(b) Death or personal injury caused by a defective item produced by REVUTO O&Uuml;.</li>
        </ul>

        <p>
          Neither Party shall be liable for breaching its obligations due to a circumstance they reasonably could not
          have foreseen and which is beyond their control, such as, e.g., a force of nature, an act of a legislative or
          executive authority, war, civil unrest, the act of terror, strike, non-trivial cyber attack, failure of a
          third-party hosting, Internet failure or any other circumstance qualifying as force majeure under the
          applicable law &mdash; to the extent that the respective circumstance prevented or hindered the Party&apos;s
          performance. For the avoidance of doubt, the provisions of this section:
        </p>

        <ul>
          <li>
            are not intended to derogate from, or limit the application of, any statutory limitation or exclusion of
            liability;
          </li>
          <li>
            shall not be construed to limit the amount of, or excuse User from paying, any fee or other consideration
            owed hereunder.
          </li>
        </ul>

        <h4 id="idemnification">15. INDEMNIFICATION</h4>

        <p>
          You agree to indemnify and hold REVUTO O&Uuml; harmless from any and all demands, losses, liability, claims or
          expenses (including attorneys&apos; fees) made against REVUTO O&Uuml; by any third party due to or arising out
          of or in connection with your use of Revuto and the Services including but not limited to:
        </p>

        <ul>
          <li>
            your use of the Platform or part thereof in violation of this Agreement, in violation of an agreement with
            the third party and/or any applicable law;
          </li>
          <li>
            any third-party claim of infringement of copyright or other Intellectual Property Rights or invasion of
            privacy arising from hosting User Content, and/or your making available thereof to other Users, and/or the
            actual use of your User Content by other Users or related services under the Agreement;
          </li>
          <li>
            any activity related to a User account, be it by you or by any other person accessing your account with or
            without your consent unless such activity was caused by the act or default of REVUTO O&Uuml;.
          </li>
        </ul>

        <h4 id="termination">16. TERMINATION</h4>

        <p>This Agreement shall continue until either:</p>

        <ul>
          <li>you cancel your subscription and/or request for your User account to be deactivated and deleted;</li>
          <li>terminated by REVUTO O&Uuml;.</li>
        </ul>

        <h5>15.1. TERMINATION BY USER</h5>

        <p>The User of Revuto may terminate this Agreement by closing the User account.</p>

        <p>
          You may cancel your subscription to any paid Subscription Plan at any time. If you cancel after your
          subscription renewal date, you will not receive a refund for any amounts that have been charged. Your
          cancellation will be effective at the end of your then-current subscription period.
        </p>

        <p>
          You understand that cessation of use of the Revuto App without cancellation of the paid Subscription Plan will
          not entitle you to any refund. If you do not use the Revuto App paid Subscription Plan, you need to cancel the
          subscription and switch to a free Subscription Plan or close the User account.
        </p>

        <h5>15.2. TERMINATION BY REVUTO O&Uuml;</h5>

        <p>
          You agree that REVUTO O&Uuml; may immediately terminate this Agreement if you do not pay the fees when due.
        </p>

        <p>
          REVUTO O&Uuml; may deny you access to all or any part of the Services or terminate your account with or
          without prior notice if you engage in any conduct or activities that REVUTO O&Uuml; determines, at its sole
          discretion, violate this Agreement or the rights of REVUTO O&Uuml; or any third party, or is otherwise
          inappropriate. Without limitation, REVUTO O&Uuml; may deny you access to the Services or Platform, or
          terminate this Agreement and your User account.
        </p>

        <p>
          REVUTO O&Uuml; reserves the right to investigate suspected violations of the Agreement, including without
          limitation any violation arising from any submission, posting or e-mails you make or send to any forum on our
          Platform. REVUTO O&Uuml; may seek to gather information from the User who is suspected of violating these
          Terms of Use, and from any other User. REVUTO O&Uuml; may suspend any User whose conduct or postings are under
          investigation and may remove such material from its servers as it deems appropriate and without notice. If
          REVUTO O&Uuml;, in its sole discretion, believes that a violation of the Agreement has occurred, it may edit
          or modify any submission, posting or e-mails, remove the material permanently, cancel postings, warn users,
          suspend Users and passwords, terminate User accounts or take other corrective action it deems appropriate.
          REVUTO O&Uuml; will fully cooperate with any law enforcement authorities or court order requesting or
          directing REVUTO O&Uuml; to disclose the identity and other available information relating to anyone posting
          any e-mails or publishing or otherwise making available any materials that are believed to violate these Terms
          of Use.
        </p>

        <p>
          REVUTO O&Uuml; may, at its sole discretion, at any time and for any reason, terminate the Revuto Service,
          terminate this Agreement, or suspend or terminate any User account on Revuto. REVUTO O&Uuml; will send notice
          to User at the email address User provides when creating a User account, or such other email address User may
          later provide to REVUTO O&Uuml;.
        </p>

        <p>
          REVUTO O&Uuml; may make changes, replace, or discontinue (temporarily or permanently) Revuto Services at any
          time for any reason with or without notice. In this case, you may be prevented from accessing or using Revuto
          Services. If, in our sole discretion, we decide to permanently discontinue Revuto Services, we will provide
          you with notice via our Website or via your email or notification in the Revuto App. You are solely
          responsible for storing outside of the Services a backup of any Wallet address and private key pair that you
          maintain in your Wallet. REVUTO O&Uuml; or its Affiliate shall not be held responsible or liable for any loss
          in the event that we discontinue or depreciate the Services.
        </p>

        <h5>15.3 TERMINATION SURVIVAL</h5>

        <p>The following provisions will survive termination of this Agreement:</p>

        <ul>
          <li>Any obligation of the User to pay for the Services Section 6 (Intellectual Property)</li>
          <li>
            Section 12 (Disclaimer of Warranties), Section 13 (Limitation and Exclusion of Liability) and Section 14
            (Indemnification)
          </li>
          <li>Section 15.3 (Termination survival)</li>
          <li>Section 20 (Jurisdiction and Choice of Law; Dispute Resolution)</li>
          <li>Section 21 (No Class Action)</li>
          <li>Any other provision of this Agreement that must survive to fulfil its essential purpose.</li>
        </ul>

        <h4 id="severability">17. SEVERABILITY</h4>

        <p>
          If any provision of this Agreement is found invalid by any court having competent jurisdiction, the invalidity
          of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall
          remain in full force and effect.
        </p>

        <p>
          If any provision of this Agreement violates any mandatory rule of the applicable law and proves to be void as
          a result thereof, such provision shall, for those specific circumstances and only in that particular respect
          in which it is void, be deemed to have been amended to comply with the law. Any such amendment shall be
          confined to the minimum necessary to make the provision valid and shall retain as much of its original ambit
          and meaning as possible.
        </p>

        <h4 id="notices">18. NOTICES</h4>

        <p>
          All notices to REVUTO O&Uuml; shall be provided to Revuto O&Uuml;, Keemia tn 4, 10616 Tallinn, Estonia or to
          the address specified in Section 1 (Definitions) or to the email support@revuto.com.
        </p>

        <p>
          REVUTO O&Uuml; may give notice to a User via the email address associated with the User account, via a
          notification within the Revuto App or by mail or courier to the address provided for that User.
        </p>

        <p>Notice shall be deemed to have been received:</p>

        <ul>
          <li>the next day if given via notice on the User account or via email</li>
          <li>
            five workdays after posting the notice via courier or registered post. By workday, we mean workdays at
            REVUTO O&Uuml;&apos;s registered seat.
          </li>
        </ul>

        <h4 id="waiver">19. NO WAIVER</h4>

        <p>
          Our failure to exercise or enforce any right or provision of the Agreement shall not constitute a waiver of
          such right or provision.
        </p>

        <h4 id="third-party">20. LINKS TO THIRD-PARTY CONTENT AND MATERIALS</h4>

        <p>
          Should REVUTO O&Uuml; enable access to data or service provider from another service provider through linking,
          REVUTO O&Uuml; does not carry responsibility for such information.
        </p>

        <p>
          The Platform may contain links to other websites, owned by other legal or natural persons. Each of these
          services or websites has its Terms of Use and <a href="/privacy-policy">Privacy policy</a> on handling
          personal data, which may differ significantly from those which are applied to the use of the Platform. REVUTO
          O&Uuml; has no control over such services or websites and shall not carry any responsibility neither for the
          availability of those services, websites nor for the Terms of Use and{' '}
          <a href="/privacy-policy">Privacy policy</a> that applies to their visitors and users.
        </p>

        <p>
          Placing links to third-party websites on the Platform does not in any way imply that REVUTO O&Uuml; recommends
          or approves services or products offered through such websites.
        </p>

        <p>
          User acknowledges and agrees that User acknowledges and agrees that REVUTO O&Uuml; shall not be responsible
          for any aspect of the information, content, or services contained in any third-party materials or on any third
          party sites accessible or linked to the Platform or REVUTO O&Uuml;. We make no representations concerning any
          third party content contained in or accessed through our Services. Any other terms, conditions, warranties, or
          representations associated with such content, are solely between you and such organizations and/or
          individuals.
        </p>

        <h4 id="jurisdiction">21. JURISDICTION AND CHOICE OF LAW; DISPUTE RESOLUTION</h4>

        <p>
          This Agreement and any matter or dispute arising out of or related to the subject matter of the Agreement
          shall be governed, construed, and enforced in accordance with the laws of Estonia, without regard to its
          conflict of law provisions. Specifically excluded from application to this Agreement is that law known as the
          United Nations Convention on the International Sale of Goods.
        </p>

        <p>
          Mindful of the high cost of dispute, you and REVUTO O&Uuml; agree to the following dispute resolution
          procedure: in the event of any controversy, claim, action or dispute arising out of or related to: (i) the
          Website; (ii) this Agreement; (iii) the Services; (iv) the breach, enforcement, interpretation, or validity of
          this Agreement; or (v) any other dispute between you and REVUTO O&Uuml; ( &quot;Dispute&quot;), the Party
          asserting the Dispute shall first try in good faith to settle such Dispute by providing written notice to the
          other Party (by email, first-class or registered mail) describing the facts and circumstances (including any
          relevant documentation) of the Dispute and allowing the receiving Party 30 days in which to respond to or
          settle the Dispute (&quot;Mandatory negotiations&quot;).
        </p>

        <p>Notice shall be sent:</p>

        <ul>
          <li>(1) if to REVUTO O&Uuml; at: Revuto O&Uuml;, Keemia tn 4, 10616 Tallinn, Estonia</li>
          <li>
            (2) if to you at: your last-used address in your account information or to your residence address (at our
            full discretion)
          </li>
        </ul>

        <p>
          If no such address exists, or if the delivery to such address is unsuccessful, the notice shall be sent to the
          email address connected to that User&apos;s Account.
        </p>

        <p>
          Both you and REVUTO O&Uuml; agree that this dispute resolution procedure is a condition precedent that must be
          satisfied before initiating any dispute resolution or filing any claim against the other Party.
        </p>

        <p>
          Failing to resolve the dispute as described in previous paragraphs of this Section, any controversy or claims
          arising out of or relating to the Agreement, or the breach thereof, shall be in the exclusive jurisdiction of
          the competent court in the city of Tallinn, Estonia.
        </p>

        <p>This clause shall survive termination of the Agreement.</p>

        <p>
          Notwithstanding the provisions above, REVUTO O&Uuml; may, at its absolute discretion, assert and seek the
          protection of its Intellectual Property Rights and rights concerning confidential information or data
          processing anywhere in the world.
        </p>

        <h4 id="action">22. NO CLASS ACTION</h4>

        <p>
          Except where prohibited by law, as a condition of using the Software and/or Service, you agree that any and
          all disputes, claims and causes of action arising out of or connected with the Software and/or Service, shall
          be resolved individually, without resort to any form of class action.
        </p>

        <p>
          Any dispute resolution under these Terms of Use will take place on an individual basis;
          class/representative/collective actions are not permitted.
        </p>

        <p>
          THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN EACH INDIVIDUAL CAPACITY, AND
          NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH
          AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and REVUTO
          O&Uuml; agree otherwise, the court may not consolidate more than one person&apos;s claims, and may not
          otherwise preside over any form of a representative or class proceeding.
        </p>

        <h4 id="agreement">23. ENTIRE AGREEMENT</h4>

        <p>
          This Agreement (as amended from time to time) including any linked documents or documents that are expressly
          or implicitly included in the Agreement constitutes the entire Agreement between the Parties relating to the
          subject matter hereof and supersedes all prior Agreements and understandings between the Parties with respect
          to that subject matter.
        </p>

        <p>
          In case of conflict between any provision herein and any statement, representation or other information
          published on the Website or contained in any other materials or communications the provision in the Agreement
          shall prevail.
        </p>
      </div>
    </>
  );
};
