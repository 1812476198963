import React from 'react';
import { ExternalLink } from '../ExternalLink';

interface IPartnerProps {
  name: string;
  link: string;
  image?: React.ReactElement;
  imageClassName?: string;
}

export const Partner: React.FC<IPartnerProps> = ({ link, imageClassName, name, image }) => {
  return (
    <ExternalLink href={link}>
      <div className="partner column">
        <div className="row flex middle-xs">
          <div className={imageClassName}>{image}</div>
        </div>
        <div className="name">
          <hr />
          <p className="uppercase light bold small">{name}</p>
        </div>
      </div>
    </ExternalLink>
  );
};
