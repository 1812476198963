import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISubscribeFormState {
  isSubModalOpen: boolean;
}

const initialState: ISubscribeFormState = {
  isSubModalOpen: false,
};

export const subscribeSlice = createSlice({
  name: 'subscribeSlice',
  initialState,
  reducers: {
    setSubModalVisible: (state, action: PayloadAction<boolean>): void => {
      state.isSubModalOpen = action.payload;
    },
  },
});
