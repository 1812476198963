import { createAction } from '@reduxjs/toolkit';
import { NFTClaimResponseMessage } from '../../components/NFTClaimModal';
import { ClaimNFTPostBody } from '../../types/dto/NFT';
import { nftSlice } from './slice';

export const { storeAllNFTs, storeUnclaimedNFTs, storeRandomSpinnerNFTs, storeBuyerNFTClaim } = nftSlice.actions;

export const fetchNFTs = createAction('nft/fetchNFTs');

export const fetchUnclaimedNFTs = createAction('nft/fetchUnclaimendNFTs');

export const fetchBuyerNFTClaim = createAction('nft/fetchBuyerNFTClaim');

type requestNFTClaimType = (
  data: ClaimNFTPostBody,
  onComplete: (message: NFTClaimResponseMessage) => void,
) => { payload: ClaimNFTPostBody; meta: (message: NFTClaimResponseMessage) => void };

export const requestNFTClaim = createAction<requestNFTClaimType>(
  'nft/nftClaimRequest',
  (data: ClaimNFTPostBody, onComplete: (message: NFTClaimResponseMessage) => void) => ({
    payload: data,
    meta: onComplete,
  }),
);

//spinner actions
export const getRandomNFTs = createAction('nft/getRandomNFTs');
export const getRandomNFTsForSequentSpin = createAction('nft/sequentSpinFetch');
