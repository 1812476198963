import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StatsState {
  dashboard: boolean;
  profile: boolean;
  deposits: boolean;
}

const initialState: StatsState = {
  dashboard: true,
  profile: true,
  deposits: true,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setDashboardLoader: (state, action: PayloadAction<boolean>): void => {
      state.dashboard = action.payload;
    },
    setProfileLoader: (state, action: PayloadAction<boolean>): void => {
      state.profile = action.payload;
    },
    setDepositsLoader: (state, action: PayloadAction<boolean>): void => {
      state.deposits = action.payload;
    },
  },
});
