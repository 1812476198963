import React from 'react';

export const AboutContainer: React.FC = () => {
  return (
    <div id="about" className="about-container row start-xs section-spacing">
      <div className="col-xs-12 col-sm-6">
        <p className="bold large">
          With common practices of deceptive small-print, free-trial user attraction traps, hidden and overly
          complicated unsubscribe procedures, the subscription economy keeps growing at 100% YoY. Revuto&apos;s mission
          is to enable our customers to save money by allowing them to take control over what, when and how they pay for
          their subscriptions.
        </p>
        <p className="light">
          By leveraging Cardano native tokens and DeFi to pay for the subscriptions, Revuto&apos;s ecosystem is designed
          to save people&apos;s money regardless of the crypto market conditions.
        </p>
      </div>

      <div className="col-xs-12 col-sm-5 col-sm-offset-1">
        <div className="about-image" />
      </div>
    </div>
  );
};
