import React from 'react';

import CommunityImage from '../../assets/images/community.png';

export const CommunityContainer: React.FC = () => {
  return (
    <div className="community-container">
      <h2 className="uppercase">building the community</h2>
      <div className="row middle-md">
        <div className="col-xs-12 col-md-5 col-lg-5">
          <p className="light">
            By providing the best service to control and pay subscriptions, Revuto&apos;s mission is to build a strong
            community of both crypto users and users who didn’t necessarily have exposure to crypto in the past. Only
            together, supporting each other, Revuto users can save more money.
          </p>
        </div>
        <div className="col-xs-12 col-md-6 col-md-offset-1 col-lg-offset-1 col-lg-6 center-xs middle-xs">
          <img src={CommunityImage} alt="Revuto Crypto Community" />
        </div>
      </div>
    </div>
  );
};
