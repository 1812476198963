import React from 'react';

export const TokenContainer: React.FC = () => {
  return (
    <div id="token" className="token-container section-spacing">
      <div className="row start-xs">
        <div className="col-xs-12 col-sm-6">
          <h2>REVU token</h2>
          <p className="light">
            REVU is a native token on the Cardano network. The main incentive for holding REVU tokens is to save money
            while paying for your subscription charges.
          </p>

          <p>
            Revuto&apos;s crypto wallet is a simple and secure wallet supporting Cardano-based native tokens, including
            REVU tokens and EURR stablecoin by default.
          </p>

          <p>
            Revuto&apos;s crypto wallet is built to allow preauthorized pull payments from other Revuto wallet addresses
            and to support direct authorization with the service providers.
          </p>
        </div>

        <div className="col-xs-12 col-sm-6 center-xs">
          <div className="token-image" />
        </div>
      </div>

      <div className="row points">
        <div className="point col-xs-12 col-sm-5 col-md-3">
          <div className="ellipse" />
          <p className="small light">REVU is a native token on the Cardano network.</p>
        </div>

        <div className="point col-xs-12 col-sm-5 col-md-3">
          <div className="ellipse" />
          <p className="small light">
            By offering Cashback for subscription payments and rewards through Revuto Referral Program, REVU tokenomics
            is set up to create constant buying pressure on REVU.
          </p>
        </div>

        <div className="point col-xs-12 col-sm-8 col-md-3">
          <div className="ellipse" />
          <p className="small light">
            Once mature, Revuto will gradually transition to community governance, allowing REVU token holders to vote
            for the future of the Revuto.
          </p>
        </div>
      </div>
    </div>
  );
};
