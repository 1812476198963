import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ExternalLink } from '../ExternalLink';

interface IMemberProps {
  name: string;
  position: string;
  description: string;
  image: string;
  link: string;
}

export const Member: React.FC<IMemberProps> = ({ link, name, position, description, image }) => {
  return (
    <>
      <ReactTooltip className="custom-tooltip" backgroundColor="#FFFFFF" textColor="#87A5BB" place="bottom" />
      <ExternalLink href={link}>
        <div className="member column middle-xs" data-tip={description}>
          <img src={image} alt={name} />
          <p className="uppercase bold small">{name}</p>
          <p className="position uppercase light bold small">{position}</p>
        </div>
      </ExternalLink>
    </>
  );
};
