import React, { FC } from 'react';
import { Header } from '../../components/Header';
import whatIsRfund from '../../assets/images/what-is-rfund.webp';
import rfundSize from '../../assets/images/rfund-size.png';
import offerIcon1 from '../../assets/images/offer-1.webp';
import offerIcon2 from '../../assets/images/offer-2.webp';
import offerIcon3 from '../../assets/images/offer-3.webp';
import offerIcon4 from '../../assets/images/offer-4.webp';
import offerIcon5 from '../../assets/images/offer-5.webp';
import apply1 from '../../assets/images/apply1.png';
import apply1a from '../../assets/images/apply1.avif';
import apply2 from '../../assets/images/apply2.png';
import apply2a from '../../assets/images/apply2.avif';
import apply3 from '../../assets/images/apply3.png';
import apply3a from '../../assets/images/apply3.avif';
import { Footer } from '../../components/Footer';
import { PopupButton } from '@typeform/embed-react';

export const RfundPage: FC = () => {
  return (
    <>
      <div className="rfund-page">
        <Header />
        <div className="rfund-hero">
          <div className="hero-text">
            <h1>R FUND</h1>
            <p>Revuto is launching an investment fund for pre-ICO projects on Cardano</p>
            <PopupButton id="RH4CDDuc" className="hero-button">
              Apply now
            </PopupButton>
          </div>
          <div className="hero-bottom">
            <h1>Let&apos;s build cardano together</h1>
          </div>
        </div>
      </div>
      <main className="main">
        <div className="page-wrapper">
          <div id="about" className="wrapper about">
            <h2>What is the R Fund?</h2>
            <p>
              R Fund is a crypto-fund dedicated to supporting projects and companies that are building on the Cardano
              blockchain.
            </p>
            <div className="about-image">
              <img src={whatIsRfund} alt="What is R fund?" width="450" height="300" />
            </div>
          </div>
        </div>
        <div className="wrapper rfund-size">
          <div className="size-text">
            <h3>What is the size of R fund?</h3>
            <p>
              Revuto and its investors are committing at least 2 million USDT into the R Fund to support the best
              creators on Cardano blockchain.
            </p>
          </div>
          <div>
            <img src={rfundSize} alt="Size of R fund?" width="780" height="260" />
          </div>
        </div>
        <hr className="separator" />
        <div id="offering" className="wrapper offering section-spacing">
          <h2 className="offering-title">Flexible and crypto friendly R Fund is offering:</h2>
          <div className="offering-grid">
            <div className="offering-box">
              <div className="offering-icon">
                <img src={offerIcon1} width="40" height="40" alt="" />
              </div>
              <p>Funds for the project development</p>
            </div>
            <div className="offering-box">
              <div className="offering-icon">
                <img src={offerIcon2} width="40" height="40" alt="" />
              </div>
              <p>Support and joint marketing efforts for the ICO preps</p>
            </div>
            <div className="offering-box">
              <div className="offering-icon">
                <img src={offerIcon3} width="40" height="38" alt="" />
              </div>
              <p>Launching ICO on proven Token Sale Platform built on Cardano</p>
            </div>
            <div className="offering-box">
              <div className="offering-icon">
                <img src={offerIcon4} width="40" height="40" alt="" />
              </div>
              <p>Contacts to investors, media and exchanges for token listing</p>
            </div>
            <div className="offering-box">
              <div className="offering-icon">
                <img src={offerIcon5} width="40" height="40" alt="" />
              </div>
              <p>Permanent RFP (Request for Proposal)</p>
            </div>
          </div>
        </div>
        <hr className="separator" />
        <div className="wrapper offering2 section-spacing">
          <h2 className="offering-title offering2-title">
            Investing in projects and companies that are building on top of Revuto:
          </h2>
          <div className="offering2-grid">
            <div className="offering2-box">
              <div className="offering2-icon"></div>
              <p>Investing in picks and shovels/Infrastructure</p>
            </div>
            <div className="offering2-box">
              <div className="offering2-icon"></div>
              <p>Subscription related products and services</p>
            </div>
            <div className="offering2-box">
              <div className="offering2-icon"></div>
              <p>Superior CX/UX</p>
            </div>
          </div>
        </div>
        <div className="page-wrapper section-spacing">
          <div id="apply" className="apply">
            <h2 className="offering-title">What do you need in order to apply?</h2>
            <div className="apply-grid">
              <div className="apply-grid-box">
                <span className="apply-grid-box-step">01</span>
                <picture>
                  <source srcSet={apply1a} type="image/avif" />
                  <img src={apply1} loading="lazy" width="215" height="197" />
                </picture>
                <h5>Describe project and your vision</h5>
              </div>
              <div className="apply-grid-box">
                <span className="apply-grid-box-step">02</span>
                <picture>
                  <source srcSet={apply2a} type="image/avif" />
                  <img src={apply2} loading="lazy" width="267" height="183" />
                </picture>
                <h5>Submit your pitch deck</h5>
              </div>
              <div className="apply-grid-box">
                <span className="apply-grid-box-step">03</span>
                <picture>
                  <source srcSet={apply3a} type="image/avif" />
                  <img src={apply3} loading="lazy" width="200" height="192" />
                </picture>
                <h5>Introduce your team</h5>
              </div>
            </div>
            <div className="centerFlex">
              <PopupButton id="RH4CDDuc" className="apply-link">
                Apply now
              </PopupButton>
              <script src="//embed.typeform.com/next/embed.js"></script>
            </div>
          </div>
        </div>
        <div className="page-wrapper">
          <div id="faq" className="faq">
            <h2>FAQ</h2>
            <div className="faq-box">
              <details>
                <summary>
                  <strong>Do we have to build on Cardano?</strong> <span className="collapse-button"></span>
                </summary>
                <p>Yes, the R Fund is committed to supporting and building the Cardano ecosystem.</p>
              </details>
            </div>
            <div className="faq-box">
              <details>
                <summary>
                  <strong>Do we have to have a company?</strong> <span className="collapse-button"></span>
                </summary>
                <p>
                  We don’t care about the formal governance and structure at the time of application but in order to
                  accept the investment, we would require a legal entity.
                </p>
              </details>
            </div>
            <div className="faq-box">
              <details>
                <summary>
                  <strong>Does it matter where the company is registered and where we’re located?</strong>{' '}
                  <span className="collapse-button"></span>
                </summary>
                <p>No, we are remote-first and don’t discriminate against teams based on their location.</p>
              </details>
            </div>
            <div className="faq-box">
              <details>
                <summary>
                  <strong>Does it matter if the team members are not working full-time on the project?</strong>{' '}
                  <span className="collapse-button"></span>
                </summary>
                <p>
                  Although we prefer to see a fully dedicated team, we don’t care if you do this as a full-time job. The
                  only metric we care about is that you keep delivering.
                </p>
              </details>
            </div>
            <div className="faq-box">
              <details>
                <summary>
                  <strong>What is the average size of your investment?</strong>{' '}
                  <span className="collapse-button"></span>
                </summary>
                <p>It will be determined on a case by case basis but up to $200K in crypto.</p>
              </details>
            </div>
            <div className="faq-box">
              <details>
                <summary>
                  <strong>How will the R fund support the projects?</strong> <span className="collapse-button"></span>
                </summary>
                <p>
                  Apart from providing funds, our team will aim at supporting the project and integrating it with the
                  Revuto product family. By partnering with you, our goal is to help the Cardano ecosystem to grow.
                </p>
              </details>
            </div>
          </div>
        </div>
        <div className="scout section-spacing">
          <div className="scout-box">
            <h4>R Fund scout program</h4>
            <p>Help us find the best teams, source the best deals and win rewards upon closed transactions.</p>
          </div>
          <PopupButton id="qyWT1cAV" className="scout-button">
            Send suggestion
          </PopupButton>
        </div>
      </main>
      <Footer />
    </>
  );
};
