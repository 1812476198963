import React, { FC, useState } from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { SocialContainer } from '../../containers/Social';
import { ExternalLink } from '../../components/ExternalLink';
import { validate } from 'bitcoin-address-validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createClient } from '@supabase/supabase-js';

// tslint:disable-next-line: @typescript-eslint/no-var-requires
// const mailchimp = require('@mailchimp/mailchimp_marketing');
// import mailchimp from '@mailchimp/mailchimp_transactional';

import GooglePlay from '../../assets/images/GooglePlay.svg';
import AppStore from '../../assets/images/AppStore.svg';
import AppGallery from '../../assets/images/appgallery.png';

import o_a from '../../assets/images/btc/o-a.png';
import o_b from '../../assets/images/btc/o-b.png';
import o_c from '../../assets/images/btc/o-c.png';

import go_a from '../../assets/images/btc/go-a.png';
import go_b from '../../assets/images/btc/go-b.png';
import go_c from '../../assets/images/btc/go-c.png';

import revuelto from '../../assets/images/btc/revuelto.png';
import lambo from '../../assets/images/btc/lamborghini.webp';

const bids = {
  'o-a': 2000,
  'o-b': 1000,
  'o-c': 500,
};

type bidsKey = keyof typeof bids;

const supabase = createClient(
  'https://jldfatvphszayrxpqogw.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpsZGZhdHZwaHN6YXlyeHBxb2d3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODA4NjA0MzIsImV4cCI6MTk5NjQzNjQzMn0.IJdYDr3nHV-nO1Y4x2iVpjJisIIP73L0gGUHDZvJkmU',
);

// const mail = mailchimp('9c23c61d-3aef-4016-af0f-f7701ed6ac1f');

export const BTC: FC = () => {
  const [state, setState] = useState({
    email: '',
    email_repeat: '',
    btc_address: '',
    btc_address_repeat: '',
    ordinal_type: '',
    bid: '',
  });
  const [invalid, setInvalid] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSelectOrdinal = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = e.target.value as bidsKey;
    setInvalid(invalid.filter((item) => item !== 'bid'));
    if (bids[value]) {
      setState({ ...state, ordinal_type: value, bid: String(bids[value]) });
      return;
    }
    setState({ ...state, ordinal_type: value });
    if (state.bid.length > 0 && (Number(state.bid) < 5000 || Number(state.bid) > 200000)) {
      setInvalid([...invalid, 'bid']);
    } else {
      setInvalid(invalid.filter((item) => item !== 'bid'));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<string> => {
    e.preventDefault();

    // const message = {
    //   from_email: 'edin.veskovic@zaposlise.com',
    //   subject: 'Hello world',
    //   text: 'Welcome to Mailchimp Transactional!',
    //   to: [
    //     {
    //       email: 'edin.veskovic@zaposlise.com',
    //     },
    //   ],
    // };

    // const email = await mail.users.ping();
    // const email = await mail.messages.send({
    //   message,
    // });
    // console.log({ email });

    if (invalid.length) {
      toast.warn('Please fill the form correctly.');
      return 'invalid';
    }

    if (state.ordinal_type === '') {
      toast.warn('Please select an ordinal type.');
      return 'invalid';
    }

    if (state.email !== state.email_repeat) {
      toast.warn('Check email address. It seems that you have made a mistake.');
      return 'invalid';
    }

    if (state.btc_address !== state.btc_address_repeat) {
      toast.warn('Check BTC address. It seems that you have made a mistake.');
      return 'invalid';
    }

    try {
      const data = {
        email: state.email,
        btc_address: state.btc_address,
        ordinal_type: state.ordinal_type,
        bid: state.bid,
      };

      setState({
        email: '',
        email_repeat: '',
        btc_address: '',
        btc_address_repeat: '',
        ordinal_type: '',
        bid: '',
      });

      const response = await supabase.from('ordinals_whitelist').insert(data);

      if (response.error) {
        console.log(response.error);
        if (response.error.code === '23505') {
          toast.info('You have already sent a request');
        } else {
          toast.error('Something went wrong, please try again later');
        }
        return 'error';
      }

      if (response.statusText === 'Created') {
        toast.success('Your request has been sent successfully');
      }

      const mail = await fetch('http://api.postmarkapp.com/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Postmark-Server-Token': '9c23c61d-3aef-4016-af0f-f7701ed6ac1f',
        },
        body: JSON.stringify({
          From: 'support@revuto.com',
          To: 'edin.veskovic@duplico.com',
          Subject: 'Ordinal Whitelist Request',
          TextBody:
            'Dear Revutonian,\n\nThank you for whitelisting your email address for purchasing Revuto Ordinals and participating in the Revuto seed round for equity, $REVU, and Revuelto.\n\nPlease, use the same email address to register/log in with the Revuto app where you will get further instructions about how to purchase Your Ordinal(s) and invest in Revuto.\n\nFor all other information about the campaign, please, follow our social media channels or contact us at mailto:support@revuto.com.\n\nYour Revuto Team\nhttps://twitter.com/get_revuto\nhttps://t.me/revuto\nhttps://t.me/getrevuto\nhttps://getrevuto.medium.com/',
          HtmlBody: `<html>
            <body>
              <p>Dear Revutonian,</p>
              <p>Thank you for whitelisting your email address for purchasing Revuto Ordinals and participating in the Revuto seed round for equity, $REVU, and Revuelto.</p>
              <p>Please, use the same email address to register/log in with the Revuto app where you will get further instructions about how to purchase Your Ordinal(s) and invest in Revuto.</p>
              <p>For all other information about the campaign, please, follow our social media channels or contact us at mailto:
                <a href="mailto:support@revuto.com">support@revuto.com</a>.
              </p>
              <p>Your Revuto Team</p>
              <p>
                <a href="https://twitter.com/get_revuto">https://twitter.com/get_revuto</a><br>
                <a href="https://t.me/revuto">https://t.me/revuto</a><br>
                <a href="https://t.me/getrevuto">https://t.me/getrevuto</a><br>
                <a href="https://getrevuto.medium.com/">https://getrevuto.medium.com/</a>
              </p>
            </body>
          </html>`,
        }),
      });

      const mailResponse = await mail.json();
      console.log({ mailResponse });

      return 'ok';
    } catch (error) {
      console.log(error);
      return 'error';
    }
  };

  const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // const validateBTCAddress = (address: string): boolean => {
  //   const re = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/;
  //   return re.test(address);
  // };

  const validateBTCAddress = (address: string): boolean => {
    return validate(address);
  };

  const validateBid = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value, disabled } = e.target;
    if (disabled) {
      return;
    }
    if (value.length > 0 && (Number(value) < 5000 || Number(value) > 200000)) {
      setInvalid([...invalid, name]);
    } else {
      setInvalid(invalid.filter((item) => item !== name));
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="btc-page">
        <Header />
        <div className="btc-hero">
          <header className="btc-hero-header">
            <h1>Invest in Revuto seed round for equity, $REVU and...</h1>
          </header>

          <p className="btc-hero-subheading">
            Secure your place to participate in the first equity seed round on BTC network to help us change the
            subscription economy while saving your money.
          </p>

          <form className="btc-hero-inputs" onSubmit={handleSubmit}>
            <div className="btc-hero-inputs-grid">
              <input
                type="email"
                name="email"
                className={invalid.includes('email') ? 'invalid' : ''}
                value={state.email}
                placeholder="enter your email address"
                onChange={handleChange}
                onBlur={(e): void => {
                  if (e.target.value.length === 0 || validateEmail(e.target.value)) {
                    setInvalid(invalid.filter((item) => item !== e.target.name));
                    return;
                  }
                  setInvalid([...invalid, e.target.name]);
                }}
                required
              />
              <input
                type="email"
                name="email_repeat"
                className={invalid.includes('email_repeat') ? 'invalid' : ''}
                value={state.email_repeat}
                placeholder="repeat your email address"
                onChange={handleChange}
                onBlur={(e): void => {
                  if (e.target.value.length === 0) {
                    setInvalid(invalid.filter((item) => item !== e.target.name));
                    return;
                  }
                  if (e.target.value !== state.email || !validateEmail(e.target.value)) {
                    setInvalid([...invalid, e.target.name]);
                    return;
                  }
                  setInvalid(invalid.filter((item) => item !== e.target.name));
                }}
                required
              />
              <input
                type="text"
                name="btc_address"
                className={invalid.includes('btc_address') ? 'invalid' : ''}
                value={state.btc_address}
                placeholder="enter your BTC address"
                onChange={handleChange}
                onBlur={(e): void => {
                  if (e.target.value.length === 0 || validateBTCAddress(e.target.value)) {
                    setInvalid(invalid.filter((item) => item !== e.target.name));
                    return;
                  }
                  setInvalid([...invalid, e.target.name]);
                }}
                required
              />
              <input
                type="text"
                name="btc_address_repeat"
                className={invalid.includes('btc_address_repeat') ? 'invalid' : ''}
                value={state.btc_address_repeat}
                placeholder="repeat your BTC address"
                onChange={handleChange}
                onBlur={(e): void => {
                  if (e.target.value.length === 0) {
                    setInvalid(invalid.filter((item) => item !== e.target.name));
                    return;
                  }
                  if (e.target.value !== state.btc_address || !validateBTCAddress(e.target.value)) {
                    setInvalid([...invalid, e.target.name]);
                    return;
                  }
                  setInvalid(invalid.filter((item) => item !== e.target.name));
                }}
                required
              />
              <select name="ordinal_type" value={state.ordinal_type} onChange={handleSelectOrdinal}>
                <option value="">select your ordinal</option>
                <option value="o-a">Ordinal Series A - $2000 in BTC</option>
                <option value="o-b">Ordinal Series B - $1000 in BTC</option>
                <option value="o-c">Ordinal Series C - $500 in BTC</option>
                <option value="go-a">Grand Ordinal Series A - $5k to $200k in BTC</option>
                <option value="go-b">Grand Ordinal Series B - $5k to $200k in BTC</option>
                <option value="go-c">Grand Ordinal Series C - $5k to $200k in BTC</option>
              </select>
              <input
                type="text"
                name="bid"
                className={invalid.includes('bid') ? 'invalid' : ''}
                value={state.bid}
                min={5000}
                max={200000}
                placeholder="place a bid for your Grand Ordinal"
                onChange={handleChange}
                onBlur={validateBid}
                disabled={!['go-a', 'go-b', 'go-c'].includes(state.ordinal_type)}
              />
            </div>
            <button>Whitelist</button>
          </form>

          <p className="btc-hero-subheading">
            Download the Revuto app and use the same email address to finish the registration for purchasing your
            ordinals. Thank You!
          </p>

          <div className="mobile-links">
            <ExternalLink href="https://play.google.com/store/apps/details?id=com.revuto.dev">
              <img src={GooglePlay} alt="Google play" />
            </ExternalLink>
            <ExternalLink href="https://apps.apple.com/us/app/revuto/id1550940719">
              <img src={AppStore} alt="App store" />
            </ExternalLink>
            <ExternalLink href="https://appgallery.huawei.com/app/C105005025">
              <img src={AppGallery} className="app-badges" alt="Huawei App Gallery" />
            </ExternalLink>
          </div>

          <div className="btc-hero-docs">
            <p>
              <a href="/ordinals_1.pdf" target="_blank" rel="noopener noreferrer">
                Read
              </a>{' '}
              why Revuto is announcing its seed round by offering ordinals on BTC.
            </p>
            <p>
              <a href="/ordinals_2.pdf" target="_blank" rel="noopener noreferrer">
                Learn
              </a>{' '}
              about Revuto and why investing in Revuto will change your life.
            </p>
          </div>
        </div>
      </div>
      <div className="btc-why">
        <div className="btc-ordinals">
          <h2>...when Lambo, Sir?</h2>
          <h3>Select yout ordinal(s) and boost your chances to earn yourself a Lambo.</h3>
          <div className="btc-ordinal-list">
            <div className="btc-ordinal">
              <h4>Ordinal Series A</h4>
              <img src={o_a} alt="Ordinal Series A" />
            </div>
            <div className="btc-ordinal">
              <h4>Ordinal Series B</h4>
              <img src={o_b} alt="Ordinal Series B" />
            </div>
            <div className="btc-ordinal">
              <h4>Ordinal Series C</h4>
              <img src={o_c} alt="Ordinal Series C" />
            </div>
          </div>
          <div className="btc-grandordinal-list">
            <div className="btc-ordinal">
              <h4>Grand Ordinal Series A</h4>
              <img src={go_a} alt="Grand Ordinal Series A" />
            </div>
            <div className="btc-ordinal">
              <h4>Grand Ordinal Series B</h4>
              <img src={go_b} alt="Grand Ordinal Series B" />
            </div>
            <div className="btc-ordinal">
              <h4>Grand Ordinal Series C</h4>
              <img src={go_c} alt="Grand Ordinal Series C" />
            </div>
          </div>
        </div>
        <div className="btc-lambo">
          <img src={revuelto} alt="Revuelto" />
          <img src={lambo} alt="Lambo" />
        </div>
      </div>
      <main className="main">
        <SocialContainer />
      </main>
      <Footer />
    </>
  );
};
