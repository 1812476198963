import React from 'react';
import classNames from 'classnames';

type InputFieldProps = React.HTMLProps<HTMLInputElement> & {
  errorMessage?: string;
  touched?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({ errorMessage, touched = true, className, ...props }) => {
  return (
    <div className={classNames('input-field', className)}>
      <input {...props} />
      <div className={classNames('error-message', { active: errorMessage && touched })}>
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};
