import {
  all,
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  spawn,
  takeEvery,
} from 'redux-saga/effects';
import {
  changePassword,
  registerBuyer,
  removeAuthorization,
  requireAuthorization,
  resetPassword,
  revokeAuthorization,
  setAddress,
  setAuthorizationError,
  setAuthorizeState,
  setChangePasswordError,
  setChangePasswordState,
  setRegisterError,
  setRegisterState,
  setResetPasswordError,
  setResetPasswordState,
  storeAuthorization,
  updateAddress,
  verifyAccount,
} from './actions';
import { postInit } from '../store';
import { AuthState, ChangePasswordState, RegisterState, ResetPasswordState } from './slice';
import {
  changeUserPassword,
  getMe,
  getVerification,
  loginUser,
  putCardanoAddress,
  registerUser,
  resetUserPassword,
} from '../../services/auth';
import jwtDecode from 'jwt-decode';
import { JwtToken } from '../../types/JwtToken';
import { setAuthorizationToken } from '../../services';
import { AxiosResponse } from 'axios';
import { ActivationDto, MeDto } from '../../types/dto/Auth.dto';
import { AuthData, getAuthData } from './selectors';
import { setProfileLoader } from '../loaders/actions';

const AUTH_STORAGE_KEY = 'authorization_key_v1';

function* processToken(token: string): Generator<PutEffect | CallEffect, void, AxiosResponse<MeDto>> {
  const decoded = jwtDecode<JwtToken>(token);
  const tokenTimeLeft = (decoded.exp - 10) * 1000 - Date.now();
  if (tokenTimeLeft > 0) {
    try {
      yield call(setAuthorizationToken, token);
      const { data } = yield call(getMe);
      yield put(
        storeAuthorization(
          token,
          decoded.role,
          data.firstName,
          data.lastName,
          data.applicantId,
          data.id,
          data.email,
          data.address,
        ),
      );
      yield put(setProfileLoader(false));
      yield delay(tokenTimeLeft);
      yield call(deauthorize); // you can change this with some refresh token saga
    } catch {
      yield call(deauthorize);
      yield put(setAuthorizationError('An unknown error occurred. Please try again later.'));
    }
  } else {
    yield call(deauthorize);
  }
}

function* initialization(): Generator<PutEffect | ForkEffect, void, void> {
  try {
    const token = localStorage.getItem(AUTH_STORAGE_KEY);
    if (token) {
      yield spawn(processToken, token);
    } else {
      yield put(setAuthorizeState(AuthState.GUEST));
    }
  } catch (e) {
    console.error(e);
  }
}

function* register({
  payload: data,
  meta: onFinish,
}: ReturnType<typeof registerBuyer>): Generator<PutEffect | CallEffect, void, AxiosResponse> {
  try {
    yield put(setRegisterState(RegisterState.NULL));
    yield put(setRegisterError(''));
    const response: AxiosResponse = yield call(registerUser, data);
    if (response.status === 200) {
      yield put(setRegisterState(RegisterState.SUCCESS));
      onFinish();
    }
  } catch (err) {
    yield put(setRegisterState(RegisterState.FAILED));
    yield put(setRegisterError(err.response?.data?.message || 'An unknown error occurred. Please try again later.'));
    onFinish();
  }
}

function* reset({
  payload: data,
  meta: onFinish,
}: ReturnType<typeof resetPassword>): Generator<PutEffect | CallEffect, void, AxiosResponse> {
  try {
    yield put(setResetPasswordState(ResetPasswordState.NULL));
    yield put(setResetPasswordError(''));
    const response: AxiosResponse = yield call(resetUserPassword, data);
    if (response.status === 200) {
      onFinish();
      yield put(setResetPasswordState(ResetPasswordState.SUCCESS));
    }
  } catch (err) {
    yield put(setResetPasswordState(ResetPasswordState.FAILED));
    yield put(
      setResetPasswordError(err.response?.data?.message || 'An unknown error occurred. Please try again later.'),
    );
  }
}

function* changePass({
  payload: data,
}: ReturnType<typeof changePassword>): Generator<PutEffect | CallEffect, void, AxiosResponse> {
  try {
    yield put(setChangePasswordState(ChangePasswordState.NULL));
    yield put(setChangePasswordError(''));
    const response: AxiosResponse = yield call(changeUserPassword, data);
    if (response.status === 200) {
      yield put(setChangePasswordState(ChangePasswordState.SUCCESS));
    }
  } catch (err) {
    yield put(setChangePasswordState(ChangePasswordState.FAILED));
    yield put(
      setChangePasswordError(err.response?.data?.message || 'An unknown error occurred. Please try again later.'),
    );
  }
}
function* authorize({
  payload: { email, password },
  meta: onFinish,
}: ReturnType<typeof requireAuthorization>): Generator<
  PutEffect | ForkEffect | CallEffect,
  void,
  string & AxiosResponse
> {
  yield put(setAuthorizationError(''));
  try {
    const response: AxiosResponse = yield call(loginUser, email, password);
    const { token } = response.data;
    localStorage.setItem(AUTH_STORAGE_KEY, token);
    onFinish();
    yield spawn(processToken, token);
  } catch (err) {
    yield put(setAuthorizeState(AuthState.GUEST));
    yield put(
      setAuthorizationError(err.response?.data?.message || 'An unknown error occurred. Please try again later.'),
    );
    onFinish();
  }
}

function* deauthorize(): Generator<PutEffect, void> {
  try {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  } catch {
    console.error('Unable to remove token from localStorage');
  }
  yield put(setProfileLoader(true));
  yield put(removeAuthorization());
}

function* verification(
  action: ReturnType<typeof verifyAccount>,
): Generator<CallEffect, void, AxiosResponse<ActivationDto>> {
  try {
    const result = yield call(getVerification, action.payload.token);
    action.payload.verified(result.data.activated);
  } catch {
    action.payload.verified(false);
  }
}

function* updateCardanoAddress({
  payload,
}: ReturnType<typeof updateAddress>): Generator<CallEffect | SelectEffect | PutEffect, void, AuthData> {
  const { id } = yield select(getAuthData);
  try {
    yield call(putCardanoAddress, id, payload.address);
    yield put(setAddress(payload.address));
  } catch (e) {
    console.log(e);
    payload.onAddressError(e.response?.data?.message || 'An unknown error occurred. Please try again later.');
  }
}

export function* authSagaWatcher(): Generator {
  yield all([
    takeEvery(updateAddress, updateCardanoAddress),
    takeEvery(verifyAccount, verification),
    takeEvery(registerBuyer, register),
    takeEvery(requireAuthorization, authorize),
    takeEvery(revokeAuthorization, deauthorize),
    takeEvery(resetPassword, reset),
    takeEvery(changePassword, changePass),
    takeEvery(postInit, initialization),
  ]);
}
