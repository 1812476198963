import React from 'react';
import { Button } from '../../components/Button';
// import video1 from '../../assets/video/r1_c.mp4';
// import video2 from '../../assets/video/r2_c.ogx';
// import video3 from '../../assets/video/r3_c.webm';
// import video4 from '../../assets/video/r4_c.m4v';
// import poster from '../../assets/video/revuto_hero_video-poster.png';

export const HeroContainer: React.FC = () => {
  return (
    <div className="wrapper">
      <div className="hero-container row start-xs page-wrapper">
        <div className="col-xs-12 col-sm-6">
          <h1>Subscribe, control and pay with crypto!</h1>
          <p className="light">
            Control your subscriptions with <b>the first dApp on Cardano</b> and leverage recurring payments with REVU
            tokens and DeFi services.
          </p>

          <form
            id="email-subscription"
            className="scout-input-box"
            action="https://email.revuto.com/x/plugin/?pName=subscribe&MIDRID=S7Y1BAAA38&pLang=en&Z=-290293691"
            method="post"
          >
            <input type="hidden" name="DMDtask" value="subscribe" />
            <input type="email" name="email" placeholder="Email" />
            <Button
              type="submit"
              label="Sign up"
              className="g-recaptcha"
              data-sitekey="6Lc8KgQfAAAAAAniUYx7KPC_kQG6BSM_KFn0SvRc"
              data-callback="onSubmit"
              data-action="submit"
            />
          </form>
        </div>

        <div className="col-xs-12 col-sm-5 col-sm-offset-1">
          <div className="hero-image" />
        </div>

        {/* <div className="hero-image-container col-xs-12 col-sm-5 col-sm-offset-1">
          <video autoPlay loop muted playsInline poster={poster} width="360" height="480" id="background">
            <source src={video4} type="video/x-m4v" />
            <source src={video1} type="video/mp4" />
            <source src={video3} type="video/webm" />
            <source src={video2} type="video/ogg" />
            This does not work
          </video>
        </div> */}
      </div>
    </div>
  );
};
