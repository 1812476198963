export function shuffleArray<G>(array: G[], seed = 1): G[] {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  const random = function (): number {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}
