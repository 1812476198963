import { all, call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { getTokenSaleStats } from '../../services/token-stats';
import { AxiosResponse } from 'axios';
import { TokenSaleStats } from '../../types/dto/TokenStats.dto';
import { setStats, setTotalEurRaised } from './actions';
import { postInit } from '../store';
import { setDashboardLoader } from '../loaders/actions';

const TOTAL_EUR_RAISED_KEY = 'totalEurRaised_v1';

function* getStats(): Generator<CallEffect | PutEffect, void, AxiosResponse<TokenSaleStats>> {
  try {
    const eurRaised = localStorage.getItem(TOTAL_EUR_RAISED_KEY);
    if (eurRaised) yield put(setTotalEurRaised(Number(eurRaised)));
  } catch (e) {
    console.log(e);
  }
  try {
    const stats = yield call(getTokenSaleStats);
    yield put(setStats(stats.data));
    yield put(setDashboardLoader(false));

    try {
      localStorage.setItem(TOTAL_EUR_RAISED_KEY, String(stats.data.totalEurRaised));
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
    console.log(e);
  }
}

export function* statsSagaWatcher(): Generator {
  yield all([takeLatest(postInit, getStats)]);
}
