import { BuyerNFTClaim, NFT } from '../../types/dto/NFT';
import { RootState } from '../store';
import { format } from 'date-fns';

export const selectAllNFTs = (state: RootState): NFT[] => state.nft.allNFTs;

export const selectUnclaimedNFTs = (state: RootState): NFT[] => state.nft.unclaimedNFTs;

export const selectBuyerNFTClaim = (state: RootState): BuyerNFTClaim | null => state.nft.buyerNFTClaim;

export const selectNFTClaimTime = (state: RootState): string => {
  if (state.nft.buyerNFTClaim) {
    const date = new Date(state.nft.buyerNFTClaim.timestamp);
    return format(date, 'HH:mm dd.MM.yyyy');
  } else return '';
};

export const selectRandomNFTs = (state: RootState): { spinner: NFT[]; winner: NFT } | null =>
  state.nft.randomSpinnerNFTs;
