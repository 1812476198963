import { createSlice } from '@reduxjs/toolkit';

export enum KYCStatus {
  Unknown,
  Awaiting,
  Pending,
  Failed,
  Finish,
  Retry,
}

interface KYCState {
  status: KYCStatus;
}

const initialState: KYCState = {
  status: KYCStatus.Unknown,
};

export const KYCSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setKycStatus: (state, action): void => {
      state.status = action.payload;
    },
  },
});
