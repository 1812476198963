import React from 'react';
import { Feature } from '../../components/Feature';

export const DappContainer: React.FC = () => {
  return (
    <div id="dapp" className="dapp-container row start-xs section-spacing">
      <div className="col-xs-12">
        <div className="col-xs-12 col-lg-9 title">
          <h2>Revuto dApp</h2>
          <p className="light">
            Subscribe with <b>Revuto Debit Cards supporting Cardano</b> native tokens to <b>Block, Snooze or Approve</b>{' '}
            subscription charges as they occur and to:
          </p>
        </div>

        <div className="row between-xs features">
          <div className="col-lg-4 col-xs-6">
            <Feature
              title="deposit native tokens"
              description="Deposit Cardano native tokens to your Revuto dApp."
              icon="icon-phone"
            />
          </div>
          <div className="col-lg-4 col-xs-6">
            <Feature
              title="pay and subscribe"
              description="Use Revuto Virtual Debit cards to subscribe and pay with Cardano Native tokens."
              icon="icon-card"
            />
          </div>
          <div className="col-lg-4 col-xs-6">
            <Feature
              title="get referral rewards"
              description="Bring us your friends and earn rewards in REVU tokens."
              icon="icon-rewards"
            />
          </div>

          <div className="col-lg-4 col-xs-6">
            <Feature
              title="stake your revu"
              description="Stake and keep REVU tokens while getting a loan in stable coin to pay for your subscriptions."
              icon="icon-wallet"
            />
          </div>
          <div className="col-lg-4 col-xs-6">
            <Feature
              title="get cashback"
              description="Get cashback in REVU tokens when paying for your subscriptions."
              icon="icon-coins"
            />
          </div>
          <div className="col-lg-4 col-xs-6">
            <Feature
              title="earn yield"
              description="Earn yields on your REVU tokens when providing liquidity to the protocol."
              icon="icon-income"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
