import { all } from 'redux-saga/effects';
import { authSagaWatcher } from './auth/sagas';
import { kycSagaWatcher } from './kyc/sagas';
import { subscribeSagaWatcher } from './subscribe/sagas';
import { depositSagaWatcher } from './deposit/sagas';
import { statsSagaWatcher } from './stats/sagas';
import { nftSagaWatcher } from './nft/sagas';

export function* rootSaga(): Generator {
  yield all([
    authSagaWatcher(),
    subscribeSagaWatcher(),
    kycSagaWatcher(),
    depositSagaWatcher(),
    statsSagaWatcher(),
    nftSagaWatcher(),
  ]);
}
