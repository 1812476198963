import React from 'react';

import DeFiImage from '../../assets/images/DeFi.png';
import { DarkBackground } from '../../components/DarkBackground';

export const DeFiContainer: React.FC = () => {
  return (
    <DarkBackground className="section-spacing">
      <div id="defi" className="defi-container">
        <h2 className="uppercase">DeFi micro lending</h2>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <p>
              Take advantage of rapidly growing decentralized finance to bring freedom to financial services such as
              lending and borrowing. With DeFi, short-term lenders and borrowers benefit from the Revuto ecosystem when
              paying subscriptions with Revu.
            </p>
          </div>
          <div className="col-xs-12 col-md-6 center-xs middle-xs">
            <img src={DeFiImage} alt="DeFi micro lending" />
          </div>
        </div>
      </div>
    </DarkBackground>
  );
};
