import { AxiosResponse } from 'axios';
import { BuyerNFTClaim, ClaimNFTPostBody, NFT, NFTClaimResponse } from '../types/dto/NFT';
import { instance } from './private';

export const fetchAllUnclaimedNFTs = (): Promise<AxiosResponse<NFT[]>> => instance.get('/v1/nfts');

export const fetchAllNFTs = (): Promise<AxiosResponse<NFT[]>> => instance.get('/v1/nfts/all');

export const claimNFT = (claimNFT: ClaimNFTPostBody): Promise<AxiosResponse<NFTClaimResponse>> =>
  instance.post('/v1/nfts', claimNFT);

export const getBuyerNFTClaim = (): Promise<AxiosResponse<BuyerNFTClaim>> => instance.get('/v1/nfts/buyer');
