import React from 'react';

export interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label: string;
  /**
   * Custom classname
   */
  className?: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  type?: 'reset' | 'submit' | 'button';
  special?: 'whitelist';
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  className,
  primary = false,
  size = 'medium',
  label,
  special,
  ...props
}) => {
  const mode = special ? special : primary ? 'primary' : 'secondary';
  return (
    <button className={['button', `button--${size}`, mode, className].join(' ')} {...props}>
      {label}
    </button>
  );
};
