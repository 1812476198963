import React from 'react';
import { ExternalLink } from '../../components/ExternalLink';

export const TermsOfWallet: React.FC = () => {
  return (
    <div className="page-wrapper wrapper plain-pages">
      <h1>Terms for Revuto Crypto Wallet</h1>
      <p>Effective as of 19th October, 2021.</p>
      <p>You are about to use the Revuto Crypto Wallet. We&apos;re excited for you!</p>
      <p>
        Before you get on, REVUTO OÜ needs you to carefully read, understand and accept our Terms for Revuto Crypto
        Wallet.
      </p>
      <p>
        The Terms for Revuto Crypto Wallet constitute a legally binding agreement between You and the Affiliate (as
        defined in <a href="/terms-of-use">Terms of Use</a>) meaning Revuto Savjetovanje d.o.o, a company registered in
        Croatia, Vladimira Nazora 21, 10000 Zagreb, CIN 05361567). By clicking the accept button, accessing,
        downloading, using or attempting to use Revuto Crypto Wallet you confirm that you agree to these terms.
      </p>
      <p>
        The Terms for Revuto Crypto Wallet apply in addition to the Revuto <a href="/terms-of-use">Terms of Use</a>
        &nbsp; including any documents and any linked terms. Any capital letter term in the Terms for Revuto Crypto
        Wallet will have the same meaning as in Revuto <a href="/terms-of-use">Terms of Use</a> if not defined herein.
        In the absence of an explicit provision, all provisions from &nbsp;<a href="/terms-of-use">Terms of Use</a>{' '}
        apply to the use of Wallet. In the event of direct contradiction between{' '}
        <a href="/terms-of-use">Terms of Use</a> and Terms for Revuto Crypto Wallet regarding the same subject matter,
        the latter shall prevail. Titles and headings provided in the Terms for Revuto Crypto Wallet are for convenience
        and ease of access only and they will not affect the interpretation of the terms.
      </p>
      <p>If you do not agree with any of the terms, do not use the Wallet.</p>
      <p>
        Your use of the Wallet is also subject to our <a href="/privacy-policy">Privacy policy</a>.
      </p>
      <h4>1. Disclaimer on the value of cryptocurrencies</h4>
      <p>
        Revuto is a blockchain project, but unlike other similar projects, REVUTO OÜ strives to bring crypto to the
        mainstream world by integrating it with its flagship product Revuto App.
      </p>
      <p>
        However, the User is fully and solely responsible for evaluating their use of cryptocurrencies and Revuto
        Services. Each User understands that the VALUES OF CRYPTOCURRENCIES (as defined below){' '}
        <strong>
          MAY FLUCTUATE SIGNIFICANTLY DEPENDING ON MARKET CONDITIONS AND OTHER RISKS AND THE USER UNDERSTANDS AND
          ACCEPTS A SUBSTANTIAL RISK OF ECONOMIC LOSS ASSOCIATED WITH HOLDING, PURCHASING, SELLING OR TRANSACTING IN
          CRYPTOCURRENCIES. BY ACCEPTING THESE TERMS THE USER ACKNOWLEDGES AND AGREES THAT:
        </strong>
      </p>
      <p>
        <strong>(1) THESE RISKS ARE ASSOCIATED TO THE USE OF REVUTO SERVICES AND THE USER ACCEPTS SUCH RISK;</strong>
      </p>
      <p>
        <strong>(2) REVUTO OÜ OR ITS AFFILIATE SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.</strong>
      </p>
      <h4>2. What is Revuto Crypto Wallet?</h4>
      <p>
        Revuto is the first subscription management service that allows payments in crypto. Users who wish to pay for
        their subscriptions using crypto will need to download Revuto&apos;s Crypto Wallet (hereinafter:{' '}
        <strong>Wallet</strong>) within the Revuto App. Crypto Wallet is a software program which interacts with the
        blockchain network to generate and manage sets of private keys and public keys, configure transactions and
        monitor their balance. The Revuto Crypto Wallet is a digital cryptocurrency non-custodial mobile wallet
        supporting all Cardano-based tokens, while by default including REVU and ADA.
      </p>
      <p>
        From time to time REVUTO OÜ may introduce upgrades and new features to the Wallet. The User must ensure to keep
        the Wallet software updated at all times.
      </p>
      <h4>3. How does the Revuto Crypto Wallet work?</h4>
      <p>
        The Wallet has specific functionalities allowing for automatic pull payments. In order to pay for subscriptions
        using cryptocurrency, the Users will first need to approve the REVU or EURr stablecoin from within their Wallet.
        After the User sets up the Wallet for recurring payments, the Wallet will invoke the smart contract, permitting
        the server Wallet to spend the funds from the User&apos;s Wallet at specific points in the future.
      </p>
      <p>
        Additionally, to be able to withdraw ADA, and the REVU token obtained in our Token Sale or through Revuto
        Referral Program in accordance with the rules under the Token Sale Whitepaper, a User who has previously
        successfully completed the KYC, has to create the Wallet within the Revuto App.
      </p>
      <p>
        For clarity, a User who has not gone through the KYC successfully may not obtain any REVU token or ADA or any
        other cryptocurrency.
      </p>
      <p>
        The User must at all times follow the Wallet Guidelines explained{' '}
        <ExternalLink href="https://getrevuto.medium.com/answering-questions-about-our-new-cardano-lite-wallet-integration-20d6dfaed674">
          here
        </ExternalLink>
        .
      </p>
      <h4>4. Usage Requirements</h4>
      <p>
        The Wallet can only be used by the owner of the User account who has previously successfully passed the KYC. The
        User understands that REVUTO OÜ, and the User must at all times comply with the AML/CTF. The User understands
        that the User may not use the Wallet or withdraw any cryptocurrencies before going through KYC successfully. The
        User acknowledges and agrees that REVUTO OÜ may require additional data or identity checks at the point of
        application for any Revuto Service. REVUTO OÜ reserves the right to forbid, suspend, freeze or cancel the use of
        the User account and the Wallet by persons other than the owner of the User account or in the event Revuto
        obtains information or suspects that User has provided false information even after the KYC procedure has been
        completed.
      </p>
      <p>
        If a User suspects or becomes aware of any unauthorized use of its username and password, the User should notify
        REVUTO OÜ immediately. REVUTO OÜ assumes no liability for any loss or damage arising from the use of Wallet by
        you or any third party with or without your authorization.
      </p>
      <h4>5. Wallet Security</h4>
      <p>
        As with any Cardano wallet, when you set up the Wallet, a pair of keys is being generated: the public and the
        private key.
      </p>
      <p>
        The private key is connected to the Wallet public address and, together, they can be used to authorize the
        transfer of cryptocurrency to and from the Wallet public address.
      </p>
      <p>
        REVUTO OÜ has implemented industry security standard protection for the Wallet. However, the actions of
        individual Users may pose risks.
      </p>
      <p>
        You agree to treat your access credentials (such as username and password), PINs, private keys, seed phrase
        (mnemonic), and other credentials as confidential information, and not to disclose such information to any third
        party. You also agree to be solely responsible for taking the necessary security measures to protect your User
        account and personal information.
      </p>
      <p>
        The Wallet stores your Wallet public address but does not receive or store your Wallet password, encrypted
        private key, unencrypted private key, secret key or Wallet seed phrase associated with your Wallet. We cannot,
        therefore, assist you with Wallet password retrieval. We cannot generate a new password for your Wallet if you
        fail to remember your original password. If you have not safely stored a backup of any Wallet address and
        private key pairs maintained in your Wallet, you accept and acknowledge that any cryptocurrency you have
        associated with such a Wallet address will become inaccessible if you do not have your Wallet password.
      </p>
      <p>
        The User is solely responsible for all the transactions under the User account and Wallet. REVUTO
        OÜ&nbsp;assumes no liability for any loss or consequences caused by authorized or unauthorized use of User
        account or Wallet credentials.
      </p>
      <p>
        The User also understands that the secret keys are held securely on the device on which the Revuto App has been
        installed and not on servers in ownership or control of REVUTO OÜ and its Affiliates.
      </p>
      <p>You hereby agree that:</p>
      <ol>
        <li>
          you will notify REVUTO OÜ immediately if you are aware of any unauthorized use of your Revuto account and/or
          Wallet or any other violation of security rules;
        </li>
        <li>
          you will strictly abide by all mechanisms or procedures of REVUTO OÜ regarding security, authentication,
          trading, charging, and withdrawal.
        </li>
      </ol>
      <p>
        User agrees that User should never share User&apos;s certificate with any natural or legal person, including
        Cardano Foundation, or any other entity. Further, User acknowledges that sharing User&apos;s certificate may
        result in loss of User&apos;s cryptocurrency, and User agrees that REVUTO OÜ shall not be responsible for such
        loss.
      </p>
      <h4>6. Inability to Access the Wallet and Recovery</h4>
      <p>The User understands and accepts that all transaction requests are irreversible.</p>
      <p>
        User acknowledges that REVUTO OÜ shall not be responsible for transferring, safeguarding, or maintaining private
        keys and/or User&apos;s cryptocurrency. If User loses access to Wallet and/or User account or private keys, the
        User understands that any cryptocurrency within the Wallet will become inaccessible. Revuto and its
        shareholders, Affiliates, officers, directors, employees and agents cannot retrieve User&apos;s private keys
        and/or credentials and enable the access to the Wallet.
      </p>
      <p>
        The only option for the User to restore the Wallet within the Revuto App is by entering the User&apos;s seed
        phrase. Seed phrase is a 24-word which the User receives upon creating the Wallet.
      </p>
      <p>
        If the User moves the Revuto App to another device or choses to delete the Revuto App, the cryptocurrencies can
        only be recovered with the seed phrase.
      </p>
      <p>
        If Revuto Services are unavailable or suspended, you can always recover your wallet by using your seed phrase on
        any compatible wallet.
      </p>
      <h4>7. Disclaimer of Warranties</h4>
      <p>
        Your use of Wallet is at your sole risk. The service is provided on an &quot;as is&quot; and &quot;as
        available&quot; basis.
      </p>
      <p>
        User is solely responsible for any damage User may suffer resulting from the use of the Wallet. No oral or
        written information or advice given by REVUTO OÜ or its authorized representatives or its Affiliates shall
        create a warranty or in any way create or increase the scope of REVUTO OÜ&apos;s obligations or create
        obligations of its Affiliates.
      </p>
      <p>
        REVUTO OÜ makes no representations about the suitability, reliability, availability, continuity and timeliness
        of the Wallet.
      </p>
      <p>
        Users understand and accept that some transactions facilitated or enabled by Revuto may be delayed, and that
        REVUTO OÜ or its Affiliates shall not be responsible for any associated loss.
      </p>
      <p>
        In order to be completed, all proposed cryptocurrency transactions must be confirmed and recorded in the
        associated public blockchain. Such networks are decentralized, peer-to-peer networks supported by independent
        third parties, which we do not own, control, or operate. We have no control over the blockchain networks and,
        therefore, cannot and do not ensure that any transaction details that you submit via Revuto Services will be
        confirmed and processed. By using the Wallet, you acknowledge and agree that the transaction details you submit
        may not be completed, or may be substantially delayed, by blockchain networks and that REVUTO OÜ makes no
        warranties in this regard.
      </p>
      <h4>8. Exclusion of Liability</h4>
      <p>
        In addition to the Exclusion and Limitation of Liability clause in <a href="/terms-of-use">Terms of Use</a>, You
        understand and agree that we have no control over, and no duty to take any action regarding:
      </p>
      <p>1. The risk of failure of hardware, software behind the Wallet, and Internet connections;</p>
      <p>
        2. The risk of malicious software being introduced or found in the software underlying the Wallet or Revuto App;
      </p>
      <p>
        3. The risk that third parties may obtain unauthorized access to information stored within your Wallet,
        including, but not limited to your Wallet address, private key, and seed phrase;
      </p>
      <p>4. The risk of unknown vulnerabilities in or unanticipated changes to the blockchain networks.</p>
      <p>Additionally, you release us from all liability related to any losses, damages, or claims arising from:</p>
      <p>a) server failure or data loss;</p>
      <p>b) unauthorized access to the Wallet;</p>
      <p>c) bugs or other errors in the Wallet;</p>
      <p>
        d) any unauthorized third party activities, including, but not limited to, the use of viruses, phishing, brute
        forcing, or other means of attack against Wallet or Revuto App;
      </p>
      <p>e) your failure to update the Wallet software.</p>
      <p>
        The User also acknowledges that REVUTO OÜ and its Affiliates have no liability associated with the following
        risks:
      </p>
      <p>
        i) Blockchain technologies are subject to continuous regulatory changes and scrutiny around the world, including
        but not limited to anti-money laundering and financial regulations. You acknowledge that certain Services,
        including their availability, could be impacted by one or more regulatory requirements.
      </p>
      <p>
        ii) Transactions in cryptocurrencies (including but not limited to exchanges, air-drops, forks, and gains), may
        be subject to taxation. These rules may be unclear or subject to change, and you are therefore encouraged to
        obtain advice from an expert.
      </p>
    </div>
  );
};
