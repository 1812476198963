import React from 'react';

import { Partner } from '../../components/Partner';
import { ReactComponent as Cardano } from '../../assets/images/partners/cardano.svg';
// import { ReactComponent as NodeFactory } from '../../assets/images/partners/nodefactory.svg';
import { ReactComponent as FourOFour } from '../../assets/images/partners/404.svg';

export const PartnershipContainer: React.FC = () => {
  return (
    <div className="partnership-container section-spacing">
      <h2 className="uppercase">partnerships</h2>
      <p className="light">Get to know those who are joining us</p>

      <div className="row evenly-xs">
        <Partner name="Chainlink" imageClassName="chainlink" link="https://chain.link/" />
        <Partner name="AdaLite" imageClassName="adalite" link="https://adalite.io/" />
        {/* <Partner name="NodeFactory" image={<NodeFactory />} link="https://nodefactory.io" /> */}
        <Partner name="404" image={<FourOFour />} link="https://www.404.agency/" />
        <Partner name="IOHK" imageClassName="iohk" link="http://iohk.io/" />
        <Partner name="Cardano Croatia" image={<Cardano />} link="https://www.meetup.com/Cardano-Blockchain-Croatia/" />
        <Partner name="Async Labs" imageClassName="async-labs" link="https://www.asynclabs.co/" />
        <Partner name="Beyondi" imageClassName="beyondi" link="https://beyondi.co/" />
        <Partner name="BlackDragon" imageClassName="blackdragon" link="https://www.blackdragon.io/" />
        {/* <Partner name="Electrocoin" imageClassName="electrocoin" link="https://electrocoin.hr/" /> */}
        <Partner name="BlokBe" imageClassName="blokbe" link="https://blokbe.com/" />
        <Partner name="ZunicLaw" imageClassName="zunic" link="https://zuniclaw.com/" />
        <Partner name="gate.io" imageClassName="gateIo" link="https://gate.io/" />
        <Partner name="Kucoin" imageClassName="kucoin" link="https://kucoin.com/" />
        <Partner name="MarketAcross" imageClassName="marketacross" link="https://marketacross.com/" />
        <Partner name="Sumsub" imageClassName="sumsub" link="https://sumsub.com/" />
        <Partner name="VyFi" imageClassName="vyfi" link="https://vyfi.io/" />
        {/* <Partner name="BitMart" imageClassName="bitmart" link="https://bitmart.com/" /> */}
        <Partner name="Genius Yield" imageClassName="genius-yield" link="https://geniusyield.co/" />
        <Partner name="Coti" imageClassName="coti" link="https://coti.io/" />
        <Partner name="ADA Handle" imageClassName="adahandle" link="https://adahandle.com/" />
        <Partner name="Profila" imageClassName="profila" link="https://profila.com/" />
        {/* <Partner name="Cardax" imageClassName="cardax" link="https://cardax.io/" /> */}
        <Partner name="Cardahub" imageClassName="cardahub" link="https://cardahub.io/home" />
        <Partner name="Railsr" imageClassName="railsr" link="https://www.railsr.com/" />
        <Partner name="Worldpay" imageClassName="worldpay" link="https://worldpay.fisglobal.com/" />
        <Partner name="NMKR" imageClassName="nmkr" link="https://nmkr.io/" />
        <Partner name="Cardano RealFI" imageClassName="realfi" link="https://real-fi.org/" />
        <Partner name="Indigo Protocol" imageClassName="indigo" link="https://indigoprotocol.io/" />
        <Partner name="Banxa" imageClassName="banxa" link="https://banxa.com/" />
      </div>
    </div>
  );
};
