// build with http://jwtbuilder.jamiekurtz.com/
// key = qwertyuiopasdfghjklzxcvbnm123456
import { instance as privateApi } from './private';
import { AxiosResponse } from 'axios';
import { ChangePasswordData, RegisterData, ResetPasswordData } from '../types/auth';
import { ActivationDto, MeDto } from '../types/dto/Auth.dto';
// const fakeToken =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MDM2OTYyMTQsImV4cCI6MTYzNTIzMjIxMCwiYXVkIjoiIiwic3ViIjoiIiwibmFtZSI6IkpvaG5ueSIsInN1cm5hbWUiOiJSb2NrZXQiLCJlbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJyb2xlIjpbIm1hbmFnZXIiLCJ1c2VyIl19.8dueGcmDVhU6i26LX9PPMpsBIva5wJbd4e8vgCpMRPg';

export const loginUser = (email: string, password: string): Promise<AxiosResponse> =>
  privateApi.post('/v1/auth/login', {
    email: email.trim(),
    password,
  });

export const registerUser = (data: RegisterData): Promise<AxiosResponse> => {
  return privateApi.post('/v1/auth/register', { ...data, email: data.email.trim() });
};

export const resetUserPassword = (data: ResetPasswordData): Promise<AxiosResponse> => {
  return privateApi.get('/v1/auth/password/reset', { params: { email: data.email } });
};

export const changeUserPassword = (data: ChangePasswordData): Promise<AxiosResponse> => {
  return privateApi.put(`/v1/auth/password/${data.token}`, { password: data.password });
};
export const getMe = (): Promise<AxiosResponse<MeDto>> => privateApi.get('/v1/buyers/me');

export const getVerification = (activationHash: string): Promise<AxiosResponse<ActivationDto>> =>
  privateApi.get(`/v1/auth/activate/${activationHash}`);

export const putCardanoAddress = (userId: number, address: string): Promise<AxiosResponse> =>
  privateApi.put(`/v1/buyers/${userId}/address`, { address });
