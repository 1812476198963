import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DepositFailedDto, DepositSuccessfulDto } from '../../types/dto/Deposit.dto';

interface DepositReducerState {
  address: string;
  round: string;
  total: number;
  successful: DepositSuccessfulDto[];
  failed: DepositFailedDto[];
}

const initialState: DepositReducerState = {
  address: 'loading address...',
  round: 'unknown',
  total: 0,
  successful: [],
  failed: [],
};

export const depositSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setDepositAddress: (state, action: PayloadAction<{ address: string; round: string }>): void => {
      state.address = action.payload.address;
      state.round = action.payload.round;
    },
    storeRawDepositData: (
      state,
      action: PayloadAction<{ successful: DepositSuccessfulDto[]; failed: DepositFailedDto[] }>,
    ): void => {
      state.successful = action.payload.successful;
      state.failed = action.payload.failed;
    },
  },
});
