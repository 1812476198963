import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenSaleStats } from '../../types/dto/TokenStats.dto';

interface StatsState {
  stats: TokenSaleStats | null;
  totalEurRaised: number;
}

const initialState: StatsState = {
  stats: null,
  totalEurRaised: 0,
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStats: (state, action: PayloadAction<TokenSaleStats>): void => {
      state.stats = action.payload;
      state.totalEurRaised = action.payload.totalEurRaised;
    },
    setTotalEurRaised: (state, action: PayloadAction<number>): void => {
      state.totalEurRaised = action.payload;
    },
  },
});
