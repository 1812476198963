import React, { FC, useState, useEffect, EffectCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { revokeAuthorization } from '../../ducks/auth/actions';
import { getAuthState } from '../../ducks/auth/selectors';
import { AuthState } from '../../ducks/auth/slice';
import { ExternalLink } from '../ExternalLink';
import { NavigationLink } from '../NavigationLink';

interface IHeader {
  whiteHeader?: boolean;
}

export const Header: FC<IHeader> = ({ whiteHeader }) => {
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);
  const authState = useSelector(getAuthState);
  const match = useRouteMatch<{ page: string }>('/:page/');
  const history = useHistory();

  useEffect((): ReturnType<EffectCallback> => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('overflow-hidden');
    }
    return (): void => {
      body && body.classList.remove('overflow-hidden');
    };
  }, []);

  const handleClick = (): void => {
    const body = document.querySelector('body');
    if (body) {
      !click ? body.classList.add('overflow-hidden') : body.classList.remove('overflow-hidden');
    }
    setClick(!click);
  };
  const closeMobileMenu = (): void => {
    const body = document.querySelector('body');
    body && body.classList.remove('overflow-hidden');
    setClick(false);
  };

  const onNavOptionClick = (): void => {
    closeMobileMenu();
    history.push('/');
  };

  return (
    <div className="header">
      <header className="wrapper large-wrapper">
        <div className="row center-xs middle-xs between-xs flex">
          <a href="https://revuto.com">{whiteHeader ? <div className="logo" /> : <div className="logo-white" />}</a>

          <div className="nav-links">
            <ul className={click ? 'nav-options active' : 'nav-options'}>
              <li className="option">
                <Link to="/btc" className="small bold">
                  <span>Buy Ordinals</span>
                  <i className="revuto-icon icon-arrow-right" />
                </Link>
              </li>
              <li className="option" onClick={onNavOptionClick}>
                <NavigationLink page={match?.params.page} anchorHref="#about">
                  <span>About</span>
                  <i className="revuto-icon icon-arrow-right" />
                </NavigationLink>
              </li>
              <li className="option" onClick={onNavOptionClick}>
                <NavigationLink page={match?.params.page} anchorHref="#token">
                  <span>REVU token</span>
                  <i className="revuto-icon icon-arrow-right" />
                </NavigationLink>
              </li>
              <li className="option" onClick={onNavOptionClick}>
                <NavigationLink page={match?.params.page} anchorHref="#defi">
                  <span>DeFi</span>
                  <i className="revuto-icon icon-arrow-right" />
                </NavigationLink>
              </li>
              <li className="option" onClick={onNavOptionClick}>
                <NavigationLink page={match?.params.page} anchorHref="#team">
                  <span>Team</span>
                  <i className="revuto-icon icon-arrow-right" />
                </NavigationLink>
              </li>
              <li className="option" onClick={onNavOptionClick}>
                <NavigationLink page={match?.params.page} anchorHref="#roadmap">
                  <span>Roadmap</span>
                  <i className="revuto-icon icon-arrow-right" />
                </NavigationLink>
              </li>
              <li className="option" onClick={closeMobileMenu}>
                <ExternalLink href="https://getrevuto.medium.com" className="small bold">
                  <span>Blog</span>
                  <i className="revuto-icon icon-arrow-right" />
                </ExternalLink>
              </li>
              <li className="option" onClick={closeMobileMenu}>
                <a href="/Revuto_Whitepaper_v1.9.pdf" target="_blank" className="small bold">
                  <span>Whitepaper</span>
                  <i className="revuto-icon icon-arrow-right" />
                </a>
              </li>
              {/* <li className="option">
                <Link to="/token-sale" className="small bold">
                  <span>Token Sale</span>
                  <i className="revuto-icon icon-arrow-right" />
                </Link>
              </li> */}
              <li className="option">
                <ExternalLink href="https://revuto.com/nft" className="small bold">
                  <span>NFTs</span>
                  <i className="revuto-icon icon-arrow-right" />
                </ExternalLink>
                {/* <Link to="/nft" className="small bold">
                  <span>NFTs</span>
                  <i className="revuto-icon icon-arrow-right" />
                </Link> */}
              </li>
              <li className="option">
                <Link to="/rfund" className="small bold">
                  <span>R Fund</span>
                  <i className="revuto-icon icon-arrow-right" />
                </Link>
              </li>
              <li className="option">
                <Link to="/wallet" className="small bold">
                  <span>Wallet</span>
                  <i className="revuto-icon icon-arrow-right" />
                </Link>
              </li>
              <li className="option" onClick={closeMobileMenu}>
                <ExternalLink href="https://revuto.gitbook.io/revuto/about-us/revuto" className="small bold">
                  <span>GitBook</span>
                  <i className="revuto-icon icon-arrow-right" />
                </ExternalLink>
              </li>
              <li>
                {authState === AuthState.AUTHORIZED && match?.params.page === 'claim-nft' && (
                  <Link
                    to="/nft"
                    className="small bold"
                    onClick={(): void => {
                      dispatch(revokeAuthorization());
                    }}
                  >
                    <span>Log out</span>
                    <i className="revuto-icon icon-arrow-right" />
                  </Link>
                )}
              </li>
            </ul>

            <div className="mobile-menu" onClick={handleClick}>
              {click ? <i className="revuto-icon icon-cancel" /> : <i className="revuto-icon icon-menu" />}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
