import React from 'react';
import Carousel from 'react-multi-carousel';

import { RoadmapSection } from '../../components/RoadmapSection';

export const RoadmapContainer: React.FC = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1201 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 3,
      partialVisibilityGutter: 80,
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 2,
      partialVisibilityGutter: 80,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  return (
    <div id="roadmap" className="section-spacing">
      <h2 className="uppercase">roadmap</h2>
      <p className="light">Find out a detailed map of the development of our technology</p>
      <Carousel responsive={responsive} arrows={false} showDots={false} partialVisible={true}>
        <RoadmapSection
          period="Q1 - Q4"
          year="2022"
          events={[
            'App and services support for Cardano native tokens and stablecoins',
            'Revuto Staking Center supporting R Fund, farming, staking, investing, presales',
            'Adding liquidity on decentralized exchanges on Cardano',
            'EMI (Electronic Money Institution) license from Bank of Lithuania',
            'FIAT to Crypto on-ramp with Banxa',
            'R token and Revudex - announcement',
            'Revulution NFTs, Subscription NFTs, Rstronaut NFTs',
            'Revuto PDCs (Physical Debit Cards) - presale',
            'Subscription Management and Password Sharing services',
          ]}
        />
        <RoadmapSection
          period="Q1 - Q4"
          year="2023"
          events={[
            'Virtual Debit Cards launch (Mastercard card program)',
            'Revuto Custodial Wallet supporting multi-chain crypto assets',
            'Fiat-to-Crypto on-ramp for REVU and other CNTs',
            'Revuto NFT on-chain minting',
            'R Token TGE and ISPO',
            'Revuto Physical Debit Cards',
            'EURr stablecoin TGE (depending on the EU crypto legislation)',
          ]}
        />
        <RoadmapSection
          period="Q1 - Q4"
          year="2024"
          events={[
            'R Token airdrop, Revudex (depending on the market conditions)',
            'Stake to Subscribe',
            'Monetize unused subscriptions with Subscription NFTs',
            'DeFi setup for Micro-lending and borrowing (Credit Scoring)',
            'DeFi setup for "Buy Now, Pay Later" option for PDCs',
            'Open DeFi micro-lending & borrowing',
            'Opening MENA or other regions',
          ]}
        />
        <RoadmapSection
          period="Q1 - Q4"
          year="2025"
          events={[
            'Full decentralization, switching all on-chain (Hydra)',
            'Governance',
            'Support for Utility bills and other recurring  payments',
            'Payment tokenization - one debit card for all payments',
            'Subscribe with Revuto API',
            'Crypto wallet pre-authorization for pull payments',
            'Opening US market',
          ]}
        />
      </Carousel>
      <p className="light">
        *events may change according to development on the Cardano blockchain (functionality of Plutus smart contracts)
      </p>
    </div>
  );
};
