import { RootState } from '../store';
import { AuthState, ChangePasswordState, RegisterState, ResetPasswordState } from './slice';

export const getRegisterState = (state: RootState): RegisterState => state.auth.registerState;

export const getRegisterError = (state: RootState): string => {
  if (state.auth.registerError === 'body.country should be equal to one of the allowed values') {
    return 'Restricted country for participating in the token sale';
  }
  return state.auth.registerError;
};
export const getResetState = (state: RootState): ResetPasswordState => state.auth.resetState;
export const getResetError = (state: RootState): string => state.auth.resetError;
export const getChangePasswordState = (state: RootState): ChangePasswordState => state.auth.changePasswordState;
export const getChangePasswordError = (state: RootState): string => state.auth.changePasswordError;
export const getAuthState = (state: RootState): AuthState => state.auth.state;

export const getAuthError = (state: RootState): string => state.auth.error;

export const getAuthRoles = (state: RootState): string[] => state.auth.roles;

export type AuthData = {
  id: number;
  email: string;
  name: string;
  surname: string;
  address: string;
  applicantId: string | null;
};
export const getAuthData = (state: RootState): AuthData => {
  const { id, email, name, surname, address, applicantId } = state.auth;
  return { id, email, name, surname, address, applicantId };
};
