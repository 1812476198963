import React from 'react';
import { IconButton } from '../IconButton';

interface IFeatureProps {
  title: string;
  description: string;
  icon: string;
}

export const Feature: React.FC<IFeatureProps> = ({ title, description, icon }) => {
  return (
    <div className="feature">
      <IconButton flat large>
        <span className={`revuto-icon ${icon}`} />
      </IconButton>
      <div className="caption column">
        <p className="uppercase small bold">{title}</p>
        <p className="light small">{description}</p>
      </div>
    </div>
  );
};
