import React from 'react';

import { Partner } from '../../components/Partner';

export const MediaContainer: React.FC = () => {
  return (
    <div className="media-container section-spacing">
      <h2 className="uppercase">media</h2>
      <p className="light">Who is covering us</p>

      <div className="row evenly-xs">
        <Partner
          name="Cointelegraph"
          imageClassName="cointelegraph"
          link="https://cointelegraph.com/press-releases/-revuto-crypto-subscriptions-on-cardano"
        />
        <Partner
          name="Coindesk"
          imageClassName="coindesk"
          link="https://www.google.com/search?q=coindesk+revuto&oq=coindesk+revuto&aqs=chrome..69i57.2304j0j9&sourceid=chrome&ie=UTF-8"
        />
        <Partner
          name="Entrepreneur"
          imageClassName="entrepreneur"
          link="https://www.entrepreneur.com/en-gb/science-technology/how-europe-became-the-stage-for-a-fintech-revolution/390305"
        />
        <Partner
          name="Bloomberg"
          imageClassName="bloomb"
          link="https://www.bloomberg.com/press-releases/2021-08-16/revuto-introduces-their-flagship-product-revuto-app-an-active-subscription-management-solution"
        />
        <Partner
          name="Business Insider"
          imageClassName="business"
          link="https://markets.businessinsider.com/news/stocks/revuto-introduces-their-flagship-product---revuto-app-an-active-subscription-management-solution-1030734876"
        />
        <Partner
          name="Forbes"
          imageClassName="forbes"
          link="https://www.forbes.com/sites/kenrapoza/2022/01/17/the-rise-of-the-new-blockchains-where-are-investors-and-developers-turning/"
        />
        <Partner
          name="Yahoo Finance"
          imageClassName="yahoo"
          link="https://finance.yahoo.com/news/revuto-introduces-flagship-product-revuto-151300650.html"
        />
        <Partner name="CoinMarketCap" imageClassName="cmc" link="https://coinmarketcap.com/currencies/revuto/" />
        <Partner
          name="CoinQuora"
          imageClassName="coinquo"
          link="https://cryptocurrencytracker.info/et/crypto-news/279743/coinquora-exclusive-interview-vedran-vukman-ceo-revuto"
        />
        <Partner
          name="AMBCrypto"
          imageClassName="amb"
          link="https://ambcrypto.com/creating-a-subscription-management-solution-an-interview-with-the-ceo-coo-of-revuto/"
        />
        <Partner
          name="Bitcosar"
          imageClassName="bitcosar"
          link="https://bitcosar.com/cardano-blok-zincirindeki-ilk-token-olan-revuto-gate-ioda-listelenecek/"
        />
        <Partner
          name="CroBitcoin"
          imageClassName="HBP"
          link="https://crobitcoin.com/revuto-je-prvi-token-sale-na-cardano-platformi/"
        />
        <Partner
          name="Cardano Feed"
          imageClassName="cardanofeed"
          link="https://cardanofeed.com/buy-crypto-in-the-revuto-app"
        />
        <Partner
          name="Coin Bureau"
          imageClassName="coin_bureau"
          link="https://www.coinbureau.com/guides/top-cardano-projects-dapps/"
        />
        <Partner
          name="Nasdaq"
          imageClassName="nasdaq"
          link="https://www.nasdaq.com/articles/revuto-expands-nft-utility-to-consumer-centric-models-via-subscriptions"
        />
        <Partner
          name="Bloomberg Adria"
          imageClassName="bloomberg_adria"
          link="https://hr.bloombergadria.com/video/start/1187-vedran-vukman-i-aleksandar-matanovic-o-rizicima-ulaganja-u-kripto-valute/"
        />
      </div>
    </div>
  );
};
