import { combineReducers, createStore, applyMiddleware, compose, Store } from 'redux';
import reduxSaga from 'redux-saga';
import { createAction } from '@reduxjs/toolkit';

import { rootSaga } from './rootSaga';
import { authSlice } from './auth/slice';
import { depositSlice } from './deposit/slices';
import { statsSlice } from './stats/slices';
import { subscribeSlice } from './subscribe/slice';
import { KYCSlice } from './kyc/slice';
import { loaderSlice } from './loaders/slices';
import { nftSlice } from './nft/slice';

// state
export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  subscribe: subscribeSlice.reducer,
  deposit: depositSlice.reducer,
  stats: statsSlice.reducer,
  kyc: KYCSlice.reducer,
  loader: loaderSlice.reducer,
  nft: nftSlice.reducer,
});
export type RootState = ReturnType<typeof rootReducer>;

// middlewares
const sagaMiddleware = reduxSaga();
const middleware = [sagaMiddleware];

// store
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const storeCreator = (initialState?: Partial<RootState>): Store<RootState> =>
  createStore(
    rootReducer,
    { ...(initialState || {}) },
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(...middleware)
      : composeEnhancers(applyMiddleware(...middleware)),
  );
export const store = storeCreator();

// special action - sagas can hook on it to make special actions required for app (can be async)
export const postInit = createAction('@@POST_INIT');

sagaMiddleware.run(rootSaga);

store.dispatch(postInit());
