import React, { FC } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

interface IHandleAnchorLink {
  page?: string;
  anchorHref: string;
}

export const NavigationLink: FC<IHandleAnchorLink> = ({ children, page, anchorHref }) => {
  if (page === undefined) {
    return (
      <AnchorLink href={anchorHref} className="small bold">
        {children}
      </AnchorLink>
    );
  } else return <a>{children}</a>;
};
