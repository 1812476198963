import React from 'react';

import { ExternalLink } from '../../components/ExternalLink';
import { IconButton } from '../../components/IconButton';

export const SocialContainer: React.FC = () => {
  return (
    <div className="social-container section-spacing">
      <div className="row middle-xs between-md">
        <div className="col-xs-10 col-xs-offset-1 center-xs col-md-5 center-md">
          <h4 className="uppercase bold">follow us to catch the latest updates</h4>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="center-xs icons">
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-facebook light" href="https://www.facebook.com/revuto/" />
            </IconButton>
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-twitter light" href="https://twitter.com/get_revuto" />
            </IconButton>
            <IconButton flat>
              <ExternalLink
                className="revuto-icon icon-linkedin light"
                href="https://www.linkedin.com/company/revuto/"
              />
            </IconButton>
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-telegram light" href="https://t.me/revuto" />
            </IconButton>
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-discord light" href="https://discord.com/invite/CuCjteKmCk" />
            </IconButton>
          </div>
          <div className="center-xs icons">
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-reddit-alien light" href="https://www.reddit.com/r/Revuto/" />
            </IconButton>
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-instagram light" href="https://www.instagram.com/get_revuto" />
            </IconButton>
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-medium light" href="https://getrevuto.medium.com/" />
            </IconButton>
            <IconButton flat>
              <ExternalLink className="revuto-icon icon-tiktok light" href="https://vm.tiktok.com/ZMRYLYTd2/" />
            </IconButton>
            <IconButton flat>
              <ExternalLink
                className="revuto-icon icon-youtube-play light"
                href="https://www.youtube.com/c/RevutoOfficialChannel"
              />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
