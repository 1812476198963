import React from 'react';
export const CookiePolicyPage: React.FC = () => {
  return (
    <>
      <div className="page-wrapper wrapper plain-pages">
        <h1>Cookie Policy</h1>
        <p>Effective as of 28. April 2021.</p>
        <p>
          This Cookie Policy makes an integral part of our{' '}
          <a href="privacy-policy">
            <b>Privacy Policy</b>
          </a>{' '}
          and{' '}
          <a href="terms-of-use">
            <b>Terms of Use</b>
          </a>{' '}
          and explains how Revuto uses cookies. You can find out more about cookies and how to control them in the
          information below. Concepts written in capital letters in this Cookie Policy have the same meaning as defined
          in the{' '}
          <a href="privacy-policy">
            <b>Privacy Policy</b>
          </a>{' '}
          or in the{' '}
          <a href="terms-of-use">
            <b>Terms of Use</b>
          </a>
          .
        </p>

        <h4>1. WHAT IS A COOKIE?</h4>
        <p>
          Cookies are text files containing small amounts of information that are downloaded to your computer or mobile
          device when you visit a website. Cookies are then sent back to the originating website on each subsequent
          visit, or to another website that recognizes that cookie. Cookies are useful because they allow a website to
          recognize a user{`'`}s device.
        </p>
        <p>
          We use cookies to collect information about the use of the Website. The purpose of collecting information
          through cookies is not to identify the Website user, but to improve the Website{`'`}s content and user
          experience.
        </p>
        <h4>2. TYPES OF COOKIES</h4>
        <p>
          In general, there are three different ways to classify cookies: what purpose they serve, how long they endure,
          and their provenance.
        </p>
        <p>
          <b>Duration</b>
        </p>
        <p>
          Session cookies &ndash; These cookies are temporary and expire once you close your browser (or once your
          session ends).
        </p>
        <p>
          Persistent cookies &mdash; This category encompasses all cookies that remain on your hard drive until you
          erase them or your browser does, depending on the cookie&rsquo;s expiration date. All persistent cookies have
          an expiration date written into their code, but their duration can vary.&nbsp;
        </p>
        <p>
          <b>Provenance</b>
        </p>
        <p>
          First-party cookies &mdash; As the name implies, first-party cookies are put on your device directly by the
          Revuto.
        </p>
        <p>
          Third-party cookies &mdash; These are the cookies that are placed on your device, not by the Revuto, but by a
          third party like an advertiser or an analytic system. ● You should consult the relevant privacy policy of
          these third parties. If you are residing in the European Union, you can find out more about how third parties
          use these cookies on the following link:
          <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>.
        </p>
        <p>
          Information on whether these are first-party or third-party cookies please check the category
          &lsquo;provider&rsquo; below.
        </p>
        <p>
          <b>Purpose</b>
        </p>
        <p>There are several types of cookies Revuto App and Website use, which have a different purpose:</p>
        <h4>2.1 STRICTLY NECESSARY COOKIES</h4>
        <p>
          Strictly necessary cookies help make Revuto App and Website usable by enabling basic functions like page
          navigation and access to secure areas of Revuto App and Website. Therefore, Revuto App and Website cannot
          function properly without these cookies.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>COOKIE NAME</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PURPOSE</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PROVIDER</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>EXPIRY</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>TYPE</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>__cfduid</p>
              </td>
              <td>
                <p>
                  Cookie associated with sites using CloudFlare, used to speed up page load times. According to
                  CloudFlare it is used to override any security restrictions based on the IP address the visitor is
                  coming from. It does not contain any user identification information.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>29 days</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>2.2 PREFERENCE COOKIES</h4>
        <p>
          Preference cookies enable websites remember information that changes the way the websites behave or look, like
          your preferred language or the region that you are in.
        </p>
        <p>Revuto currently uses no present preference cookies.</p>
        <h4>2.3 STATISTIC COOKIES</h4>
        <p>
          Statistic cookies help us understand how visitors interact with the Revuto App and Website by collecting and
          reporting information anonymously. These cookies allow us to count visits and traffic sources so that we can
          measure and improve the performance of Revuto App and Website. They help us know which pages are the most and
          the least popular and see how visitors move around the Revuto App and Website. All information collected by
          these cookies is collected anonymously. If these cookies are blocked, we will not know when someone visits the
          Revuto App and Website.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>COOKIE NAME</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PURPOSE</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PROVIDER</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>EXPIRY</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>TYPE</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_ga</p>
              </td>
              <td>
                <p>
                  Registers a unique ID that is used to generate statistical data on how the visitor uses the website.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>2 years</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_gat</p>
              </td>
              <td>
                <p>Used by Google Analytics to throttle request rate.</p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>1 day</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_gid</p>
              </td>
              <td>
                <p>
                  Registers a unique ID that is used to generate statistical data on how the visitor uses the website.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>1 day</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_gat_UA-179206244-1</p>
              </td>
              <td>
                <p>
                  This is a pattern type cookie set by Google Analytics, where the pattern element on the name contains
                  the unique identity number of the account or website it relates to. It appears to be a variation of
                  the _gat cookie which is used to limit the amount of data recorded by Google on high traffic volume
                  websites.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>Session</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_hjAbsoluteSessionInProgress</p>
              </td>
              <td>
                <p>
                  This cookie is used to detect the first pageview session of a user. This is a True/False flag set by
                  the cookie.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>30 minutes</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_hjFirstSeen</p>
              </td>
              <td>
                <p>
                  This is set to identify a new user&rsquo;s first session. It stores a true/false value, indicating
                  whether this was the first time Hotjar saw this user. It is used by Recording filters to identify new
                  user sessions.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>1 day</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_hjid</p>
              </td>
              <td>
                <p>
                  Hotjar cookie that is set when the customer first lands on a page with the Hotjar script. It is used
                  to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in
                  subsequent visits to the same site will be attributed to the same user ID.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>1 year</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_hjIncludedInPageviewSample</p>
              </td>
              <td>
                <p>
                  This cookie is set to let Hotjar know whether that visitor is included in the data sampling defined by
                  your site{`'`}s pageview limit.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>30 minutes</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>YSC</p>
              </td>
              <td>
                <p>This cookie is set by YouTube to track views of embedded videos.</p>
              </td>
              <td>
                <p>youtube.com</p>
              </td>
              <td>
                <p>Session</p>
              </td>
              <td>
                <p>HTTPS</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>2.4 MARKETING COOKIES</h4>
        <p>
          Marketing cookies help us provide you with good user experience, tailored content and allow us to continually
          improve Revuto App and Website. Marketing cookies are set up through our location and set by our advertising
          partners. They can use these companies to create a profile of your interests and show you relevant
          advertisements in other locations. They work by uniquely identifying your browser and device. If you do not
          allow these cookies, you will not experience our targeted ads on other websites.
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>COOKIE NAME</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PURPOSE</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PROVIDER</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>EXPIRY</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>TYPE</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_fbp</p>
              </td>
              <td>
                <p>
                  Used by Facebook to deliver a series of advertisement products such as real time bidding from third
                  party advertisers.
                </p>
              </td>
              <td>
                <p>revuto.com</p>
              </td>
              <td>
                <p>3 months</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>ads/ga-audiences</p>
              </td>
              <td>
                <p>
                  Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the
                  visitor{`'`}s online behaviour across websites.
                </p>
              </td>
              <td>
                <p>google.com</p>
              </td>
              <td>
                <p>Session</p>
              </td>
              <td>
                <p>Pixel</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>fr</p>
              </td>
              <td>
                <p>
                  Used by Facebook to deliver a series of advertisement products such as real time bidding from third
                  party advertisers.
                </p>
              </td>
              <td>
                <p>facebook.com</p>
              </td>
              <td>
                <p>3 months</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>tr</p>
              </td>
              <td>
                <p>
                  Used by Facebook to deliver a series of advertisement products such as real time bidding from third
                  party advertisers.
                </p>
              </td>
              <td>
                <p>facebook.com</p>
              </td>
              <td>
                <p>Session</p>
              </td>
              <td>
                <p>Pixel</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>VISITOR_INFO1_LIVE</p>
              </td>
              <td>
                <p>
                  This cookie is set by Youtube to keep track of user preferences for Youtube videos embedded in
                  sites;it can also determine whether the website visitor is using the new or old version of the Youtube
                  interface.
                </p>
              </td>
              <td>
                <p>youtube.com</p>
              </td>
              <td>
                <p>6 months</p>
              </td>
              <td>
                <p>HTTPS</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CONSENT</p>
              </td>
              <td>
                <p>
                  This cookie carries out information about how the end user uses the website and any advertising that
                  the end user may have seen before visiting the said website.
                </p>
              </td>
              <td>
                <p>youtube.com</p>
              </td>
              <td>
                <p>16 years</p>
              </td>
              <td>
                <p>HTTPS</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>test_cookie</p>
              </td>
              <td>
                <p>
                  This cookie is set by DoubleClick (which is owned by Google) to determine if the website visitor{`'`}s
                  browser supports cookies.
                </p>
              </td>
              <td>
                <p>doubleclick.net</p>
              </td>
              <td>
                <p>1 day</p>
              </td>
              <td>
                <p>HTTP</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>3. HOW TO BLOCK COOKIES?</h4>
        <p>
          You can change your cookie preferences at any time by clicking on the &lsquo;Cookie preferences&rsquo; icon.
          You can then adjust the available sliders/checkboxes to &lsquo;On&rsquo; or &lsquo;Off&rsquo;, then clicking
          &lsquo;Save and close&rsquo;. You may need to refresh your page for your settings to take effect.
        </p>
        <p>
          If you wish to disable cookies, you may also use your browser settings. Namely, most internet browsers are set
          up by default to accept cookies. However, if you want to refuse or delete them (or similar technologies)
          please refer to the help and support area on your browser for instructions on how to block or delete cookies
          (for example{' '}
          <a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
            Internet Explorer
          </a>
          ,{' '}
          <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en">
            Google Chrome
          </a>
          ,{' '}
          <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US">
            Mozilla Firefox
          </a>
          , and <a href="https://support.apple.com/en-gb/safari">Apple Safari</a>). Keep in mind that each browser has a
          different procedure for managing and configuring cookies. However, if you use your browser settings to block
          all cookies (including necessary cookies) you may not be able to access all or some parts of RevutoApp and
          Website.
        </p>
        <h4>4. CHANGES TO OUR COOKIE POLICY</h4>
        <p>This Cookie Policy may be updated from time to time.</p>
        <p>
          If we change anything important about this Cookie Policy, we will notify you through a pop-up on the RevutoApp
          and Website in a reasonable time period prior to and following the change.
        </p>
      </div>
    </>
  );
};
