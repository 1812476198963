import { AxiosResponse } from 'axios';
import { CreateKYCDto, ShowKYCDto } from '../types/dto/KYC.dto';
import { instance } from './private';

export const createKYC = (): Promise<AxiosResponse<CreateKYCDto>> => {
  return instance.post('/v1/kyc/access-token');
};

export const getKYCUser = (id: string): Promise<AxiosResponse<ShowKYCDto>> =>
  instance.get('/v1/kyc/status', { params: { applicantId: id } });
