import { createAction } from '@reduxjs/toolkit';
import { ISubscriptionFormData } from '../../types/dto/PublicData.dto';
import { subscribeSlice } from './slice';

export const { setSubModalVisible } = subscribeSlice.actions;

export type SubmitSubAction = (
  subFormData: ISubscriptionFormData,
  onComplete: (message: string, details?: string) => void,
  recaptcha: string,
) => {
  payload: { subFormData: ISubscriptionFormData; recaptcha: string };
  meta: (message: string, details?: string) => void;
};

export const submitSubscription = createAction<SubmitSubAction>(
  'subscribe/submit_subscription',
  (subFormData, meta, recaptcha) => ({
    payload: { subFormData, recaptcha },
    meta,
  }),
);
