import React from 'react';
import classNames from 'classnames';

interface IDarkBackgroundProps {
  children: React.ReactElement;
  className?: string;
}

export const DarkBackground: React.FC<IDarkBackgroundProps> = ({ className, children }) => {
  return <div className={classNames('dark-background', className)}>{children}</div>;
};
