import React from 'react';

import { Member } from '../../components/Member';
import Josipa from '../../assets/images/team/josipa-maic.jpeg';
import Dorijan from '../../assets/images/team/dorijan-Jelincic.jpeg';
import Domagoj from '../../assets/images/team/domagoj-praljak.jpeg';
import Boris from '../../assets/images/team/boris_juric.jpg';
import Igor from '../../assets/images/team/igor-kvrgic.jpeg';
import Vedran from '../../assets/images/team/vedran-vukman.jpeg';
import Glibo from '../../assets/images/team/kristijan-glibo.jpeg';
import Krunoslav from '../../assets/images/team/krunoslav-gaspert.jpeg';
import Klancir from '../../assets/images/team/luka-klancir.jpeg';
import Marko from '../../assets/images/team/marko-rukonic.jpeg';
import Vidakovic from '../../assets/images/team/petar-vidakovic.jpeg';
import Reil from '../../assets/images/team/tomislav-reil.png';
import Vukasin from '../../assets/images/team/vukoje-vukasin.jpeg';
import Nemanja from '../../assets/images/team/nemanja-zunic.jpeg';
import Fruk from '../../assets/images/team/mladen-fruk.jpeg';
import Andrea from '../../assets/images/team/andrea-sagud.jpeg';
import GDuric from '../../assets/images/team/gduric.jpg';
// import Danny from '../../assets/images/team/danny.jpeg';
// import Mario from '../../assets/images/team/mario-rubinic.jpg';
import Sanja from '../../assets/images/team/sanja-zeljkovic.jpg';
import Sakar from '../../assets/images/team/andro_sakar.jpg';
import Loga from '../../assets/images/team/daniela_loga.jpg';
import Mlinaric from '../../assets/images/team/zvonimir_mlinaric.jpg';
import Ivankovic from '../../assets/images/team/dino_ivankovic.jpg';
import Zubcic from '../../assets/images/team/marko_zubcic.jpg';
import Blagojevic from '../../assets/images/team/blagojevic.jpg';
import Koprivic from '../../assets/images/team/koprivic.jpg';

export const TeamContainer: React.FC = () => {
  return (
    <div id="team" className="team-container section-spacing">
      <h2 className="uppercase">our team</h2>
      <p className="light">
        Revuto&apos;s team consists of financial and crypto industry experts, international serial entrepreneurs, and
        Silicon Valley advisors.
      </p>

      <div className="row center-xs center-md center-lg">
        <Member
          name="Vedran Vukman"
          position="CEO"
          image={Vedran}
          link="https://www.linkedin.com/in/vedranvukman/"
          description="Serial entrepreneur and founder of Vyoocam, a wearable communication device built to support in-field services. Vedran has international experience of product development and product launch in Silicon Valley. Vedran is an early crypto adopter, investor in several crypto projects, and a big supporter of Cardano. Member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Josipa Majic"
          position="Strategy and Communications"
          image={Josipa}
          link="https://www.linkedin.com/in/josipamajic/"
          description="Founder of the biometric and behavioral company Tacit, doing consumer research with AI emotional analytics tools for Fortune 100s and the banking sector, giving them a unique insight into how people truly feel, not necessarily the reaction they want to portray. Alpen-Adria Universitat, Wien-Graz, Business Administration, Faculty of Business and Economics, University of Zagreb, London School of Public Relations, a member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Marko Rukonic"
          position="CTO"
          image={Marko}
          link="https://www.linkedin.com/in/marko-rukonic/"
          description="Fintech veteran, spent more than 20 years in Silicon Valley developing financial apps like Quicken, Mint, and QuickBooks for intuit, author and co-author of 27 patents related to artificial intelligence (AI) and financial transactions, recently published in the most prestigious AI magazine in the world. AI magazine is a publication of AAAI, the world’s most respected organization for artificial intelligence. Faculty of Electrical Engineering and Computing, University of Zagreb, a member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Boris Juric"
          position="COO"
          image={Boris}
          link="https://www.linkedin.com/in/borisjuricnd/"
          description="A veteran of several industries, from technology and services to consumer electronics, working in top management positions, associated with industry giants like Apple, Motorola, Maxim Integrated, and KLM. 10+ years in Silicon Valley, primarily focused on several projects connected to Neuroscience, creating new categories in the market. Neurosky, Choose Muse, Thync, Teddy the Guardian, and Tacit among others. Love for the startup world and expertise in growing companies in Silicon Valley came from the passion for technology’s ability to revolutionize industries and ultimately change people’s lives. The University of Notre Dame, Mendoza School of Business. Member of Notre Dame Deloitte Center for Ethical Leadership; Member of Irish Angels Investment Group."
        />
        <Member
          name="Dorijan Jelincic"
          position="CTiO / Blockchain"
          image={Dorijan}
          link="https://www.linkedin.com/in/dorijan-jelin%C4%8Di%C4%87-099b6725/"
          description="Blockchain developer, functional programming in Haskell. Dorijan was involved in developing several blockchain products, the latest one was Odee, a decentralized blockchain exchange on ZRX. University Of Zagreb, Automatics, a member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Dino Ivanković"
          position="Business Development and Partnerships"
          image={Ivankovic}
          link="https://linkedin.com/in/dino-ivanković-0976871a2"
          description="Crypto and Blockchain enthusiast since 2017. Former regional telecommunications manager of major companies. Working on Revuto project expansion across the globe. Skilled in business development, international team management and sales performance."
        />
        <Member
          name="Daniela Loga"
          position="Marketing"
          image={Loga}
          link="https://www.linkedin.com/in/dloga/"
          description="Highly experienced international media, communications, business, and economy specialist. A native of Silicon Beach and proud alumna of both UCLA (BA: Slavic Languages and Literatures) and St. Petersburg State University (MA: International Relations), she has an interest in and commitment to fast-paced projects and output as well as a fine eye for detail and understanding of market dynamics."
        />
        <Member
          name="Andro Sakar"
          position="Community management"
          image={Sakar}
          link="https://www.linkedin.com/in/andro-sakar/"
          description="University of Zagreb, Faculty of Business and Economic, mag.oec. Also ACCA affiliate. Involved in crypto since 2017, investor in real estate, fx, crypto and stock markets. Forensic accountant and auditor of financial statments with interest in information systems and technology."
        />
        <Member
          name="Filip Blagojević"
          position="Project Manager at R Fund"
          image={Blagojevic}
          link="https://hr.linkedin.com/in/filipblagojevic"
          description="Blockchain consultant skilled in Cardano infrastructure and processes. Active in the Cardano community helping to build the governance layer since 2020. Haskell enthusiast and Plutus Pioneer."
        />
        <Member
          name="Zvonimir Mlinarić"
          position="Project Manager at R Fund"
          image={Mlinaric}
          link="https://www.linkedin.com/in/zvonimirmlinaric/"
          description="Blockchain enthusiast and Cardano stake pool operator since 2017. Interested in DeFi, investing and fintech. Experienced Cardano community member. Co-author of several research papers. Member of several expert associations and societies. Analytical scientist, Master of Pharmacy and PhD student at University of Zagreb."
        />
        <Member
          name="Goran Đurić"
          position="Backend developer"
          image={GDuric}
          link="https://www.linkedin.com/in/gd1337/"
          description="Enthusiastic and proactive IT technician with years of quality experience.  Goran has very strong programming skills which are demonstrated in his work ethic that is dedicated to helping others, fixing things, automating tasks, and solving problems that cross his path."
        />
        {/* <Member
          name="Mario Rubinić"
          position="CFO"
          image={Mario}
          link="https://www.linkedin.com/in/mario-rubini%C4%87-1a97704b/"
          description="University of applied sciences Baltazar Zaprešić. Twenty years of experience in banking and ICT business. Former Quality Assurance expert. An early crypto adopter and supporter of Cardano."
        /> */}
        <Member
          name="Marko Zubčić"
          position="DeFi &amp; Social Media"
          image={Zubcic}
          link="https://www.linkedin.com/in/marko-zub%C4%8Di%C4%87-2403bb114/"
          description="Intense interest and deep technical understanding of blockchain and DeFi technology (Decentralised Finance). Skilled in market research and data analysis with the ability and knowledge needed to identify new attractive opportunities for capturing new users, interpret data and conduct research for trending topics in the cryptocurrency industry in order to anticipate potential risks and essentially contribute to the project’s growth. Extensive knowledge and understanding of capital markets and financial instruments including equities, derivatives, and cryptocurrency. With 10+ years experience in the traditional financial markets, and 5 years exploring blockchain technology and cryptocurrencies."
        />
        <Member
          name="Marko Koprivić"
          position="Support"
          image={Koprivic}
          link="#"
          description="Awarded former Senior Contact Center Banker and International Clients banker at Zagrebačka banka (Unicredit Group). Worked occasionally as a QA specialist on mobile banking products. As a Partner Operations Manager at Settle, lead the team that introduced the first mobile wallet to feature merchant payments in Croatia. Later on, gained great experience in leading the startup subsidiary as an Acting Country Manager. Crypto enthusiasm became his full-time job after joining Revuto as a Community Manager. Unlocked his full potential after being promoted to his current role as Head of Customer Support. His goal with Revuto is to set industry standards and provide best-in-class customer service."
        />
        <Member
          name="Andrea Šagud"
          position="PR"
          image={Andrea}
          link="https://www.linkedin.com/in/andrea-%C5%A1agud-63a7b7203/"
          description="M.Sc. at University of Zagreb, Faculty of Agriculture. Experienced community manager, marketing and PR assistant. Experience includes working at a London based startup with multinational B2B customers and consumer brands in CE Europe."
        />
        <Member
          name="Sanja Željković"
          position="Compliance &amp; AML"
          image={Sanja}
          link="https://www.linkedin.com/in/sanjazeljkovic/"
          description="Faculty of Science of the University of Zagreb. Experience through multiple projects in the IT and financial industry in Croatia. Fraud and ML detection systems expert. Covering traditional banking, card, electronic and cryptocurrency transactions and payments."
        />
        <Member
          name="Vukasin Vukoje"
          position="Advisor / Blockchain"
          image={Vukasin}
          link="https://www.linkedin.com/in/vvkio/"
          description="Leading product for Cardano Smart Contracts (Haskell developer), Native Tokens, Ethereum Token bridge, and sidechains. Founder of Tenderly, a monitoring platform for Ethereum Smart Contracts that empowers users with real-time EVM level insights for every transaction on the network. Advisor and investor in Revuto."
        />
        <Member
          name="Luka Klancir"
          position="Advisor / Blockchain"
          image={Klancir}
          link="https://www.linkedin.com/in/lklancir/"
          description="Croatia's 30 under 30 selected by VIDI Magazine. Founder of Croatian Bitcoin Portal focused on bringing information, news, statistics, and analysis from the field of digital cryptocurrencies and underlying blockchain technology. Founder of Async Labs, a digital agency with a focus on blockchain technology, software development, and pushing products to the market. Univ. Bacc. Inf. Karl Franzens-Universitat-Graz, board member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Igor Kvrgic"
          position="CISO"
          image={Igor}
          link="https://www.linkedin.com/in/ikvrgic/"
          description="Working as a CISO in Revuto to make sure Revuto excels in cybersecurity. Igor aims for the highest standards when it comes to encryption and authentication, along with continuous diligence towards the protection of customer's data. Started with working 5 years for IBM on their WebSphere portfolio, later involved in developing high profile eGovernment solutions, a wide range of tech, from Oracle, VMWare, and Microsoft to Machine learning and BI ETLs. BE, Information Technology, Singidunum University."
        />
        <Member
          name="Tomislav Reil"
          position="Advisor / Blockchain"
          image={Reil}
          link="https://www.linkedin.com/in/tomislavreil/"
          description="Stanford University, Adv. Project Mng. MBA at Hult International Business School, Harvard Extension School, Strategic Mng at the University of Zagreb, Faculty of Informatics, Bachelor of Science, IT (Business Systems), worked at U.S. Bank in San Francisco, Group Engineering Manager, Assistant VP. Responsive Web Page Performance Optimization, Web Security, Online Banking Systems, Money Movement. Tomislav led the U.S. Bank Web Platform in achieving the highest Mobile Financial Services Index within the US. Tomislav is a big crypto enthusiast, blockchain developer, and investor in Revuto. "
        />
        <Member
          name="Kristijan Glibo"
          position="Advisor / Blockchain"
          image={Glibo}
          link="https://www.linkedin.com/in/kristijan-glibo/"
          description="A blockchain engineer focused on developing decentralized manageable processes. Founder of Orcain, a blockchain orchestration platform as a Service, Product Designer, Chief Researcher. Advisory board member at UBIK - Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Krunoslav Gaspert"
          position="Product"
          image={Krunoslav}
          link="https://www.linkedin.com/in/kgaspert/"
          description="University of applied sciences VERN, former product manager at Erste Card Club d.d., former country manager at U-Turn Tax Refund. An early crypto adopter and supporter of Cardano. Member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Domagoj Praljak"
          position="Payments"
          image={Domagoj}
          link="https://www.linkedin.com/in/domagoj-praljak-5553111b/"
          description="Payments consultant, experienced Sales Manager with a demonstrated history of working in the financial services industry. Skilled in Marketing Management, Negotiation, Business Planning, Analytical Skills, and Coaching. Former Key Account Manager and Specialist for e-commerce at Erste Card Club d.d.  Strong sales professional with a Master of Arts (M.A.) focused in Project Management. BA Economics at the University of Zagreb, Master of Arts, Process Management."
        />
        <Member
          name="Nemanja Zunic"
          position="Legal"
          image={Nemanja}
          link="https://www.linkedin.com/in/nemanja-zunic/"
          description="Partner at Zunic Law Firm for IT and Intellectual Property. While in London, developed niche expertise within the IT sector by advising a variety of technology enterprises from start-ups to established IT companies. Roles and responsibilities include technology joint venture and spin-off agreements, IT investment agreements, commercial and tax-related legal issues regarding cryptocurrency transactions, and ICO legal support. UCL, Master’s Degree in IP-IT Law, University of Novi Sad, Faculty of Law, Master’s Degree, International Law, and Legal Studies."
        />
        <Member
          name="Petar Vidakovic"
          position="Advisor"
          image={Vidakovic}
          link="https://www.linkedin.com/in/petarv/"
          description="University of Cambridge, MBA, Hult International Business School, Faculty of Electrical Engineering and Computing, University of Zagreb, former Project Manager at Raiffeisen Bank International AG. Member at UBIK, Croatian Union for Blockchain & Cryptocurrencies."
        />
        <Member
          name="Mladen Fruk"
          position="Advisor"
          image={Fruk}
          link="https://www.linkedin.com/in/mladenfruk/"
          description="12 years Partner at McKinsey & Company, ex Associate at KPMG from Jun 2006 to Jan 2009, MSc Economics and Finance at Universitat Pompeu Fabra, BA Economics at University of Zagreb, investor and advisor at Revuto."
        />
        {/* <Member
          name="Danny Heng Zhang Hopking"
          position="Advisor / Marketing"
          image={Danny}
          link="https://tr.linkedin.com/in/littledannyhopkins"
          description="Crypto enthusiastic investor and strategy marketer, specializes in crypto startups marketing development in China. Co-founder of Shangai AiLian Technology Co.,LTD."
        /> */}
      </div>
    </div>
  );
};
