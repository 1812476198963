import React, { FC } from 'react';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { AboutContainer } from '../../containers/About';
import { DappContainer } from '../../containers/Dapp';
import { HeroContainer } from '../../containers/Hero';
import { TokenContainer } from '../../containers/Token';
import { DeFiContainer } from '../../containers/DeFi';
import { CommunityContainer } from '../../containers/Community';
import { PartnershipContainer } from '../../containers/Partnership';
import { MediaContainer } from '../../containers/Media';
import { TeamContainer } from '../../containers/Team';
import { RoadmapContainer } from '../../containers/Roadmap';
import { SocialContainer } from '../../containers/Social';
import { CTAContainer } from '../../containers/CTA';
import { SubscriptionForm } from '../../containers/SubscriptionForm';

export const HomePage: FC = () => {
  return (
    <>
      <div className="solid-background">
        <Header />
        <HeroContainer />
      </div>
      <CTAContainer />
      <div className="page-wrapper wrapper">
        <AboutContainer />
      </div>

      <div className="gradient-background">
        <div className="page-wrapper wrapper">
          <DappContainer />
        </div>
      </div>

      <div className="page-wrapper wrapper">
        <TokenContainer />
      </div>

      <div className="split-background-color">
        <div className="page-wrapper wrapper split-background-color">
          <DeFiContainer />
        </div>
      </div>

      <div className="gradient-background">
        <div className="page-wrapper wrapper">
          <CommunityContainer />
        </div>
      </div>

      <div className="landing-bottom-section page-wrapper wrapper">
        <PartnershipContainer />
        <MediaContainer />
        <TeamContainer />
        <RoadmapContainer />
        <CTAContainer />
        <SocialContainer />
        <SubscriptionForm />
      </div>
      <Footer />
    </>
  );
};
