import { all, call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  fetchUnclaimedNFTs,
  storeUnclaimedNFTs,
  fetchBuyerNFTClaim,
  storeBuyerNFTClaim,
  storeRandomSpinnerNFTs,
  getRandomNFTs,
  getRandomNFTsForSequentSpin,
  requestNFTClaim,
  storeAllNFTs,
  fetchNFTs,
} from './actions';
import { claimNFT, fetchAllNFTs, fetchAllUnclaimedNFTs, getBuyerNFTClaim } from '../../services/NFT';
import { BuyerNFTClaim, NFT, NFTClaimResponse } from '../../types/dto/NFT';
import { selectRandomNFTs, selectUnclaimedNFTs } from './selectors';
import { shuffleArray } from '../../utils/array';

function* randomNFTs(): Generator<SelectEffect | PutEffect, void, NFT[]> {
  try {
    const allNFTs: NFT[] = yield select(selectUnclaimedNFTs);
    const randomNFTs = [...new Array(10)].map(() => allNFTs[Math.round(Math.random() * (allNFTs.length - 1))]);
    yield put(storeRandomSpinnerNFTs(randomNFTs));
  } catch (err) {
    console.error(err);
  }
}

function* sequentSpinNFTs(): Generator<
  CallEffect | SelectEffect | PutEffect,
  void,
  AxiosResponse<NFT[]> & { spinner: NFT[]; winner: NFT }
> {
  try {
    const response: AxiosResponse<NFT[]> = yield call(fetchAllUnclaimedNFTs);
    const allNFTs = response.data;
    const spinnerNFTs: { spinner: NFT[]; winner: NFT } = yield select(selectRandomNFTs);
    const newRandomNFTs = [...new Array(9)].map(() => allNFTs[Math.round(Math.random() * (allNFTs.length - 1))]);
    yield put(storeRandomSpinnerNFTs([spinnerNFTs.spinner[0], ...newRandomNFTs]));
  } catch (err) {
    console.error(err);
  }
}

function* getUnclaimedNFTs(): Generator<CallEffect | PutEffect, void, AxiosResponse<NFT[]>> {
  try {
    const response: AxiosResponse<NFT[]> = yield call(fetchAllUnclaimedNFTs);
    yield put(storeUnclaimedNFTs(response.data));
    yield put(getRandomNFTs());
  } catch (err) {
    console.error(err);
  }
}

function* getAllNFTs(): Generator<CallEffect | PutEffect, void, AxiosResponse<NFT[]>> {
  try {
    const response: AxiosResponse<NFT[]> = yield call(fetchAllNFTs);
    const shuffledNFTs = shuffleArray<NFT>(response.data);
    yield put(storeAllNFTs(shuffledNFTs));
  } catch (err) {
    console.error(err);
  }
}

function* buyerNFTClaim(): Generator<CallEffect | PutEffect, void, AxiosResponse<BuyerNFTClaim>> {
  try {
    const response: AxiosResponse<BuyerNFTClaim> = yield call(getBuyerNFTClaim);
    yield put(storeBuyerNFTClaim(response.data));
  } catch (err) {
    console.error(err);
  }
}

function* claimingNFT({
  payload,
  meta,
}: ReturnType<typeof requestNFTClaim>): Generator<CallEffect, void, AxiosResponse<NFTClaimResponse>> {
  try {
    const response = yield call(claimNFT, payload);
    if (response.status === 200) {
      meta({
        type: 'success',
        message: 'NFT is successfully claimed',
      });
    } else throw Error();
  } catch (err) {
    console.error(err);
    meta({
      type: 'failure',
      message: `Failed to claim NFT: ${err.response.data.message}`,
    });
  }
}

export function* nftSagaWatcher(): Generator {
  yield all([
    takeEvery(fetchNFTs, getAllNFTs),
    takeEvery(fetchUnclaimedNFTs, getUnclaimedNFTs),
    takeEvery(fetchBuyerNFTClaim, buyerNFTClaim),
    takeEvery(getRandomNFTs, randomNFTs),
    takeEvery(getRandomNFTsForSequentSpin, sequentSpinNFTs),
    takeEvery(requestNFTClaim, claimingNFT),
  ]);
}
