import React from 'react';
import classNames from 'classnames';

interface IIconButtonProps {
  children: React.ReactElement;
  flat?: boolean;
  large?: boolean;
  className?: string;
  onClick?: () => void;
}

export const IconButton: React.FC<IIconButtonProps> = ({ className, large, flat, children, onClick }) => {
  return (
    <button onClick={onClick} className={classNames('icon-rounded', { flat, large }, className)}>
      {children}
    </button>
  );
};
