import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AuthState {
  INITIALIZATION = 'initialization',
  AUTHORIZED = 'authorized',
  GUEST = 'guest',
}

export enum ResetPasswordState {
  NULL = 'null',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum ChangePasswordState {
  NULL = 'null',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum RegisterState {
  NULL = 'null',
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface AuthReducerState {
  state: AuthState;
  registerState: RegisterState;
  registerError: string;
  resetState: ResetPasswordState;
  resetError: string;
  changePasswordState: ChangePasswordState;
  changePasswordError: string;
  error: string;
  token: null | string;
  roles: string[];
  id: number;
  email: string;
  name: string;
  surname: string;
  address: string;
  applicantId: string | null;
}

const initialState: AuthReducerState = {
  state: AuthState.INITIALIZATION,
  registerState: RegisterState.NULL,
  registerError: '',
  resetState: ResetPasswordState.NULL,
  resetError: '',
  changePasswordState: ChangePasswordState.NULL,
  changePasswordError: '',
  error: '',
  token: null,
  roles: [],
  name: '',
  surname: '',
  address: '',
  applicantId: null,
  id: 0,
  email: '',
};

interface StoreAuthorizationPayload {
  token: string;
  roles: string[];
  name: string;
  surname: string;
  address: string;
  applicantId: string | null;
  id: number;
  email: string;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorizeState: (state, action: PayloadAction<AuthState>): void => {
      state.state = action.payload;
    },
    setAuthorizationError: (state, action: PayloadAction<string>): void => {
      state.error = action.payload;
    },
    addAuthorizationRole: (state, action: PayloadAction<string>): void => {
      state.roles.push(action.payload);
    },
    setRegisterState: (state, action: PayloadAction<RegisterState>): void => {
      state.registerState = action.payload;
    },
    setRegisterError: (state, action: PayloadAction<string>): void => {
      state.registerError = action.payload;
    },
    setResetPasswordState: (state, action: PayloadAction<ResetPasswordState>): void => {
      state.resetState = action.payload;
    },
    setResetPasswordError: (state, action: PayloadAction<string>): void => {
      state.resetError = action.payload;
    },
    setChangePasswordState: (state, action: PayloadAction<ChangePasswordState>): void => {
      state.changePasswordState = action.payload;
    },
    setChangePasswordError: (state, action: PayloadAction<string>): void => {
      state.changePasswordError = action.payload;
    },
    storeAuthorization: {
      prepare: (
        token: string,
        roles: string[],
        name: string,
        surname: string,
        applicantId: string | null,
        id: number,
        email: string,
        address: string,
      ): { payload: StoreAuthorizationPayload } => ({
        payload: { token, roles, name, surname, address, applicantId, id, email },
      }),
      reducer: (state, action: PayloadAction<StoreAuthorizationPayload>): void => {
        state.state = AuthState.AUTHORIZED;
        state.roles = action.payload.roles;
        state.token = action.payload.token;
        state.name = action.payload.name;
        state.surname = action.payload.surname;
        state.address = action.payload.address;
        state.applicantId = action.payload.applicantId;
        state.id = action.payload.id;
        state.email = action.payload.email;
      },
    },
    setAddress: (sate, action: PayloadAction<string>): void => {
      sate.address = action.payload;
    },
    removeAuthorization: (state): void => {
      state.state = AuthState.GUEST;
      state.roles = [];
      state.token = null;
      state.name = '';
      state.surname = '';
    },
  },
});
