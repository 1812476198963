import React from 'react';
import GooglePlay from '../../assets/images/GooglePlay.svg';
import AppStore from '../../assets/images/AppStore.svg';
import AppGallery from '../../assets/images/appgallery.png';
import Kucoin from '../../assets/images/kucoin.png';
import Gateio from '../../assets/images/gateio.png';
import { ExternalLink } from '../../components/ExternalLink';
import { Link } from 'react-router-dom';

export const CTAContainer: React.FC = () => {
  return (
    <div className="wrapper">
      <div className="cta-container">
        <span className="vertical-separator"></span>
        <div>
          <h3>Reserve your spot in history by participating in the first equity fundraise on Bitcoin network.</h3>
          <Link to="/btc" className="buy-ordinals">
            Buy ordinals
          </Link>
          <p>
            <small>
              <strong>REVU can be purchased on the following tier 1 crypto exchanges:</strong>
            </small>
          </p>
          <div className="mobile-links">
            <ExternalLink href="https://trade.kucoin.com/trade/REVU-USDT">
              <img src={Kucoin} alt="Kucoin" width="150" height="50" />
            </ExternalLink>
            <ExternalLink href="https://www.gate.io/trade/REVU_USDT">
              <img src={Gateio} alt="Gate.io" width="150" height="50" />
            </ExternalLink>
          </div>
        </div>
        <div className="widget-app">
          {/* <h4>Live Revu price</h4> */}
          {/* <div id="crypto-widget-CoinList" data-transparent="true" data-design="modern" data-coin-ids="9637"></div> */}
          <h4>Download the Revuto app now:</h4>
          {/* <p className="v-hidden">
            <small>
              <strong>Our App is available on all majore stores:</strong>
            </small>
          </p> */}
          <div className="mobile-links">
            <ExternalLink href="https://play.google.com/store/apps/details?id=com.revuto.dev">
              <img src={GooglePlay} alt="Google play" />
            </ExternalLink>
            <ExternalLink href="https://apps.apple.com/us/app/revuto/id1550940719">
              <img src={AppStore} alt="App store" />
            </ExternalLink>
            <ExternalLink href="https://appgallery.huawei.com/app/C105005025">
              <img src={AppGallery} className="app-badges" alt="Huawei App Gallery" />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};
