import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ExternalLink } from '../../components/ExternalLink';

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="page-wrapper wrapper plain-pages">
      <h1>Privacy Policy</h1>
      <p>Effective as of 19th October, 2021.</p>
      <p>Welcome to Revuto&apos;s Privacy Policy!</p>
      <p>
        Please note that this Privacy Policy applies to personal data that is collected and processed by Revuto, a
        company registered in Estonia, Keemia tn 4, 10616 Tallinn, TIN: 16108647 VAT number: EE102322835 and Revuto
        Savjetovanje d.o.o, a company registered in Croatia, Vladimira Nazora 21, 10000 Zagreb, CIN 05361567
        (collectively (&quot;<b>REVUTO</b>&quot;, &quot;<b>we</b>&quot; or &quot;<b>us</b>&quot;)).
      </p>
      <p>
        REVUTO companies, as joint data controllers, collect and process personal data relating to interactions on the
        Website and Revuto App (as defined in the Definitions section of the <a href="/terms-of-use">Terms of Use</a>).
        This Privacy Policy describes how REVUTO uses and protects any information that you give us.
      </p>
      <p>
        This Policy applies to the data processing that takes place through or in connection with the following: (a)
        your use of the Website (as defined in Definition Section of our <a href="/terms-of-use">Terms of Use</a>) (b)
        your use of the Software (as defined in Definition Section of our <a href="/terms-of-use">Terms of Use</a>) (c)
        your use of the Revuto App (as defined in Definition Section of our <a href="/terms-of-use">Terms of Use</a>).
      </p>
      <p>We believe in full transparency, which is why we keep our Privacy Policy simple and easy to understand.</p>
      <p>
        We strongly urge you to read this Privacy Policy and make sure that you fully understand and agree with it. If
        you do not agree to this Privacy Policy, please do not access or otherwise use the Website and Revuto App.
      </p>
      <p>
        Any capitalized but undefined term in this Privacy Policy shall have the meaning given to it in the Definitions
        section of the <a href="/terms-of-use">Terms of Use</a>.
      </p>

      <p>
        <AnchorLink href="#definitions">
          <h5>1. DEFINITIONS</h5>
        </AnchorLink>
        <AnchorLink href="#controller">
          <h5>2. DATA CONTROLLER</h5>
        </AnchorLink>
        <AnchorLink href="#collect">
          <h5>3. WHAT DATA DO WE COLLECT ABOUT YOU AND WHEN?</h5>
        </AnchorLink>
        <AnchorLink href="#process">
          <h5>4. PERSONAL DATA WE PROCESS</h5>
        </AnchorLink>
        <AnchorLink href="#what-we-do-not-do">
          <h5>5. WHAT WE DO NOT DO?</h5>
        </AnchorLink>
        <AnchorLink href="#personal-data">
          <h5>6. PERSONAL DATA SECURITY</h5>
        </AnchorLink>
        <AnchorLink href="#sharing">
          <h5>7. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h5>
        </AnchorLink>
        <AnchorLink href="#transfer">
          <h5>8. INTERNATIONAL TRANSFER OF YOUR PERSONAL DATA</h5>
        </AnchorLink>
        <AnchorLink href="#duration">
          <h5>9. HOW LONG DO WE KEEP YOUR DATA?</h5>
        </AnchorLink>
        <AnchorLink href="#rights">
          <h5>10. YOUR RIGHTS</h5>
        </AnchorLink>
        <AnchorLink href="#california">
          <h5>11. CALIFORNIA RESIDENTS PRIVACY NOTICE</h5>
        </AnchorLink>
        <AnchorLink href="#changes">
          <h5>12. CHANGES TO PRIVACY POLICY</h5>
        </AnchorLink>
      </p>

      <h4 id="definitions">1. DEFINITIONS</h4>
      <p>
        When we say &quot;<strong>consent</strong>&quot; we mean your explicit consent on the processing of personal
        data. Persons who are 18 years of age or older may give free consent to the processing of their personal data.
      </p>
      <p>
        When we say &quot;<strong>cookies</strong>&quot; we mean small pieces of data stored on your device (computer or
        mobile device). This information is used to track your use of the Website and to compile statistical reports on
        website activity. For further information about the use of cookies and how you can manage them, please read our{' '}
        <a href="/cookie-policy">Cookie Policy</a>.
      </p>
      <p>
        When we say &quot;<strong>Data controller</strong>&ldquo; we mean the natural or legal person, public authority,
        agency or other body which, alone or jointly with others, determines the purposes and means of the processing of
        personal data.
      </p>
      <p>
        When we say &quot;<strong>Data Processors</strong>&quot; or &quot;<strong>processor</strong>
        &quot; we mean any natural or legal person who processes the data on behalf of the Data Controller. In addition,
        we may use the services of various service providers to process your data more effectively. In such cases, they
        are either our processors or sub-processors.
      </p>
      <p>
        When we say &quot;<strong>Data Subject</strong>&quot;, bsp;&quot;<strong>you</strong>&quot; or &quot;
        <strong>your</strong>
        &quot; or&nbsp;we mean any natural person that shares personal data with us via Revuto.
      </p>
      <p>
        When we say &ldquo;<strong>GDPR</strong>&rdquo; we mean the General Data Protection Regulation (EU) 2016/679 of
        the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to
        the processing of personal data and on the free movement of such data.
      </p>
      <p>
        When we say &quot;<strong>processing</strong>&quot; we mean any operation or set of operations which is
        performed on personal data or sets of personal data. This includes activities such as collection, recording,
        organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
        transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or
        destruction.
      </p>
      <p>
        When we say &quot;<strong>personal data</strong>&quot; or &quot;<strong>data</strong>
        &quot; we mean any information relating to an identified or identifiable natural person; an identifiable natural
        person is one who can be identified, either directly or indirectly. Therefore, data about a company or any legal
        entity is not considered to be personal data but registering on behalf of a legal entity may include sharing
        personal data. For example, the information in relation to one-person companies may constitute personal data
        where it allows the identification of a natural person. The rules also apply to all personal data relating to
        natural persons in the course of professional activity, such as the employees of a company or organization,
        business e-mail addresses like <strong>&quot;firstname.surname@company.com&quot;</strong>. This Privacy Policy
        does not apply to information from which no individual can reasonably be identified (anonymized information).
      </p>
      <p>
        When we say &#8222;Special categories of personal data&ldquo; we mean personal data revealing racial or ethnic
        origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of
        genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or
        data concerning a natural person&rsquo;s sex life or sexual orientation.
      </p>
      <h4 id="controller">2. DATA CONTROLLER</h4>
      <p>
        Revuto is a company registered under the laws of Estonia, which, as a Data controller, jointly with Revuto
        Savjetovanje d.o.o, a company registered under the laws of Croatia, determines the purposes and means of the
        processing of personal data. Joint controllers are responsible for your personal data. The purpose of data
        processing is the reason why we process your personal data. The table in Section 4 of the Privacy Policy
        presents the purposes and legal basis for data processing. In such cases, REVUTO is responsible for your
        personal data.
      </p>
      <h4 id="collect">3. WHAT DATA DO WE COLLECT ABOUT YOU AND WHEN?</h4>
      <p>
        We may collect and receive information about you in various ways:
        <ul>
          <li>
            (i) Information you provide through the use of the Revuto Services, Revuto Website and Revuto App (for
            example, by creating the account on the Website).
          </li>
          <li>
            (ii) Information you decide to provide through getting in touch with us via our{' '}
            <ExternalLink href="https://revuto.com/contact">Contact page</ExternalLink>.
          </li>
          <li>
            (iii) Information we collect through the use of cookies in accordance with our{' '}
            <a href="/cookie-policy">Cookie Policy</a> (for example, your time zone).
          </li>
        </ul>
      </p>
      <h4 id="process">4. PERSONAL DATA WE PROCESS</h4>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>DATA WE COLLECT</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>PURPOSE</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>LEGAL BASIS</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>RETENTION</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>First Name, Last Name, Email address, password</strong>
              </p>
            </td>
            <td>
              <p>
                Signing up to get early access to Revuto, creating and maintaining a User Account on the Platform
                according to the <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in the{' '}
                <a href="/terms-of-use">Terms of Use</a>). Without providing your first and last name, email address and
                password the User may not create the User Account, enable your participation in Revuto Referral Program
                or undertake KYC (as described below)
              </p>
              <p>
                You are not obliged to use face ID authentication. Therefore, we will collect this data only if you give
                us your consent. If you do not want to use face ID authentication, please click on the &quot;Don&apos;t
                Allow&quot; button, on the push notification and Revuto App will use passcode authentication instead.
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with the <a href="/terms-of-use">Terms of Use</a>.
              </p>
              <p>
                If the processing is based on your consent, we keep the information until you withdraw your consent.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Revuto Debit Card / Revuto Debit Card Pro Information</strong>
              </p>
              <p>
                such as Debit Cardholder&rsquo;s name, address, bank account, subscription type, subscription price, due
                date, billing cycle, source of payment, 19 digits custom phrase for Revuto Debit Card Pro.
              </p>
            </td>
            <td>
              <p>
                To be able to use Revuto App a User must obtain Revuto Debit Card. This information is being collected
                by a third party processor.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in the{' '}
                <a href="/terms-of-use">Terms of Use</a>) and providing the Services. Without a Revuto Debit Card the
                User may not be able to use Revuto App.
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with the <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Financial Data</strong>
              </p>
              <p>such as name, address, bank account and payment card details.</p>
            </td>
            <td>
              <p>
                When subscribing to any of the paid Subscription Plans in accordance with the{' '}
                <a href="/terms-of-use">Terms of Use</a>, this information is being collected by a third party
                processor.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement which includes providing additional
                features based on the selected paid Subscription Plan.
              </p>
            </td>
            <td>
              <p>
                We keep only the last four digits of the credit card number under subscription billing info until such
                Agreement is terminated and for the period necessary to comply with the applicable financial and tax
                accounting and other statutory obligations in accordance with the applicable law.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Revutoken Information </strong>
              </p>
              <p>such as token ID, wallet address, Revutoken balance.</p>
            </td>
            <td>
              <p>
                With Rev Utokens, Users may easily transfer and/or sell digital assets to others, and pay less for
                online goods and services.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in the{' '}
                <a href="/terms-of-use">Terms of Use</a>) and providing the Services.
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with the <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Revuto crypto wallet Information</strong>
              </p>
              <p>
                such as biometric data or Apple password, wallet name, spending password, wallet recovery phrase
                (24-word seed phrase (mnemonic)), available funds, transactions information, unique address for
                receiving payments, personal information related to KYC /AML checks (as explained below).
              </p>
            </td>
            <td>
              <p>
                To provide a simple and secure cryptocurrency wallet supporting all Cardano-based tokens, while by
                default including REVU and the EURR stablecoin, and providing specific functionalities allowing for
                automatic pull payments.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in{' '}
                <a href="/terms-of-use">Terms of Use</a>) and providing the Services.
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Revuto Referral Program Information</strong>
              </p>
              <p>
                such as your email address, Revuto crypto wallet information (public address from the blockchain),
                unique referral link, personal information related to KYC /AML checks (as explained below).
              </p>
            </td>
            <td>
              <p>
                To unlock other benefits and earn more Revu Tokens, you may participate in the Revuto referral program.
                Once you sign up, you will receive a unique Referral Link that you can share with others. Using your
                unique referral link, you will be able to unlock benefits and earn Revutokens, as explained{' '}
                <ExternalLink href="https://revuto.com/referral-page">here</ExternalLink>. You will be able to claim and
                unlock Revutokens provided that you verify your account through the KYC check (as explained below).
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in the&nbsp;
                <a href="/terms-of-use">Terms of Use</a>) and providing the Services.
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with the <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Location information</strong>
              </p>
            </td>
            <td>
              <p>
                We collect your precise location information since the Revuto App and Revuto crypto wallet is only
                available to the residents of the EEA and the United Kingdom.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in the{' '}
                <a href="/terms-of-use">Terms of Use</a>).
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with the <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>User&rsquo;s subscriptions information</strong>
              </p>
              <p>
                such as information about your finances and expenses related to your subscriptions to other services,
                analytics data on your savings and expenses.
              </p>
            </td>
            <td>
              <p>
                We collect this information in order to Approve, Block, or Snooze your payment for any
                service/subscription.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in the{' '}
                <a href="/terms-of-use">Terms of Use</a>).
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with the <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Additional Data</strong>
                <br />
                <br />
                i.e., data you decide to share with us.
              </p>
            </td>
            <td>
              <p>
                If you send us an inquiry via&nbsp;our <a href="https://revuto.com/contact">Contact page</a>&nbsp;or
                otherwise request support, we will collect data you decide to share with us.
              </p>
            </td>
            <td>
              <p>
                Processing of personal data is either necessary to provide a Service or part thereof, or the processing
                is based on your consent.
              </p>
            </td>
            <td>
              <p>
                If the processing is based on your consent, we keep the information until you withdraw your consent or
                for one year, whichever date comes first.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Device information </strong>
              </p>
            </td>
            <td>
              <p>
                We may collect information about your device such as Internet Protocol (&ldquo;IP&rdquo;) addresses, log
                information, error messages, device type, and unique device identifiers. For example, we may collect IP
                addresses from you as part of our sign in and security features.
              </p>
            </td>
            <td>
              <p>
                Processing of personal data is either necessary to provide a Service or part thereof, or the processing
                is based on your consent.
              </p>
            </td>
            <td>
              <p>
                If the processing is based on your consent, we keep the information until you withdraw your consent or
                for one year, whichever date comes first.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Email address</strong>
                <br />
                <br />
                If you decide to sign up for our newsletter, we use your email address.
              </p>
            </td>
            <td>
              <p>
                This newsletter allows us to inform you of the new features of the Platform, updates, as well as other
                news relevant to the company. Also, via our newsletter you may get invitations for our live events where
                Revuto Services will be promoted and where you have a chance to get Revuto Redeem codes.
              </p>
            </td>
            <td>
              <p>
                Processing is based on your consent. You have the right to withdraw your consent at any time, without
                affecting the lawfulness of the processing based on consent prior to such withdrawal. You may
                unsubscribe from receiving a newsletter from us. If you wish to do so, simply follow the instructions
                found at the end of each email.
              </p>
            </td>
            <td>
              <p>We may use your email for this purpose until you unsubscribe or until you delete your User Account.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Email address used for signing up to Revuto</strong>
              </p>
            </td>
            <td>
              <p>
                In order to fulfill its contractual obligations to notify the Users of the amendments and changes of the
                Agreement (as defined in <a href="/terms-of-use">Terms of Use</a>), Revuto will send notifications to
                your email address.
              </p>
            </td>
            <td>
              <p>
                Processing is necessary for the performance of the Agreement (as defined in{' '}
                <a href="/terms-of-use">Terms of Use</a>).
              </p>
            </td>
            <td>
              <p>
                Until the account is deleted in accordance with <a href="/terms-of-use">Terms of Use</a>.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Mobile phone number</strong>
              </p>
            </td>
            <td>
              <p>
                In order not to miss any important news you can join our Telegram groups and channel for announcements,
                general questions, and the latest updates.
              </p>
            </td>
            <td>
              <p>
                Processing is based on your consent. You have the right to withdraw your consent at any time, without
                affecting the lawfulness of the processing based on consent prior to such withdrawal. You may
                unsubscribe from receiving Revuto updates from Telegram groups and channels. If you wish to do so,
                simply leave Telegram groups and channels.
              </p>
            </td>
            <td>
              <p>
                We may use your mobile phone number for this purpose until you leave Telegram groups and/or channels.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Information necessary for identification</strong>
              </p>
            </td>
            <td>
              <p>
                To allow Data Subjects from EEA to exercise their rights in accordance with this Privacy Policy, as
                defined in Section 10.
              </p>
            </td>
            <td>
              <p>Processing is necessary for compliance with a legal obligation which the Controller is subject to.</p>
            </td>
            <td>
              <p>We keep this information for a period of one year.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>Personal information related to KYC /AML checks</strong>
              </p>
              <br />
              <p>
                such as name and surname, passport or any identity card data, proof of registered address, banking
                details, facial image via camera.
                <br />
                We may process Special categories of personal data that you give us directly or that we receive from
                third parties and/or publicly available sources:
                <br />- data which might be revealed by KYC or other background checks (for example, because it has been
                reported in the press or is available in public registers);
                <br />- data that is incidentally revealed by photographic ID (biometric data).
              </p>
            </td>
            <td>
              <p>
                For the prevention and detection of fraud, money laundering, counter terrorism, sanction screening,
                fraud or other crimes or to respond to a binding request from a public authority or court.
              </p>
              <p>
                Additionally, the purpose of data processing is to uniquely identify a natural person and provide the
                Service that is conditioned upon successful KYC.
              </p>
              <p>This information is being collected by us or by a third party processor.</p>
            </td>
            <td>
              <p>The processing is necessary to comply with legal and regulatory obligations.</p>
              <p>
                We may also process such data in connection with these purposes if it is necessary for the performance
                of the Agreement. Namely, full access to Revuto Services (e.g. claiming Revu Tokens, Revuto crypto
                wallet functionalities, etc) is conditioned upon successful KYC. Basically, without a successful KYC, a
                User cannot use any of the essential services on the Revuto Platform.
              </p>
              <p>
                For Special categories of personal data, it is necessary for reasons of substantial public interest
                under EU Anti-Money Laundering Directives and the relevant EU Member States&apos; laws implementing
                them, as well as under the anti-money laundering legislation of the UK (information from your ID
                documents including a photographic picture of you and a visual image of your face (the so called
                “liveness check”)).
              </p>
              <p>
                Where we need to collect personal data by law, or under the terms of the Agreement, and you refuse to
                provide that data when requested, we may not be able to perform the Agreement we have or are trying to
                enter into with you – for example, to provide you Services.
              </p>
            </td>
            <td>
              <p>In accordance with the applicable statutory deadlines.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h4 id="what-we-do-not-do">5. WHAT WE DO NOT DO?</h4>
      <p>
        REVUTO will never:
        <ul>
          <li>Sell any kind of personal information or data</li>
          <li>Disclose this information to marketers or third parties not specified in Section 7</li>
          <li>Process your data in any way other than stated in this Privacy Policy.</li>
        </ul>
      </p>
      <h4 id="personal-data">6. PERSONAL DATA SECURITY</h4>
      <p>
        REVUTO takes the privacy and security of its members&apos; personal information seriously. We maintain
        administrative, technical and physical safeguards designed to protect your information&rsquo;s security,
        confidentiality and integrity.&nbsp;
      </p>
      <p>
        Upon assessing whether a measure is adequate and which level of security is appropriate, we consider the nature
        of the personal data we are processing and the nature of the processing operations we perform, the risks to
        which you are exposed by our processing activities, the costs of the implementation of security measures and
        other relevant matters in the particular circumstances.
      </p>
      <p>
        Some of the measures we apply include access authorization control, information classification (and handling
        thereof), protection of integrity and confidentiality, data backup, firewalls, data encryption and other
        appropriate measures. We equip our staff with the appropriate knowledge and understanding of the importance and
        confidentiality of your personal data security.
      </p>
      <p>
        We protect personal information you provide online in connection with registering yourself as a user of our
        Website or Revuto App. Access to your own personal information is available through a password selected by you.
        This password is encrypted while transmitted from your browser to our servers and while stored on our systems.
        To protect the security of your personal information, never share your password to anyone. Please notify us
        immediately if you believe your password has been compromised.
      </p>
      <p>
        Whenever we save your personal information, it&rsquo;s stored on servers and in facilities that only selected
        REVUTO personnel and our contractors have access to. We encrypt all data that you submit through the Website
        during transmission using Transport Layer Security (TLS) in order to prevent unauthorized parties from viewing
        such information. Remember &ndash; all information you submit to us by email is not secure, so please do not
        send sensitive information in any email to REVUTO. We never request that you submit sensitive or personal
        information over email, so please report any such requests to us by sending an email to support@revuto.com.
      </p>
      <p>
        <strong>Steps you can take to help us keep your information safe</strong>
      </p>
      <p>
        There are many steps you can take to help us keep your information safe. First and foremost, choose complex,
        independent passwords for each website and service you maintain an account with. This helps keep any breach of
        any of your accounts isolated to one service. Also, don&rsquo;t use anything related to your birthday, address,
        phone number, PIN number or any other easily guessable information in your password.
      </p>
      <p>
        REVUTO urges your caution when using public computers or networks, like at a coffee shop or library. To best
        protect your personal information and login information, don&rsquo;t use such computers to access your sensitive
        accounts, and if you must, ensure that you logout of your account entirely.
      </p>
      <p>
        When either you or we update information in your account, such as the status of your account or Revutoken (REVU)
        balance, we typically send you notice of these changes via email or text message. In recent years, individuals,
        businesses and even governments have seen a rise in &ldquo;phishing&rdquo; attacks. Phishing occurs when someone
        attempts to obtain your password or other sensitive information. Scammers often do this by impersonating a
        trusted user, or by offering a compelling reason to open a malicious email attachment, click on a link or give
        over information. We never ask for your sensitive personal information, such as password, over email or other
        unsecure methods or through any site not under the revuto.com domain. Please notify us at support@revuto.com if
        you ever receive suspicious correspondence from us.
      </p>

      <h4 id="sharing">7. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h4>
      <p>
        REVUTO utilizes external processors for certain processing activities. We use information audits to identify,
        categorize and record all personal data that is processed outside the company, so that the information,
        processing activity, processor and legal basis are all recorded, reviewed and easily accessible.
      </p>
      <p>
        We have strict due diligence procedures and measures in place and review, assess and background check all
        processors prior to forming a business relationship. We obtain company documents, certifications, references and
        ensure that the processor is adequate, appropriate and effective for the task we are employing them for.
      </p>
      <p>
        We audit their processes and activities prior to contract and during the contract period to ensure compliance
        with the data protection regulations and review any codes of conduct that oblige them to confirm compliance.
      </p>
      <p>This is the list of processors with whom we share your personal data:</p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>PROCESSOR</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>ROLE</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>SEAT</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google, Inc.</p>
            </td>
            <td>
              <p>Email services based on Cloud</p>
            </td>
            <td>
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Google, Inc.</p>
            </td>
            <td>
              <p>Analytics</p>
            </td>
            <td>
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>You Tube, Inc.</p>
            </td>
            <td>
              <p>Statistics on embedded videos</p>
            </td>
            <td>
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Hotjar, Ltd.</p>
            </td>
            <td>
              <p>User experience analytics</p>
            </td>
            <td>
              <p>Malta</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>DigitalOcean, Inc.</p>
            </td>
            <td>
              <p>Cloud Infrastructure (IaaS)</p>
            </td>
            <td>
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Manigo Services Ltd</p>
            </td>
            <td>
              <p>Technical services</p>
            </td>
            <td>
              <p>UK</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Electrocoin</p>
            </td>
            <td>
              <p>Payment Processor</p>
            </td>
            <td>
              <p>Croatia</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sum And Substance Ltd</p>
            </td>
            <td>
              <p>KYC check</p>
            </td>
            <td>
              <p>UK</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>We may also share your personal data with our outside accountants, legal counsels and auditors.</p>
      <ul>
        <li>
          if we are under a duty to disclose or share your personal data in order to comply with any legal obligation;
        </li>
        <li>to prevent and detect fraud or crime;</li>
        <li>in response to a subpoena, warrant, court order, or as otherwise required by law.</li>
      </ul>
      <p>
        Finally, personal information may be disclosed or transferred as part of, or during negotiations of, a merger,
        consolidation, sale of our assets, as well as equity financing, acquisition, strategic alliance or in any other
        situation where personal information may be transferred as one of the business assets of Revuto.
      </p>
      <p>
        We do not have a list of all third parties we share your data with, as this would be dependent on your specific
        use of our Services. However, if you would like further information about who we have shared your data with, or
        to be provided with a list specific to you, you can request this by writing to support@revuto.com.
      </p>
      <h4 id="transfer">8. INTERNATIONAL TRANSFER OF YOUR PERSONAL DATA</h4>
      <p>
        We may transfer your personal data to countries other than the one you reside in. In these cases, we will
        transfer your personal data only:
      </p>
      <ol>
        <li>To the countries within the EEA;</li>
        <li>
          To the countries which do not form the EEA but are considered to ensure an adequate level of protection;
        </li>
        <li>
          To the countries which do not belong to those specified under item 1. and 2, but only by applying the
          appropriate safeguard measure in accordance with the GDPR.
        </li>
      </ol>
      <p>We use servers located in the Netherlands.</p>
      <h4 id="duration">9. HOW LONG DO WE KEEP YOUR DATA?</h4>
      <p>
        The period for which we store your personal data depends on a particular purpose for the processing of personal
        data, as explained in detail in Section 4 of this Privacy Policy. We retain personal data for as long as we
        reasonably require it for legal or business purposes. In determining data retention periods, we take into
        consideration the applicable law (see Section 21 of the <a href="/terms-of-use">Terms of Use</a>), contractual
        obligations, and the expectations and requirements of our Users. When we no longer need personal information, or
        when you request us to delete your information, where this is legal, we will securely delete or destroy it.
      </p>
      <p>
        Personal data we collected for your early access to Revuto will be deleted by the end of February 2022, provided
        you have not continued using our Services.
      </p>
      <p>
        However, as an exception to the retention periods in Section 4 of this Privacy Policy the data may be processed
        to determine, pursue or defend claims and counterclaims.
      </p>
      <h4 id="rights">10. YOUR RIGHTS</h4>
      <p>
        As a Data Subject in the EEA, whose personal information we hold, you have certain rights under the GDPR. This
        part of the Privacy Policy aims to give you a general understanding of these rights. In relation to each of the
        rights noted below, we provided a reference to the specific provision of the GDPR from which that right arises.
      </p>
      <p>
        <strong>Right of Access </strong>
        <em>(Article 15 GDPR)</em>
      </p>
      <p>You can send us a request for a copy of the personal data we hold about you.</p>
      <p>
        We have ensured that appropriate measures have been taken to provide such in a concise, transparent,
        intelligible and easily accessible form, using clear and plain language. Such information is provided in writing
        free of charge. It may be provided by other means when authorized by the Data Subject and with prior
        verification as to the subject&rsquo;s identity.
      </p>
      <p>
        Information is provided to the Data Subject at the earliest convenience, but at a maximum of 30 days from the
        date the request was received. Where the retrieval or provision of information is particularly complex or is
        subject to a valid delay, the period may be extended by two further months where necessary.
      </p>
      <p>
        <strong>Right to Correction of Your Personal Data</strong>
        <em>(Article 16 GDPR)</em>
      </p>
      <p>
        <br />
        <br />
      </p>
      <p>
        If the personal data we have about you is incorrect, you have the right to request that we correct that data.
        When notified of inaccurate data by the Data Subject, we will rectify the error within 30 days and inform any
        third party of the rectification if we have disclosed the personal data in question to them.
      </p>
      <p>
        <br />
        <br />
      </p>
      <p>
        <strong>Right to Be Forgotten or Right to Erasure </strong>
        <em>(Article 17 GDPR)</em>
      </p>
      <p>
        You have the right to request from us that your personal data is deleted in certain circumstances including:
      </p>
      <ul>
        <li>The personal data are no longer needed for the purpose for which they were collected;</li>
        <li>You withdraw your consent (where the processing was based on consent);</li>
        <li>
          You object to the processing and no overriding legitimate grounds are justifying us processing the personal
          data;&nbsp;
        </li>
        <li>The personal data have been unlawfully processed; or</li>
        <li>To comply with a legal obligation.</li>
      </ul>
      <p>However, this right does not apply where, for example, the processing is necessary:</p>
      <ul>
        <li>To comply with a legal obligation; or</li>
        <li>For the establishment, exercise or defense of legal claims.</li>
      </ul>
      <p>
        <strong>Right to Restriction of Processing </strong>
        <em>(Article 18 GDPR)</em>
      </p>
      <p>
        If the accuracy of the personal data is contested, you consider the processing is unlawful but you do not want
        it erased, we no longer need the personal data but you require it for the establishment, exercise or defense of
        legal claims or you have objected to the processing and verification, you can exercise your right to the
        restriction of processing.
      </p>
      <p>
        <strong>Right to Withdraw the Consent </strong>
        <em>(Article 13(2)c) GDPR)</em>
      </p>
      <p>
        If you have provided your consent to the collection, processing and transfer of your personal data, you have the
        right to fully or partly withdraw your consent. Once we have received notification that you have withdrawn your
        consent, we will no longer process your information for the purpose(s) to which you originally consented unless
        there is another legal ground for the processing.
      </p>
      <p>
        <strong>Right to Lodge a Complaint</strong>
      </p>
      <p>
        If you have any concerns or requests in relation to your personal data, please contact us at support@revuto.com
        and we will respond as soon as possible but not later than within 30 days.
      </p>
      <p>If you are unsatisfied with how we process your data, you may contact the competent supervisory authority.</p>
      <p>
        In case you believe that we are processing your personal data in violation of the GDPR, you have the right to
        lodge a complaint with the supervisory authority located in the EEA where you reside or work or where the
        alleged infringement took place.
      </p>
      <h4 id="california">11. CALIFORNIA RESIDENTS PRIVACY NOTICE („Notice“)</h4>
      <p>
        This Section supplements the information contained in the REVUTO&apos;s Privacy Policy and applies solely to
        visitors, users, and others who reside in the State of California and fall under the scope of the California
        Consumer Privacy Act (“consumers” or “you”) when they visit the Website or use the Services. Under this Notice
        REVUTO complies with the California Consumer Privacy Act of 2018 (“CCPA”).
      </p>
      <p>
        Any capitalized but undefined term in this Notice shall have the meaning given to it in the CCPA, Definitions
        section of the <a href="/terms-of-use">Terms of Use</a>, Privacy Policy and{' '}
        <a href="/cookie-policy">Cookie Policy</a>.
      </p>
      <p>
        This Notice makes an integral part of our Privacy Policy and <a href="/terms-of-use">Terms of Use</a>.
      </p>
      <p>
        <strong>Information we collect</strong>
      </p>
      <p>
        For more details about the personal information REVUTO has collected over the last 12 months please see Section
        4 of the Privacy Policy - Personal Data We Process .
      </p>
      <p>
        The categories of personal information that are listed in Section 4 are obtained by REVUTO in accordance with
        Section 3 of the Privacy Policy - What Data Do We Collect About You And When?
      </p>
      <p>
        <strong>Use of Personal Information</strong>
      </p>
      <p>
        REVUTO collects personal information for the purposes described in Section 4 of the Privacy Policy - Personal
        Data We Process .
      </p>
      <p>
        <strong>Sharing Personal Information</strong>
      </p>
      <p>
        REVUTO shares personal information with the categories of third parties described in Section 7 of the Privacy
        Policy - With whom do we share your personal data?
      </p>
      <p>
        Please note that REVUTO uses third-party cookies for advertising purposes as further described in our Cookie
        Policy .
      </p>
      <p>
        <strong>REVUTO does not sell your personal information</strong>
      </p>
      <p>REVUTO does not sell personal information, including personal information of anyone under 18 years of age.</p>
      <p>
        Under the CCPA, a business that sells consumers personal information to others must: 1) give notice to consumers
        before selling their personal information to others; and 2) provide to consumers the right to opt-out of the
        sale of their personal information.
      </p>
      <p>Therefore, the notification and opt-out requirements do not apply to REVUTO.</p>

      <p>
        <strong>Your Rights and Choices</strong>
      </p>
      <p>
        This section describes your rights under the CCPA and explains how to exercise those rights. &nbsp; These rights
        may be exercised by consumers when REVUTO operates as a Data Controller.
      </p>

      <p>
        <strong>Right to know Personal Information and Data Portability Rights</strong>
      </p>
      <p>
        You have the right to request that we disclose certain information to you about REVUTO&apos;s collection and use
        of your personal information over the past 12 months, i.e.:
      </p>
      <ul>
        <li>Our business or commercial purpose for collecting your personal information.</li>
      </ul>
      <p>The categories of personal information we collected about you.</p>
      <ul>
        <li>The categories of third parties with whom we share your personal information.</li>
        <li>The specific pieces of personal information we collected about you („data portability request “).</li>
      </ul>
      <p>We will disclose this information to you once we receive and confirm your verifiable consumer request.</p>

      <p>
        <strong>Right to Deletion</strong>
      </p>
      <p>
        You have the right to deletion your personal information REVUTO collected. REVUTO will delete your personal
        information from the records once we receive and confirm your verifiable consumer request unless a CCPA
        exception applies.
      </p>
      <p>
        Namely, we may deny your deletion request subject to the exceptions in CCPA §1798.105, i.e. if retaining the
        information is necessary for REVUTO or our service providers to:
      </p>
      <ol>
        <li>
          Complete the transaction for which REVUTO collected the personal information, provide a good or service that
          you requested, take actions reasonably anticipated within the context of REVUTO&apos;s ongoing business
          relationship with you, or otherwise perform our contract with you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute
          those responsible for such activities.
        </li>
        <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise
          another right provided for by law.
        </li>
        <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code §1546 seq. ).</li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that
          adheres to all other applicable ethics and privacy laws, when the information&apos;s deletion may likely
          render impossible or seriously impair the research&apos;s achievement, if you previously provided informed
          consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship
          with us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are compatible with the context in which you
          provided it.
        </li>
      </ol>

      <p>
        If your request is justified, REVUTO will also direct their service providers to delete your personal
        information as well.
      </p>

      <p>
        <strong>Right to Non-Discrimination</strong>
      </p>
      <p>
        REVUTO will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA,
        REVUTO will not:
      </p>
      <ul>
        <li>Deny you use of our Services.</li>
        <li>Provide you a different level or quality of Services.</li>
        <li>
          Charge you different prices or rates for Services, including through the use of discounts or other benefits or
          by imposing penalties.
        </li>
        <li>
          Suggest that the person exercising their rights will receive a different price or rate for Services or a
          different level or quality of Services.
        </li>
      </ul>

      <p>
        <strong>Right to say no to the sale of your personal information (Right to opt-out)</strong>
      </p>
      <p>
        As explained above, the CCPA requires businesses that sell personal information to allow consumers the ability
        to opt-out of the selling of their personal information.
      </p>
      <p>Again, REVUTO does not sell personal information.</p>

      <p>
        <strong>Exercising your rights</strong>
      </p>
      <p>
        To exercise the rights to access, data portability, and deletion, please submit a verifiable consumer request at
        support@revuto.com.
      </p>
      <p>Consumers are entitled to make a request for access or data portability twice within a 12-month period.</p>
      <p>To exercise your rights, you may send a request by yourself or use an authorized agent.</p>
      <p>
        If REVUTO cannot verify your identity or authority to make the request and confirm the personal information
        related to you, REVUTO cannot respond to your request or provide you with personal information.
      </p>
      <p>
        We try to respond to consumers&apos; requests within 45 days of their receipt. Sometimes, REVUTO may need more
        time to respond to the request (up to 90 days total from the moment we receive the request) when REVUTO will
        inform the consumer of the reason and extension period in writing.
      </p>
      <p>
        You are not obliged to create an account with us in order to make a verifiable consumer request. We will deliver
        our written response to the registered email associated with the account if you have an account with us. Please
        note that any information we provide will only cover the 12-month period preceding the consumers&apos; request
        receipt. If we cannot comply with your request, we will provide an explanation.
      </p>
      <p>
        If a consumer files a data portability request, REVUTO will select a format that is readily useable and should
        allow the transmission of the information from one entity to another entity without hindrance.
      </p>
      <p>
        We do not charge a fee to process or respond to verifiable consumer requests unless it is excessive, repetitive,
        or manifestly unfounded. If REVUTO &nbsp;determines that the request warrants a fee, REVUTO will:
      </p>
      <ul>
        <li>inform you why the decision was made and</li>
        <li>provide the consumer with a cost estimate before completing the request.</li>
      </ul>
      <h4 id="changes">12. CHANGES TO PRIVACY POLICY</h4>
      <p>
        We reserve the right to change Privacy Policy from time to time at our sole discretion. If we make any changes,
        we will publish the new rules on this web page and, if we have your email, we will notify you directly.
      </p>
      <p>
        Where you have previously consented to our Privacy Policy, your continued use of the Revuto after we make
        changes is deemed to be acceptance of the updated rules.
      </p>
    </div>
  );
};
