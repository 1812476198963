import { createAction } from '@reduxjs/toolkit';
import { authSlice } from './slice';
import { RegisterData, ResetPasswordData, ChangePasswordData } from '../../types/auth';

export const {
  setAuthorizeState,
  setAuthorizationError,
  storeAuthorization,
  removeAuthorization,
  addAuthorizationRole,
  setRegisterState,
  setRegisterError,
  setResetPasswordState,
  setResetPasswordError,
  setChangePasswordState,
  setChangePasswordError,
  setAddress,
} = authSlice.actions;

type RequireAuthorization = (
  username: string,
  password: string,
  onFinish: () => void,
) => { payload: { email: string; password: string }; meta: () => void };
export const requireAuthorization = createAction<RequireAuthorization>(
  'auth/require_authorization',
  (email: string, password: string, onFinish) => ({
    payload: { email, password },
    meta: onFinish,
  }),
);

type RegisterBuyer = (data: RegisterData, onFinish: () => void) => { payload: typeof data; meta: () => void };
type ResetPassword = (data: ResetPasswordData, onFinish: () => void) => { payload: typeof data; meta: () => void };
type ChangePassword = (data: ChangePasswordData) => { payload: typeof data };
export const registerBuyer = createAction<RegisterBuyer>('auth/register_buyer', (data, onFinish) => ({
  payload: data,
  meta: onFinish,
}));
export const resetPassword = createAction<ResetPassword>('auth/reset_password', (data, onFinish) => ({
  payload: data,
  meta: onFinish,
}));
export const changePassword = createAction<ChangePassword>('auth/change_password', (data) => ({
  payload: data,
}));
export const revokeAuthorization = createAction('auth/revoke_authorization');

export const verifyAccount = createAction<{ token: string; verified: (successfully: boolean) => void }>(
  'auth/verify_account',
);

export const updateAddress = createAction<{ address: string; onAddressError: (error: string) => void }>(
  'auth/update_address',
);
